import React from 'react';
import { useSelector } from 'react-redux';
import Notice from 'components/Notice';
import { selectors as approversSelectors } from 'modules/approvers';
import useQueryParams from 'hooks/useQueryParams';

const NoticeContainer = () => {
  const { getParams } = useQueryParams();
  const { approverId } = getParams();
  const approver = useSelector(approversSelectors.approver(approverId));

  if (approver) {
    const name = `${approver.firstName} ${approver.lastName}`;
    return <Notice>Viewing timesheets for {name}</Notice>;
  }

  return null;
};

export default NoticeContainer;
