import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NotFound from 'components/NotFound';

import SubHeader from 'components/SubHeader';
import { Container, View } from 'components/layout';

import UsersLayout from './UsersLayout';
import UserTimesheetLayout from './UserTimesheetLayout';

export default ({ match }) => {
  const menuItems = [{ to: `${match.path}/users`, display: 'Users' }];

  return (
    <Fragment>
      <SubHeader items={menuItems} />
      <View>
        <Container>
          <Switch>
            <Route exact path={`${match.path}/users`} component={UsersLayout} />
            <Route path={`${match.path}/users/:id/timesheets`} component={UserTimesheetLayout} />
            <Redirect exact from={match.path} to={`${match.path}/users`} />
            <Route component={NotFound} />
          </Switch>
        </Container>
      </View>
    </Fragment>
  );
};
