import React from 'react';
import StyledLoader from './Loader.styles';

const Loader = props => (
  <StyledLoader {...props}>
    <div className="Loader__container">
      <svg className="circular" viewBox="25 25 50 50">
        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </div>
  </StyledLoader>
);

export default Loader;
