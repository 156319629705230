import React, { useEffect } from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';

import { colors } from 'theme/theme';
import { aria } from 'constants/aria';

const Container = styled.div`
  display: inline-block;
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px ${colors.grayLight};
  padding: 10px;
  border-radius: 3px;
  position: relative;
  width: 300px;

  &:after {
    content: '▼';
    position: absolute;
    font-size: 18px;
    bottom: -17px;
    left: 0;
    width: 100%;
    text-align: center;
    color: ${colors.white};
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.17;
  color: ${colors.grayMortar};
  margin-bottom: 10px;

  .Header__title {
    flex: 1 1 auto;
    margin-right: 5px;
  }

  .Header__close {
    flex: 0 0 auto;
  }
`;

const Textarea = styled.textarea`
  vertical-align: bottom;
  border-radius: 3px;
  background-color: ${colors.white};
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px ${colors.grayLight};
  resize: none;
  padding: 5px;
  font-size: 14px;
  outline: none;
  width: 100%;
  height: 60px;
  overflow: auto;
`;

const CloseButton = styled.i.attrs({
  className: 'fa fa-times',
  title: 'close',
})`
  color: ${colors.grayCharcoal};
  font-size: 12px;
  cursor: pointer;
`;

const EntryNotes = ({ value, onChange, onClose, ...props }) => {
  useEffect(() => {
    const onKeyDown = e => {
      if (!['Esc', 'Escape'].includes(e.key)) return;
      onClose();
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onClose]);

  return (
    <Container className="CommentBubble">
      <Header>
        <div className="Header__title">
          <strong>Comment</strong> (Required):
        </div>
        <div className="Header__close">
          <CloseButton aria-label={aria.timesheet.entryCommentClose()} onClick={() => onClose()} />
        </div>
      </Header>
      <Textarea {...props} value={value} onChange={e => onChange(e.target.value)} autoFocus />
    </Container>
  );
};

EntryNotes.defaultProps = {
  value: '',
  onChange: noop,
};

export default EntryNotes;
