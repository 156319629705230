import React from 'react';
import styled from 'styled-components';
import statusColor from 'utils/statusColor';

const StatusText = props => {
  return <StyledStatus {...props}>{props.status}</StyledStatus>;
};

const StyledStatus = styled.span`
  color: ${p => statusColor(p.status)};
  text-transform: capitalize;
`;

export default StatusText;
