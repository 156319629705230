import React from 'react';
import { Switch, Route, matchPath } from 'react-router';
import { Container, View } from 'components/layout';
import PowerBIReport from 'components/PowerBIReports/PowerBIReport';
import PowerBIReportsList from 'components/PowerBIReports/PowerBIReportsList';
import Breadcrumbs from 'components/Breadcrumbs';
import { POWERBI_REPORTS_PATH, POWERBI_REPORTS_TITLE } from 'components/PowerBIReports';
import { usePowerBIReport } from 'hooks/usePowerBIReport';

const PowerBIReports = ({ match }) => {
  const reportPath = `${match.path}/:reportId`;
  const reportMatch = matchPath(window.location.pathname, { path: reportPath });
  const reportId = reportMatch?.params?.reportId;

  const { name } = usePowerBIReport(reportId);

  const routes = (() => {
    const list = [{ display: POWERBI_REPORTS_TITLE, url: POWERBI_REPORTS_PATH }];
    if (name) return [...list, { display: name }];
    return list;
  })();

  return (
    <View>
      <Container>
        <Breadcrumbs routes={routes} />

        <Switch>
          <Route exact path={match.path} component={PowerBIReportsList} />
          <Route path={reportPath} component={PowerBIReport} />
        </Switch>
      </Container>
    </View>
  );
};

export default PowerBIReports;
