export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_READABLE = 'MMM D, YYYY';
export const DATE_TIME_FORMAT_READABLE = `${DATE_FORMAT_READABLE} hh:mm A`;
export const PARTIAL_DAY = 'partial_day';
export const MULTIPLE_DAY = 'multiple_days';
export const PTO_MINIMUM_HOURS = 80;

export const TIMESHEET_STATUSES = Object.freeze({
  UNSUBMITTED: 'unsubmitted',
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  UNAPPROVED: 'unapproved',
});
