import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import History from 'components/History';
import { selectors, actions } from 'modules/timesheet';

const TimesheetHistoryContainer = () => {
  const dispatch = useDispatch();
  const timesheet = useSelector(selectors.localTimesheet, ({ id: prevId }, { id: nextId }) => prevId === nextId);
  const loading = useSelector(selectors.historyLoading);
  const history = useSelector(selectors.history);

  useEffect(() => {
    if (timesheet && timesheet.id) dispatch(actions.fetchTimesheetHistory());
  }, [dispatch, timesheet]);

  return <History loading={loading} rows={history} />;
};

export default TimesheetHistoryContainer;
