import React, { Fragment } from 'react';
import styled from 'styled-components';

import { TIMESHEET_STATUS_MESSAGES } from 'utils/reference';

import Loader from 'components/Loader';
import StatusBadge from 'components/StatusBadge';
import Table from 'components/Table';
import LinkRow from 'components/LinkRow';
import PeriodSelector from 'containers/team/PeriodSelectorContainer';
import ApproverSelector from 'containers/team/ApproverSelectorContainer';
import useQueryParams from 'hooks/useQueryParams';

import { aria } from 'constants/aria';

const Root = styled.div`
  .selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 25px;
  }
`;

const renderLoading = (colSpan = 5, numItems = 1) => {
  const arr = [...Array(numItems)];

  return (
    <Fragment>
      {arr.map((_, i) => (
        <tr key={i}>
          <td colSpan={colSpan} style={{ padding: 0 }}>
            <Loader compact />
          </td>
        </tr>
      ))}
    </Fragment>
  );
};

const RenderContent = ({ timesheets }) => {
  const { search } = useQueryParams();

  if (timesheets.length === 0) {
    return (
      <tr>
        <td colSpan="6" style={{ textAlign: 'center' }}>
          No Timesheets Found!
        </td>
      </tr>
    );
  } else {
    return timesheets.map(timesheet => (
      <LinkRow
        key={timesheet.spudUserId}
        to={`/team/timesheets/${timesheet.id}${search}`}
        disabled={timesheet.id === null}
      >
        <td>{timesheet.employee}</td>
        <td className="center">{parseFloat(timesheet.hoursTotal).toFixed(2)}</td>
        <td className="center">{timesheet.capacity}</td>
        <td className="center">{parseFloat(timesheet.hoursBillable).toFixed(2)}</td>
        <td className="center">{parseInt(timesheet.utilization * 100, 10)}%</td>
        <td>
          <StatusBadge
            status={timesheet.status}
            role="contentinfo"
            tabIndex="0"
            aria-label={aria.timesheet.teamStatusMessage(timesheet.employee, timesheet.status)}
          >
            {TIMESHEET_STATUS_MESSAGES[timesheet.status] || timesheet.status}
          </StatusBadge>
        </td>
      </LinkRow>
    ));
  }
};

const TeamTimeSummary = ({ timesheets, loading }) => (
  <Root>
    <div className="selector">
      <PeriodSelector />
      <ApproverSelector />
    </div>
    <Table>
      <thead>
        <tr>
          <th>Employee</th>
          <th className="center">Total Hours</th>
          <th className="center">Capacity</th>
          <th className="center">Billable Hours</th>
          <th className="center">Utilization</th>
          <th />
        </tr>
      </thead>
      <tbody>{loading ? renderLoading(6) : <RenderContent timesheets={timesheets} />}</tbody>
    </Table>
  </Root>
);

export default TeamTimeSummary;
