import React, { useState, useEffect } from 'react';
import Select from 'components/Select';
import { fetchEngagements } from 'utils/api';

const ProjectSelectContainer = ({ projectId, setProjectId, selectedClientId, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [engagements, setEngagements] = useState([]);

  const handleGetEngagements = async () => {
    try {
      setLoading(true);
      const { engagements } = await fetchEngagements();
      setEngagements(engagements);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetEngagements();
  }, []);

  const prepareOptions = () => {
    if (!selectedClientId) return [{ label: 'Select a client...', value: '' }];
    const filtered = engagements.filter(engagement => engagement.clientId === parseInt(selectedClientId, 10));

    return filtered.map(engagement => {
      const { name, projectId, id } = engagement;

      return {
        label: projectId ? `[${projectId}] ${name ?? ''}` : name,
        value: String(id),
      };
    });
  };

  return (
    <Select
      isLoading={loading}
      value={projectId}
      options={prepareOptions()}
      onChange={setProjectId}
      isClearable
      {...rest}
    />
  );
};

export default ProjectSelectContainer;
