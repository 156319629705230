import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { actions } from 'modules/approvers';
import NotFound from 'components/NotFound';

import SubHeader from 'components/SubHeader';
import { Container, View } from 'components/layout';

import TimeSummary from 'containers/team/TimeSummaryContainer';
import PTOSummary from './PTOSummary';
import PTOOverview from './PTOOverview';
import PendingLayout from './PendingLayout';
import TimesheetLayout from './TimesheetLayout';

export default ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchRelatedManagers());
  }, [dispatch]);

  const menuItems = [
    { to: `${match.path}/pending`, display: 'Pending' },
    { to: `${match.path}/timesheets`, display: 'Time' },
    { to: `${match.path}/pto`, display: 'Time Off' },
  ];

  return (
    <>
      <SubHeader items={menuItems} />
      <View>
        <Container>
          <Switch>
            <Route path={`${match.path}/pending`} component={PendingLayout} />
            <Route path={`${match.path}/timesheets/:id`} component={TimesheetLayout} />
            <Route path={`${match.path}/timesheets`} component={TimeSummary} />
            <Route path={`${match.path}/pto/:id/:year`} component={PTOSummary} />
            <Route path={`${match.path}/pto`} component={PTOOverview} />
            <Redirect exact from={match.path} to={`${match.path}/pending`} />
            <Route component={NotFound} />
          </Switch>
        </Container>
      </View>
    </>
  );
};
