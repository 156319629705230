import React from 'react';
import styled from 'styled-components';

import BillingPeriod from 'containers/ReadOnlyBillingPeriodContainer';
import PeriodSelector from 'containers/PeriodSelectorContainer';
import TimesheetHeader from 'containers/TimesheetHeaderContainer';
import TabGroup from 'components/TabGroup';
import TabItem from 'components/TabItem';
import TimesheetHistory from 'containers/TimesheetHistoryContainer';
import { useSelector } from 'react-redux';
import { selectors } from 'modules/timesheet';
import { TimesheetComments } from 'components/Timesheets/TimesheetComments';

const ControlBar = styled.div`
  margin: 0 0 20px;
`;

const UsersLayout = ({ match }) => {
  const timesheet = useSelector(selectors.localTimesheet);
  const timesheetLoading = useSelector(selectors.loading);
  const timesheetRows = timesheet ? timesheet.timesheetRows : [];

  return (
    <div>
      <ControlBar>
        <TimesheetHeader />
        <PeriodSelector userId={match.params.id} />
      </ControlBar>

      <BillingPeriod userId={match.params.id} />

      <TabGroup>
        <TabItem title="Comments">
          <TimesheetComments loading={timesheetLoading} rows={timesheetRows} />
        </TabItem>
        <TabItem title="History">
          <TimesheetHistory />
        </TabItem>
      </TabGroup>
    </div>
  );
};

export default UsersLayout;
