import React from 'react';
import styled from 'styled-components';
import dayjs from 'lib/dayjs';
import { orderBy } from 'lodash';

import Table from 'components/Table';
import { Project } from 'components/Project/Project';
import Loader from 'components/Loader';
import useSort from 'hooks/useSort';

const CommentsTable = styled(Table)`
  .Comments__date,
  .Comments__duration,
  .Comments__project,
  .Comments__comment {
    white-space: nowrap;
    padding: 6px 20px;
  }

  .Comments__date,
  .Comments__duration {
    width: 1%;
    text-align: center;
  }

  .Comments__project {
    min-width: 150px;
    max-width: 375px;
  }

  .Comments__comment {
    white-space: pre-wrap;
    width: 50%;
  }
`;

const TableHeader = styled.th`
  cursor: pointer;
`;

const handleCustomSort = (data, key, sortDirection) => {
  let sortedArr;

  if (key === 'project') {
    sortedArr = orderBy(data, ['project.props.data.name', 'date'], [sortDirection, 'asc']);
  } else {
    sortedArr = orderBy(data, [key, 'date'], [sortDirection, 'asc']);
  }

  return sortedArr;
};

const TimesheetComments = ({ loading = false, rows = [] }) => {
  const defaultSortParams = {
    defaultSortKey: 'date',
    defaultSortDirection: 'asc',
    customSortHandler: handleCustomSort,
  };

  const { sortDirection, sortedRows, getSortIndicator, onSort } = useSort(defaultSortParams);

  const projectDescription = projectRow => {
    return <Project data={projectRow} isAdditionalProject={!!projectRow.additionalProjectId} collapse isReadOnly />;
  };

  const parseRowEntries = ({ timesheetEntries = [], ...timesheetRow }) => {
    return timesheetEntries
      .filter(e => e.note?.length > 0)
      .map(timesheetEntry => {
        const { id, date, note, hours } = timesheetEntry;

        return {
          id,
          date,
          project: projectDescription(timesheetRow),
          hours,
          note,
        };
      });
  };

  const flattenedEntries = rows.reduce((entries, row) => {
    const rowEntries = parseRowEntries(row);
    return rowEntries.length ? [...entries, ...rowEntries] : entries;
  }, []);

  const renderComments = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={4}>
            <Loader compact />
          </td>
        </tr>
      );
    }

    if (!flattenedEntries.length) {
      return (
        <tr>
          <td colSpan={4}>
            <p>No comments to display</p>
          </td>
        </tr>
      );
    }

    const rowsToRender =
      sortedRows.length && sortDirection !== 'default'
        ? sortedRows
        : flattenedEntries.sort((a, b) => new Date(a.date) - new Date(b.date));

    return rowsToRender.map((entry, i) => (
      <tr key={entry.id || `${i}-${entry.date}-${entry.project}`}>
        <td className="Comments__date">{dayjs(entry.date).format('MMM D')}</td>
        <td className="Comments__project">{entry.project}</td>
        <td className="Comments__duration">{entry.hours}</td>
        <td className="Comments__comment">{entry.note}</td>
      </tr>
    ));
  };

  return (
    <CommentsTable>
      <thead>
        <tr>
          <TableHeader className="Comments__date" onClick={() => onSort('date', flattenedEntries)}>
            Date {getSortIndicator('date')}
          </TableHeader>
          <TableHeader className="Comments__project" onClick={() => onSort('project', flattenedEntries)}>
            Project {getSortIndicator('project')}
          </TableHeader>
          <TableHeader className="Comments__duration" onClick={() => onSort('hours', flattenedEntries)}>
            Duration {getSortIndicator('hours')}
          </TableHeader>
          <TableHeader className="Comments__comment" onClick={() => onSort('note', flattenedEntries)}>
            Comment {getSortIndicator('note')}
          </TableHeader>
        </tr>
      </thead>
      <tbody>{renderComments()}</tbody>
    </CommentsTable>
  );
};

export { TimesheetComments };
