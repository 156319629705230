import React, { useCallback, useRef } from 'react';

import {
  PeriodSelectorContainer,
  PeriodSelector as Selector,
  Arrow,
  CurrentPeriod,
  CurrentLink,
} from './PeriodSelector.styles';
import { DATE_FORMATS, getDateRangeString } from 'utils/dates';
import { aria } from 'constants/aria';

const getDateDisplay = (dateInput, dateFormat) => {
  if (!dateInput) return '';
  return getDateRangeString(dateInput, dateFormat);
};

const PeriodSelector = ({ selectedPeriod, timesheetPeriods: periods = [], userId, onPeriodSelect }) => {
  const periodRef = useRef();

  const selectedIndex = periods?.indexOf(selectedPeriod);
  const previousPeriod = periods?.[selectedIndex - 1];
  const nextPeriod = periods?.[selectedIndex + 1];
  const isCurrentPeriod = selectedPeriod?.current;
  const isFirstPeriod = selectedIndex === 0;
  const isLastPeriod = selectedIndex === periods?.length - 1;

  const selectPeriod = useCallback(
    period => {
      onPeriodSelect({ startDate: period, userId });
      periodRef?.current?.focus();
    },
    [userId, onPeriodSelect]
  );

  const selectPreviousPeriod = useCallback(() => {
    if (isFirstPeriod) return null;
    selectPeriod(previousPeriod?.startDate);
  }, [isFirstPeriod, previousPeriod, selectPeriod]);

  const selectNextPeriod = useCallback(() => {
    if (isLastPeriod) return;
    selectPeriod(nextPeriod?.startDate);
  }, [isLastPeriod, nextPeriod, selectPeriod]);

  const selectCurrentPeriod = useCallback(() => {
    if (!periods?.length) return;

    const currentPeriod = periods.find(p => p.current);
    const currentIndex = periods.indexOf(currentPeriod);
    if (currentIndex > -1) {
      selectPeriod(periods[currentIndex].startDate);
    }
  }, [periods, selectPeriod]);

  if (!periods?.length) return null;
  if (!selectedPeriod) return null;

  return (
    <PeriodSelectorContainer>
      <Selector>
        <Arrow
          disabled={isFirstPeriod}
          onClick={selectPreviousPeriod}
          aria-disabled={isFirstPeriod}
          aria-label={
            previousPeriod &&
            aria.timesheet.periodSelectorPrevious({
              dateRange: getDateDisplay(previousPeriod, DATE_FORMATS.readable),
            })
          }
        >
          <i className="fa fa-caret-left" />
        </Arrow>
        <CurrentPeriod
          ref={periodRef}
          aria-label={aria.timesheet.periodSelectorCurrent({
            dateRange: getDateDisplay(selectedPeriod, DATE_FORMATS.readable),
          })}
          role="contentinfo"
          tabIndex="0"
          autoFocus
        >
          {getDateDisplay(selectedPeriod)}
        </CurrentPeriod>
        <Arrow
          disabled={isLastPeriod}
          onClick={selectNextPeriod}
          aria-disabled={isLastPeriod}
          aria-label={
            nextPeriod &&
            aria.timesheet.periodSelectorNext({
              dateRange: getDateDisplay(nextPeriod, DATE_FORMATS.readable),
            })
          }
        >
          <i className="fa fa-caret-right" />
        </Arrow>
      </Selector>
      {!isCurrentPeriod && (
        <div className="PeriodSelectorContainer__current">
          <CurrentLink onClick={selectCurrentPeriod} aria-label={aria.timesheet.periodSelectorCurrentLink()}>
            Current Timesheet
          </CurrentLink>
        </div>
      )}
    </PeriodSelectorContainer>
  );
};

export default PeriodSelector;
