import React from 'react';
import styled from 'styled-components';

import PendingTimesheets from 'containers/PendingTimesheetsContainer';
import PendingPTO from 'components/PTO/PendingPTO';
import { PageBlock } from 'components/layout';

const Page = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(1, 1fr);
`;

const PendingLayout = () => (
  <Page>
    <PageBlock title="Pending Timesheets">
      <PendingTimesheets />
    </PageBlock>
    <PageBlock title="Pending Time Off Requests">
      <PendingPTO />
    </PageBlock>
  </Page>
);

export default PendingLayout;
