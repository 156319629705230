import isFunction from 'lodash/isFunction';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Button from 'components/Button';
import { ButtonIcon } from 'components/ButtonIcon';
import { actions } from 'modules/modal';
import { noop } from 'utils/common';

import { defaultProps, propTypes } from './ModalWrapper.statics';
import StyledModal, { ModalActions, ModalBackdrop } from './ModalWrapper.styled';

export const MODAL_TYPES = {
  alert: 'alert',
  info: 'info',
  success: 'success',
};

class ModalWrapper extends PureComponent {
  static defaultProps = defaultProps;
  static propTypes = propTypes;

  // Wrap Action with close event
  withClose = (action, useReduxCloseAction = false) => () => {
    // TODO - add edited check
    const { id, close } = this.props;
    const modalAction = isFunction(action) ? action : noop;

    if (useReduxCloseAction) {
      modalAction();
    } else {
      modalAction();
      close(id);
    }
  };

  getCloseEvent = () => {
    const { onCancel, useCancelAction } = this.props;
    return this.withClose(onCancel, useCancelAction);
  };

  handleBackgroundClick = e => {
    if (e.target === e.currentTarget) {
      const closeEvent = this.getCloseEvent();
      closeEvent();
    }
  };

  handleKeyPress = ({ keyCode }) => {
    if (keyCode === 27) {
      const closeEvent = this.getCloseEvent();
      closeEvent();
    }
  };

  getButtonType = (type = '') => ({
    alert: type === MODAL_TYPES.alert,
    info: type === MODAL_TYPES.info,
    success: type === MODAL_TYPES.success,
  });

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyPress);
  }

  render() {
    const {
      id,
      buttons,
      children,
      cancelAriaLabel = '',
      closeAriaLabel = '',
      cancelText,
      closeText,
      cancelButton,
      closeButton,
      ignoreAutoFocus,
      useCloseAction,
      useCancelAction,
      showButtonType,
      locked,
      title,
      type,
      width,
      valid,
      onCancel,
      onClose,
    } = this.props;

    const modalButtons = [];

    if (cancelButton) {
      modalButtons.push({
        'aria-label': cancelAriaLabel,
        autoFocus: !!closeButton && !ignoreAutoFocus,
        action: onCancel,
        useReduxAction: useCancelAction,
        text: cancelText,
      });
    }

    if (closeButton) {
      const buttonType = type || MODAL_TYPES.info;
      const showType = showButtonType || cancelButton;

      modalButtons.push({
        'aria-label': closeAriaLabel,
        autoFocus: !cancelButton && !ignoreAutoFocus,
        action: onClose,
        useReduxAction: useCloseAction,
        text: closeText,
        type: showType ? buttonType : null,
        disabled: !valid,
      });
    }

    const modalActions = [...buttons, ...modalButtons];

    const renderHeader = () => {
      if (!title && !locked) return null;

      const closeEvent = this.getCloseEvent();
      return (
        <div className="Modal__header">
          <div className="Modal__header-left">{title}</div>
          <div className="Modal__header-right">
            <ButtonIcon onClick={closeEvent} icon="times" transparent />
          </div>
        </div>
      );
    };

    const renderFooter = () => {
      if (!modalActions.length) return null;

      return (
        <ModalActions length={modalActions.length}>
          {modalActions.map(({ action, type, text, useReduxAction, ...props }, i) => (
            <Button
              className="Modal__action"
              key={i}
              onClick={this.withClose(action, useReduxAction)}
              {...this.getButtonType(type)}
              {...props}
            >
              {text}
            </Button>
          ))}
        </ModalActions>
      );
    };

    return (
      <ModalBackdrop key={id} onClick={!locked ? this.handleBackgroundClick : noop}>
        <StyledModal style={{ width: width ? `${width}px` : '100%' }} type={type} hasFooter={modalActions.length > 0}>
          {renderHeader()}
          <div className="Modal__content">{children}</div>
          {renderFooter()}
        </StyledModal>
      </ModalBackdrop>
    );
  }
}

export default connect(null, { close: actions.closeModal })(ModalWrapper);
