import styled from 'styled-components';
import { colors } from 'theme/theme';

const Button = styled.button.attrs({ type: 'button ' })`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.grayCharcoal};
  cursor: pointer;
  outline: none;
  border-radius: 2px;
  background-color: ${colors.white};
  border: solid 1px ${colors.grayLight};
  text-transform: uppercase;
  padding: ${props => (props.square ? 0 : '10px')} ${props => (props.square ? 0 : '20px')};
  height: ${props => (props.square ? '36px' : 'auto')};
  width: ${props => (props.square ? '36px' : 'auto')};
  transition: all 300ms;

  &:hover {
    ${props => !props.disabled && 'opacity: 0.8;'};
  }

  ${props => props.transparent && `background: transparent`};
  ${props => props.transparent && `border-color: transparent`};
  ${props => props.transparent && `color: ${colors.grayCharcoal}`};

  ${props => props.info && `background: ${colors.blueDodger}`};
  ${props => props.info && `border-color: ${colors.blueDenim}`};
  ${props => props.info && `color: ${colors.white}`};

  ${props => props.warning && `background: ${colors.yellowDandelion}`};
  ${props => props.warning && `border-color: ${colors.yellowPutty}`};
  ${props => props.warning && `color: ${colors.black}`};

  ${props => props.success && `background: ${colors.greenSushi}`};
  ${props => props.success && `border-color: ${colors.greenSushi}`};
  ${props => props.success && `color: ${colors.white}`};

  ${props => props.alert && `background: ${colors.redPersian}`};
  ${props => props.alert && `border-color: ${colors.redPersian}`};
  ${props => props.alert && `color: ${colors.white}`};

  ${props => props.disabled && 'opacity: 0.5'};
  ${props => props.disabled && 'cursor: default'};

  ${props => props.size === 's' && 'padding: 8px 12px'};
  ${props => props.size === 'm' && 'padding: 10px 16px'};
  ${props => props.size === 'l' && 'padding: 12px 20px'};
`;

export default Button;
