import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SubmitButton from 'components/SubmitButton';
import { actions as modalActions } from 'modules/modal';
import { actions, selectors } from 'modules/timesheet';
import { actions as toastActions } from 'modules/toast';

class SubmitButtonContainer extends PureComponent {
  onSubmit = () => {
    const { createToast, edited, hasPendingRows, showModal, submitTimesheet } = this.props;

    if (edited) {
      return createToast('alert', {
        id: 'TIMESHEET_UNSUBMITTED',
        title: 'Unsaved Changes',
        closeText: 'Got It',
        message: 'You have unsaved changes, please save your timesheet and try again.',
      });
    }

    if (hasPendingRows) {
      return showModal('confirm', {
        title: 'Submit Timesheet',
        type: 'alert',
        onClose: submitTimesheet,
        closeText: 'Yes, Submit Anyways',
        cancelText: 'No, Cancel',
        message: `You currently have some pending time off requests. Are you sure you still want to submit your timesheet?`,
      });
    }

    showModal('confirm', {
      title: 'Submit Timesheet',
      type: 'success',
      onClose: submitTimesheet,
      closeText: 'Yes, Submit',
      cancelText: 'No, Cancel',
      message: `Are you sure you want to submit your timesheet? You won't be able to make any changes once submitted!`,
    });
  };

  render() {
    return <SubmitButton {...this.props} onSubmit={this.onSubmit} />;
  }
}

const mapState = state => ({
  approved: selectors.approved(state),
  disabled: selectors.disabled(state),
  edited: selectors.edited(state),
  hasPendingRows: selectors.hasPendingRows(state),
  status: selectors.status(state),
  totalHours: selectors.totalHours(state),
});

const mapDispatch = {
  createToast: toastActions.createToast,
  showModal: modalActions.showModal,
  submitTimesheet: actions.submitTimesheet,
};

export default connect(mapState, mapDispatch)(SubmitButtonContainer);
