import React from 'react';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

const TitledCell = ({ children }) => {
  let title = children;
  if (isObject(children)) title = '';
  if (isArray(children)) title = children.join('');
  return <td title={title}>{children}</td>;
};

export default TitledCell;
