import React from 'react';
import styled from 'styled-components';

import { colors } from 'theme/theme';

const StyledTextArea = styled.textarea`
  display: block;
  border: 1px solid ${colors.grayNobel};
  border-radius: 3px;
  outline: none;
  box-shadow: none;
  width: 100%;
  min-height: 100px;
  font-size: 16px;
  padding: 8px;
`;

const TextArea = ({ ...props }) => <StyledTextArea {...props} />;

export default TextArea;
