import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { Accessible } from './Accessible';

const StyledRow = styled(Accessible)`
  td {
    transition: background 500ms;
  }

  &:hover td {
    cursor: pointer;
    ${props => props.disabled && `cursor: not-allowed`};
    ${props => props.hover && `background: ${props.hover}`};
  }
`;

const LinkRow = ({ children, to, onClick, history, ...props }) => (
  <StyledRow
    elementType="tr"
    role="row"
    onClick={async e => {
      if (props.disabled) return;

      await onClick?.(e);
      history.push(to);
    }}
    {...props}
  >
    {children}
  </StyledRow>
);

export default withRouter(LinkRow);
