import React from 'react';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';
import { PowerBIEmbed as PowerBIEmbedBase } from 'powerbi-client-react';

const HEADER_HEIGHT = 120;
const VIEW_PADDING = 25;
const BREADCRUMB_HEIGHT = 60;
const HEIGHT_OFFSET = HEADER_HEIGHT + VIEW_PADDING * 2 + BREADCRUMB_HEIGHT;
const MIN_HEIGHT = 432;

const PowerBIEmbed = ({ height = '100%', autoHeight, css, style, className, ...rest }) => {
  const { height: windowHeight } = useWindowSize();

  const embedHeight = autoHeight ? `${Math.max(MIN_HEIGHT, windowHeight - HEIGHT_OFFSET)}px` : height;

  return (
    <Container {...{ css, style, className, embedHeight }}>
      <PowerBIEmbedBase {...rest} cssClassName={`${rest.cssClassName || ''} powerbi-embed`} />
    </Container>
  );
};

const Container = styled.div`
  .powerbi-embed {
    height: ${({ embedHeight }) => `${embedHeight}`};

    & iframe {
      border: 0;
    }
  }
`;

export default PowerBIEmbed;
