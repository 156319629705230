import Rollbar from 'rollbar';
import { version } from '../../package.json';

const { REACT_APP_ROLLBAR_TOKEN, REACT_APP_ROLLBAR_ENV, NODE_ENV } = process.env;

const accessToken = REACT_APP_ROLLBAR_TOKEN;
const environment = REACT_APP_ROLLBAR_ENV || NODE_ENV;

const isDev = environment === 'development';

if (!isDev && !accessToken) throw new Error('No access token provided for rollbar');

export const baseConfig = {
  accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: { environment },
  version,
};

const rollbar = new Rollbar(baseConfig);

export default rollbar;
