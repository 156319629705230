import { PureComponent } from 'react';
import { connect } from 'react-redux';

import RescindButton from 'components/RescindButton';
import { actions as modalActions } from 'modules/modal';
import { actions, selectors } from 'modules/timesheet';

class RescindButtonContainer extends PureComponent {
  onSubmit = () => {
    const { showModal, rescindTimesheet } = this.props;

    showModal('confirm', {
      title: 'Undo Timesheet Submission',
      type: 'success',
      onClose: rescindTimesheet,
      closeText: 'Yes, Undo Submission',
      cancelText: 'No, Cancel',
      message: `
        Are you sure you want to undo submission of your timesheet?
        You will be able to make changes and resubmit.
      `,
    });
  };

  render() {
    if (!this.props.pending) return null;
    return <RescindButton {...this.props} onSubmit={this.onSubmit} />;
  }
}

const mapState = state => ({
  pending: selectors.pending(state),
  disabled: selectors.loading(state) || selectors.saving(state),
});

const mapDispatch = {
  showModal: modalActions.showModal,
  rescindTimesheet: actions.rescindTimesheet,
};

export default connect(mapState, mapDispatch)(RescindButtonContainer);
