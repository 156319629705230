import React from 'react';
import styled from 'styled-components';

import { colors } from 'theme/theme';
import { Accessible } from './Accessible';

const StyledSection = styled.div`
  margin: 0 0 25px;

  &:last-child {
    margin: 0;
  }

  .Section__header {
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    color: ${colors.grayNeutral};
    border-bottom: 1px solid ${colors.grayLight};
  }

  .Section__content {
    margin-left: auto;
  }
`;

const Section = ({ children, label, ...props }) => (
  <StyledSection {...props}>
    {label && (
      <Accessible elementType="div" role="heading" aria-label={label} className="Section__header">
        {label}
      </Accessible>
    )}
    <div className="Section__content">{children}</div>
  </StyledSection>
);

export { Section };
