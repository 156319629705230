import styled, { css } from 'styled-components';

import { colors } from 'theme/theme';

export const cellWidth = 50;
export const addButtonSize = 42;

export const borderColor = colors.graySilver;

const styledButton = css`
  border: 1px solid ${borderColor};
  cursor: pointer;
  outline: none;
  text-align: center;

  &:hover {
    background: ${colors.graySmoke};
  }
`;

const styledRow = css`
  background: ${colors.graySmoke};
  color: ${colors.grayDim};
  font-size: 12px;
  text-align: center;

  & > th,
  & > td {
    height: 60px;
    border-top: 1px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};

    &:first-child {
      border-left: 1px solid ${borderColor};
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-right: 1px solid ${borderColor};
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

const BillingPeriod = styled.div`
  margin: 0 0 20px;
  padding: 0 10px;

  &:last-child {
    margin: 0;
  }

  .BillingPeriod__notes {
    position: absolute;
    z-index: 100;
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
  }

  .BillingPeriod__notes-icon {
    position: absolute;
    z-index: 3;
    margin-top: -5px;
    right: 0;
    color: ${colors.grayHoki};
    font-size: 12px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.333);
  }

  .BillingPeriod__notes-toggle {
    position: absolute;
    left: 50%;
    margin-top: -5px;
    transform: translate(-50%, -100%);
    z-index: 100;
    cursor: pointer;
    color: white;
    font-size: 20px;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.65);
    opacity: 0.65;
  }

  .BillingPeriod__button {
    ${styledButton};
  }

  .BillingPeriod__button-add {
    width: ${addButtonSize}px;
    height: ${addButtonSize}px;
    background: ${colors.white};
    position: absolute;
    left: -10px;
    border-radius: ${addButtonSize / 2}px;
    font-size: 14px;
    color: ${colors.grayCharcoal};
  }

  .BillingPeriod__button-remove {
    border-radius: 2px;
    background: transparent;
    font-size: 14px;
    background-color: ${colors.graySmoke};
    color: ${colors.grayDim};
  }

  .BillingPeriod__spacer {
    position: relative;
  }
`;

export const BillingPeriodTable = styled.table`
  border-spacing: 0;
  width: 100%;
  min-width: 825px;

  & > tbody:before,
  & > tbody:after {
    content: '';
    display: table-row;
    height: 15px;
  }

  .BillingPeriodTable__header {
    ${styledRow};
  }

  .BillingPeriodTable__header-label {
    min-width: 225px;
  }

  .BillingPeriodTable__footer {
    ${styledRow};
  }

  .BillingPeriodTable__total {
    color: ${colors.grayNight};
    text-align: center;
    font-weight: 700;
    line-height: 33px;
    font-size: 15px;
    padding: 0 8px;
  }
`;

export const DragCell = styled.td`
  cursor: grab;
  color: ${colors.grayDark};
  min-width: 25px;
  max-width: 25px;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  line-height: 0;
  ${props => props.disabled && 'cursor: not-allowed;'};
  ${props => props.disabled && 'opacity: 0.6;'};

  &:hover {
    ${props => !props.disabled && `color: ${colors.grayCharcoal};`};
  }

  svg {
    width: 10px;
    height: auto;
  }
`;

export const ProjectCell = styled.td`
  min-width: 200px;
  max-width: 250px;
`;

export const HeaderCell = styled.th`
  width: ${cellWidth}px;
  color: ${props => (props.isToday ? colors.blueDodger : colors.grayNight)};
  font-weight: ${props => (props.isToday ? '700' : 'normal')};

  .HeaderCell__date {
    color: ${props => (props.isToday ? `${colors.blueDodger}` : `${colors.grayDim}`)};
  }
`;

export const FooterCell = styled.th`
  text-align: center;
  font-size: 15px;
  color: ${props => (props.isToday ? colors.blueDodger : colors.grayNobel)};
  font-weight: 700;
`;

export const TimesheetRow = styled.tr`
  vertical-align: middle;

  .TimesheetRow__cell {
    position: relative;
    width: ${cellWidth}px;
    padding: 5px 3px;
  }
`;

export const CellInput = styled.input`
  width: 100%;
  height: 33px;
  border-radius: 3px;
  background-color: ${props => {
    if (props.disabled) {
      return props.isWeekend || props.isHoliday ? colors.grayHint : colors.grayTint;
    }
    return props.isWeekend || props.isHoliday ? colors.grayTint : colors.white;
  }};
  border: 1px solid ${borderColor};
  border-color: ${props => (props.disabled ? 'transparent' : borderColor)};
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  outline: none;
  -webkit-text-fill-color: ${props => (props.disabled ? colors.grayNeutral : colors.black)};
  -webkit-opacity: 1;
  opacity: 1;
  color: ${props => (props.disabled ? colors.grayNeutral : colors.black)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'default')};
`;

export default BillingPeriod;
