import React, { Component } from 'react';
import omit from 'lodash/omit';

import Login from './Login';

class LoginContainer extends Component {
  handleSignIn = () => {
    this.props.userLogin();
  };

  getChildProps() {
    return {
      ...omit(this.props, ['userLogin']),
      handleSignIn: this.handleSignIn.bind(this),
    };
  }

  render() {
    return <Login {...this.getChildProps()} />;
  }
}

export default LoginContainer;
