import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions, selectors } from 'modules/pto';

import PTOBalance from './PTOBalance';

class PTOBalanceContainer extends PureComponent {
  componentDidMount() {
    this.props.fetchBalances();
  }

  render() {
    return <PTOBalance {...this.props} />;
  }
}

const mapState = state => ({
  balances: selectors.balances(state),
  loading: selectors.loadingBalances(state),
  year: selectors.year(state),
  isViewingHistory: selectors.isViewingHistory(state),
});

const mapDispatch = {
  fetchBalances: actions.fetchBalances,
};

export default connect(mapState, mapDispatch)(PTOBalanceContainer);
