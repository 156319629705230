import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme/theme';

const StyledPane = styled.div`
  position: absolute;
  left: 0;
  bottom: -5px;
  transform: translateY(100%);

  .Pane__tail {
    position: relative;
    margin-left: 12px;

    &,
    &::before {
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 8px solid ${colors.grayLight};
    }

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 1px;
      left: -7px;
      border-bottom: 8px solid ${colors.graySmoke};
    }
  }

  .Pane__header {
    background-color: ${colors.graySmoke};
    font-weight: 700;
    padding: 10px 32px 10px 10px;
  }

  .Pane__content {
    box-shadow: 0 3px 4px 0 rgb(0 0 0 / 5%);
    border: 1px solid ${colors.grayLight};
    background-color: ${colors.white};
    min-width: 225px;
    font-size: 14px;
  }

  .Pane__item {
    cursor: pointer;
    max-width: 300px;
    border-bottom: 1px solid ${colors.graySmoke};

    &.selected,
    &:hover {
      background-color: ${colors.graySmoke};
    }
  }
`;

const PaneCloseButton = styled.i.attrs({ className: 'fa fa-times' })`
  display: block;
  position: absolute;
  top: 12px;
  right: 6px;
  font-size: 18px;
  padding: 5px;
  color: ${colors.grayCharcoal};
  cursor: pointer;

  &:hover {
    color: ${colors.grayMortar};
  }
`;

const Pane = ({ children, title, onClose, ...props }) => {
  const hasTitle = !!title;

  const renderHeader = () => {
    if (!hasTitle) return <PaneCloseButton onClick={onClose} />;

    return (
      <div className="Pane__header">
        {title}
        <PaneCloseButton onClick={onClose} />
      </div>
    );
  };

  return (
    <StyledPane {...props}>
      <div className="Pane__tail" />
      <div className="Pane__content">
        {renderHeader()}
        {children}
      </div>
    </StyledPane>
  );
};

export { Pane };
