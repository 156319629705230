import useSWR from 'swr';
import { appFetcher } from 'utils/appFetcher';

export const useFetch = (key, options) => {
  const { data, error, ...rest } = useSWR(key, appFetcher, options);

  const isLoading = !data && !error;
  const isError = !!error;

  return { ...rest, data, error, isLoading, isError };
};
