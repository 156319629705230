import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { selectors } from 'modules/timesheet';

export const TimesheetHeader = styled.h2`
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin: 0 0 15px;
`;

class TimesheetHeaderContainer extends PureComponent {
  render() {
    const { timesheet } = this.props;

    return timesheet ? (
      <TimesheetHeader>
        Viewing Timesheet for <strong>{timesheet.employee}</strong>
      </TimesheetHeader>
    ) : null;
  }
}

const mapStateToProps = state => ({
  timesheet: selectors.localTimesheet(state),
});

export default connect(mapStateToProps)(TimesheetHeaderContainer);
