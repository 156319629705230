import dayjs from 'lib/dayjs';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProjectTimeReport from 'components/Reports/ProjectTimeReport';
import { DATE_FORMAT } from 'lib/constants';
import { actions, selectors } from 'modules/reports';

const currentDate = new Date();

class ProjectTimeReportContainer extends PureComponent {
  state = {
    startDate: dayjs(currentDate),
    endDate: dayjs(currentDate),
    departmentId: '',
    clientId: '',
    employeeId: '',
    projectId: '',
    filteredProjects: [],
  };

  selectStartDate = date => {
    this.setState(() => ({ startDate: date }));
  };

  selectEndDate = date => this.setState(() => ({ endDate: date }));

  setDepartmentId = id => this.setState(() => ({ departmentId: String(id) }));

  setClientId = id => {
    this.setState(() => ({ clientId: String(id), projectId: '' }));
  };

  setProjectId = id => {
    this.setState(() => ({ projectId: String(id) }));
  };

  setEmployeeId = id => {
    this.setState(() => ({ employeeId: String(id) }));
  };

  setEngagementManagerId = id => {
    this.setState(() => ({ engagementManagerId: String(id) }));
  };

  setFilteredProjects = (filteredProjects, clearEmployee) => {
    this.setState(state => ({ filteredProjects, employeeId: clearEmployee ? '' : state.employeeId }));
  };

  runReport = () => {
    const { startDate, endDate, departmentId, clientId, employeeId, engagementManagerId, projectId } = this.state;
    this.props.fetchProjectTimeReport(
      startDate.format(DATE_FORMAT),
      endDate.format(DATE_FORMAT),
      departmentId,
      clientId,
      employeeId,
      engagementManagerId,
      projectId
    );
  };

  render() {
    const { loading } = this.props;

    return (
      <ProjectTimeReport
        {...this.props}
        {...this.state}
        loading={loading}
        selectStartDate={this.selectStartDate}
        selectEndDate={this.selectEndDate}
        setDepartmentId={this.setDepartmentId}
        setClientId={this.setClientId}
        setEmployeeId={this.setEmployeeId}
        setEngagementManagerId={this.setEngagementManagerId}
        setProjectId={this.setProjectId}
        setFilteredProjects={this.setFilteredProjects}
        filteredProjects={this.state.filteredProjects}
        runReport={this.runReport}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: selectors.loading(state),
    report: selectors.projectTime(state),
  };
};

export default connect(mapStateToProps, { fetchProjectTimeReport: actions.fetchProjectTimeReport })(
  ProjectTimeReportContainer
);
