import React from 'react';
import styled from 'styled-components';
import dayjs from 'lib/dayjs';

import InputContainer from 'components/InputContainer';
import Select from 'components/Select';

import { selectors } from 'modules/pto';
import { useSelector } from 'react-redux';
import ApproverSelector from 'containers/team/ApproverSelectorContainer';

const Options = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Option = styled(InputContainer)`
  min-width: 150px;
`;

const buildOptions = options => options.map(str => ({ label: str, value: str }));

const getYears = (startYear = 2017) => {
  const currentYear = dayjs().year() + 1;

  if (startYear >= currentYear) {
    return [currentYear];
  }

  return [...Array(currentYear - startYear + 1)].map((_, i) => startYear + i).reverse();
};

const PTOOverviewOptions = ({ options, onOptionChange }) => {
  const years = getYears(2017);
  const value = useSelector(selectors.teamPtoOverviewYear);

  return (
    <Options>
      <Option>
        <h3>Year</h3>
        <Select options={buildOptions(years)} onChange={onOptionChange('year')} clearable={true} value={value} />
      </Option>
      <ApproverSelector />
    </Options>
  );
};

export default PTOOverviewOptions;
