import React from 'react';

import Button from 'components/Button';

const RequestPTOButton = ({ onButtonClick }) => {
  return (
    <Button info onClick={onButtonClick}>
      Request Time Off
    </Button>
  );
};

export default RequestPTOButton;
