import React from 'react';
import dayjs from 'lib/dayjs';
import Loader from 'components/Loader';
import Table from 'components/Table';
import StatusText from 'components/StatusText';
import { DATE_TIME_FORMAT_READABLE } from 'lib/constants';

const History = ({ loading, rows = [] }) => {
  if (loading) return <Loader />;

  if (!rows.length) return <h5>No history to display.</h5>;

  return (
    <Table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Status</th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody>
        {rows.map(item => (
          <tr key={item.id}>
            <td>{dayjs(item.createdAt).format(DATE_TIME_FORMAT_READABLE)}</td>
            <td>
              <StatusText status={item.status} />
              {item.spudUser && <span> by {item.spudUser.name}</span>}
            </td>
            <td>{item.note}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default History;
