import React from 'react';

import { colors } from 'theme/theme';
import changeMethod from 'utils/changeMethod';

import { Comment } from './PTOBulkActionModal.styled';
import ModalWrapper from 'components/Modals/ModalWrapper';
import LabeledInput from 'components/LabeledInput';
import TextArea from 'components/TextArea';

const VERBIAGE = {
  approve: {
    verb: 'approve',
    verbTitle: 'Approve',
    noun: 'approval',
  },
  reject: {
    verb: 'reject',
    verbTitle: 'Reject',
    noun: 'rejection',
  },
};

const PTOBulkActionModal = props => {
  const {
    MAX_COMMENT_LENGTH,
    actionQuantity,
    actionType,
    details,
    comment,
    onSubmit,
    onUpdate,
    validation,
    ...rest
  } = props;

  const verbiage = VERBIAGE[actionType];

  const renderBulk = () => {
    return (
      <div>
        <p>
          You're about to {verbiage.verb} <strong>{actionQuantity}</strong> requests.
        </p>

        {actionType === 'approve' && <p>You can enter an optional comment explaining the {verbiage.noun}.</p>}
        {actionType === 'reject' && <p>Please enter a comment explaining the {verbiage.noun}.</p>}
      </div>
    );
  };

  const renderInputs = () => {
    const commentRemaining = MAX_COMMENT_LENGTH - comment.length;

    return (
      <>
        {renderBulk()}
        <LabeledInput
          label={
            <span>
              Comment{' '}
              {actionType === 'reject' && (
                <Comment style={{ display: 'inline-flex', fontSize: 11, fontWeight: 300 }}> - Required</Comment>
              )}
            </span>
          }
          style={{ gridColumn: '1 / span 2' }}
        >
          <TextArea name="comment" onChange={changeMethod(onUpdate)} value={comment} autoFocus />
          <Comment>
            Maximum {MAX_COMMENT_LENGTH} characters{' '}
            <span style={{ color: commentRemaining >= 0 ? 'inherit' : colors.redBrick }}>
              ({commentRemaining} remaining)
            </span>
          </Comment>
        </LabeledInput>
      </>
    );
  };

  return (
    <ModalWrapper
      type="alert"
      title="Time Off Admin Action"
      closeText="Yes, Confirm"
      cancelText="No, Cancel"
      onClose={onSubmit}
      valid={validation(props)}
      width={400}
      locked
      {...rest}
    >
      {renderInputs()}
    </ModalWrapper>
  );
};

export default PTOBulkActionModal;
