import styled from 'styled-components';
import { colors } from 'theme/theme';

export const HeaderContainer = styled.div`
  flex: 0 0 auto;
`;

const Header = styled.header`
  background: ${colors.grayHoki};
  padding: 38px 25px 18px;

  .Header__details {
    display: flex;
    align-items: flex-end;
  }

  .Header__logo {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .Header__menu-container {
    flex: 1 1 auto;
  }

  .Header__menu {
    font-size: 18px;
    font-weight: 500;
    margin: 0 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  .Header__menu-actions {
    margin-left: auto;
  }

  .Header__menu-item {
    color: ${colors.graySmoke};
    text-decoration: none;
    margin-right: 15px;

    &.disabled,
    &:disabled {
      color: ${colors.graySilver};
      cursor: not-allowed;
    }

    &:last-child {
      margin: 0;
    }
  }

  .Header__menu-item--active {
    color: ${colors.white};
    border-bottom: 2px solid ${colors.orangeGambage};
  }
`;

export default Header;
