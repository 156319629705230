import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';

import { createBrowserHistory } from 'history';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';

import createRootReducer, { rootSaga } from 'modules/root';

import { IS_DEV, IS_PROD } from 'utils/common';

/*
 * Middleware & enhancers
 */
const middleware = [];

// middleware: Redux Router
export const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);
middleware.push(routerMiddleware);

// middleware: Redux Saga
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

// middleware: Redux Logger
if (IS_DEV) middleware.push(createLogger({ collapsed: true }));

const enhancers = [];

// enhancer: middleware chain
enhancers.push(applyMiddleware(...middleware));

// enhancer: Redux DevTools
if (!IS_PROD) {
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const rootReducer = createRootReducer(history);

// export the created store
const store = createStore(rootReducer, compose(...enhancers));

// run the root saga
sagaMiddleware.run(rootSaga);

export default store;
