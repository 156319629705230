import { connect } from 'react-redux';
import styled from 'styled-components';

import { StyledButton } from 'components/SubmitButton';
import { actions as modalActions } from 'modules/modal';
import { actions } from 'modules/team';
import { colors } from 'theme/theme';

const Button = styled(StyledButton)`
  background-color: ${colors.blueDodger};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.blueDenim};
  }
`;

const ApproveButtonContainer = ({ approveTimesheet, showModal, loading, ...props }) => (
  <Button
    {...props}
    onClick={() => {
      showModal('confirm', {
        title: 'Confirm Approval',
        message: 'Are you sure you want to approve this timesheet?',
        type: 'success',
        closeText: 'Yes, Approve',
        cancelText: 'No, Cancel',
        onClose: approveTimesheet,
      });
    }}
    title="Approve Timesheet"
  >
    <i className="fa fa-check" />
    Approve Timesheet
  </Button>
);

const mapDispatch = { approveTimesheet: actions.teamApproveTimesheet, showModal: modalActions.showModal };

export default connect(null, mapDispatch)(ApproveButtonContainer);
