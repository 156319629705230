import styled from 'styled-components';

import { colors } from 'theme/theme';

const LoaderBlock = styled.div`
  height: ${props => (props.height ? `${props.height}px` : '20px')};
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  border-radius: 3px;
  background: ${colors.grayTint};
`;

export default LoaderBlock;
