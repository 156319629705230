import React from 'react';
import styled from 'styled-components';
import HelpFabLink from 'components/Help/HelpFabLink';

const StyledView = styled.div`
  position: relative;
  flex: 1 1 auto;

  .View__scroll {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding: 25px;
    scroll-behavior: smooth;
    padding-bottom: 6rem;
  }
`;

const View = ({ fab = <HelpFabLink />, children, ...rest }) => (
  <StyledView {...rest}>
    <div className="View__scroll">
      {children}
      {fab}
    </div>
  </StyledView>
);

export default View;
