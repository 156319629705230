import React from 'react';
import styled from 'styled-components';

const TabItem = ({ active, children }) => {
  if (!active) return null;
  return <Tab className="tab-group__item">{children}</Tab>;
};

const Tab = styled.div`
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 1em;
  }
`;

export default TabItem;
