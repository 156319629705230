import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from 'theme/theme';

const BreadcrumbsContainer = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin: 20px 0;
  color: ${colors.grayMortar};

  a,
  a:visited,
  a:hover,
  a:active {
    color: ${colors.grayMortar};
    text-decoration: none;
  }
`;

const Crumb = styled.div`
  display: inline-block;

  &:not(:last-child):after {
    content: '•';
    display: inline-block;
    padding: 0 10px;
  }
`;

const Breadcrumbs = ({ routes }) => (
  <BreadcrumbsContainer>
    {routes.map((route, i) => (
      <Crumb key={i}>{route.url ? <Link to={route.url}>{route.display}</Link> : <span>{route.display}</span>}</Crumb>
    ))}
  </BreadcrumbsContainer>
);

export default Breadcrumbs;
