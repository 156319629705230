import React from 'react';
import dayjs from 'lib/dayjs';
import styled from 'styled-components';

import BillingPeriod from 'components/BillingPeriod';
import TabGroup from 'components/TabGroup';
import TabItem from 'components/TabItem';
import { TimesheetComments } from 'components/Timesheets/TimesheetComments';
import History from 'components/History';
import Loader from 'components/Loader';
import SubmitButton from 'containers/SubmitButtonManagerContainer';
import ApproveButton from 'containers/ApproveButtonContainer';
import RejectButton from 'containers/RejectButtonContainer';
import UnapproveButton from 'containers/UnapproveButtonContainer';

import { TIMESHEET_STATUSES } from 'lib/constants';
import { aria } from 'constants/aria';

const periodString = ({ startDate, endDate }) =>
  `${dayjs(startDate).format('MMM DD')} - ${dayjs(endDate).format('DD')}`;

const Root = styled.div`
  h2 {
    margin: 0;
  }

  h4 {
    margin: 30px 0 15px;
  }

  .tab-group {
    margin-top: 30px;
  }
`;

const TopBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -8px;
  > button {
    margin: 8px;
  }
`;

const MemberTimesheet = ({ loading, timesheet, timesheetRows, history }) => {
  if (loading) return <Loader />;

  if (!timesheet)
    return (
      <Root>
        Timesheet not found.{` `}
        <span role="img" aria-label={aria.various.emojiCry()}>
          😢
        </span>
      </Root>
    );

  const isUnsubmitted = timesheet.status === TIMESHEET_STATUSES.UNSUBMITTED;
  const isPending = timesheet.status === TIMESHEET_STATUSES.PENDING;
  const isApproved = timesheet.status === TIMESHEET_STATUSES.APPROVED;
  const isRejected = timesheet.status === TIMESHEET_STATUSES.REJECTED;
  const isUnapproved = timesheet.status === TIMESHEET_STATUSES.UNAPPROVED;

  return (
    <Root>
      <TopBar>
        <h2>
          {timesheet.employee}'s timesheet for {periodString(timesheet.timesheetPeriod)}
        </h2>
      </TopBar>

      <BillingPeriod timesheetPeriod={timesheet.timesheetPeriod} timesheetRows={timesheetRows} disabled />

      <ButtonGroup>
        {(isUnsubmitted || isRejected) && <SubmitButton />}
        {(isPending || isUnapproved) && <ApproveButton />}
        {(isPending || isUnapproved) && <RejectButton />}
        {isApproved && <UnapproveButton />}
      </ButtonGroup>

      <TabGroup>
        <TabItem title="Comments">
          <TimesheetComments rows={timesheetRows} />
        </TabItem>
        <TabItem title="History">
          <History rows={history} />
        </TabItem>
      </TabGroup>
    </Root>
  );
};

export default MemberTimesheet;
