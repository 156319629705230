import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions } from 'modules/modal';
import { PTO_REQUEST_MODAL } from 'modules/pto';

import RequestPTOButton from './RequestPTOButton';

class RequestPTOButtonContainer extends PureComponent {
  render() {
    const { showModal } = this.props;
    return <RequestPTOButton onButtonClick={() => showModal('PTORequest', { id: PTO_REQUEST_MODAL })} />;
  }
}

export default connect(null, { showModal: actions.showModal })(RequestPTOButtonContainer);
