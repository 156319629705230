import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PeriodSelector from 'components/PeriodSelector';
import { actions as projectsActions } from 'modules/projects';
import { actions as periodActions, selectors as periodSelectors } from 'modules/timesheetPeriods';
import useQueryParams from 'hooks/useQueryParams';

const PeriodSelectorContainer = props => {
  const { userId } = props;

  const { getParams, setParams } = useQueryParams();
  const { startDate: queryStartDate } = getParams();
  const dispatch = useDispatch();

  const timesheetPeriods = useSelector(periodSelectors.periods);
  const currentPeriod = useSelector(periodSelectors.currentPeriod);

  const selectedPeriod = useMemo(() => {
    const foundPeriod = timesheetPeriods.find(period => period.startDate === queryStartDate);
    return foundPeriod ?? currentPeriod;
  }, [queryStartDate, timesheetPeriods, currentPeriod]);

  const changePeriod = useCallback(
    period => {
      dispatch(periodActions.selectTimesheetPeriod(period));
      dispatch(projectsActions.fetchProjects(period.startDate));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!selectedPeriod) return;
    changePeriod({ ...selectedPeriod, userId });
  }, [changePeriod, selectedPeriod, userId]);

  const handlePeriodSelect = period => {
    setParams({ startDate: period.startDate });
  };

  return (
    <PeriodSelector
      {...props}
      selectedPeriod={selectedPeriod}
      timesheetPeriods={timesheetPeriods}
      currentPeriod={currentPeriod}
      onPeriodSelect={handlePeriodSelect}
    />
  );
};

export default PeriodSelectorContainer;
