import React, { PureComponent } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import dayjs from 'lib/dayjs';

import { TableContainer } from './Reports.styled';
import Table from 'components/Table';
import TitledCell from 'components/TitledCell';

class ProjectTimeReportTable extends PureComponent {
  renderRow = row => {
    const lastName = row.user.name.split(' ')[1];
    const name = row.user.name.split(' ');
    const formattedName = `${name[1]}, ${name[0]}`;

    return (
      <tr key={row.id}>
        <TitledCell>{row.engagement?.clientName ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.projectId ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.name ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.engagementManagerName ?? ''}</TitledCell>
        <TitledCell>{formattedName}</TitledCell>
        <TitledCell>{lastName}</TitledCell>
        <TitledCell>{row.role}</TitledCell>
        <TitledCell>
          {row.timesheetPeriod.startDate} - {row.timesheetPeriod.endDate}
        </TitledCell>
        <TitledCell>{row.hours}</TitledCell>
        <TitledCell>{row.date}</TitledCell>
        <TitledCell>{row.note}</TitledCell>
      </tr>
    );
  };

  render() {
    const { records } = this.props;
    const fileName = `Project-Time-Report-${dayjs().format('L')}`;

    return (
      <TableContainer>
        <div className="Table__actions">
          <div className="Table__comment">Hover over cell for full contents</div>

          <ReactHTMLTableToExcel
            className="download-table-xls-button"
            table="table-to-xls"
            filename={fileName}
            sheet="report1"
            buttonText="Download as XLS"
          />
        </div>
        <div className="Table__scroller">
          <Table id="table-to-xls">
            <thead>
              <tr>
                <th>Client Name</th>
                <th>Project Code</th>
                <th>Project Name</th>
                <th>Engagement Manager</th>
                <th>User Name</th>
                <th>User Last Name</th>
                <th>Task Name</th>
                <th>Timesheet Period</th>
                <th>Project Hrs.</th>
                <th>Entry Date</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>{records.length > 0 && records.map(this.renderRow)}</tbody>
          </Table>
        </div>
      </TableContainer>
    );
  }
}

export default ProjectTimeReportTable;
