import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MemberTimesheet from 'components/MemberTimesheet';
import { actions, selectors } from 'modules/team';
import useQueryParams from 'hooks/useQueryParams';

const MemberTimesheetContainer = ({ id }) => {
  const dispatch = useDispatch();
  const { getParams } = useQueryParams();
  const { approverId } = getParams();
  const loading = useSelector(selectors.memberTimesheetLoading);
  const timesheet = useSelector(selectors.memberTimesheet);
  const timesheetRows = useSelector(selectors.memberTimesheetRows);
  const history = useSelector(selectors.memberTimesheetHistory);

  useEffect(() => {
    dispatch(actions.fetchMemberTimesheet(id));
    dispatch(actions.fetchMemberTimesheetHistory(id));
  }, [dispatch, id, approverId]);

  return <MemberTimesheet loading={loading} timesheet={timesheet} timesheetRows={timesheetRows} history={history} />;
};

export default MemberTimesheetContainer;
