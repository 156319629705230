import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { userLogOut } from 'modules/currentUser';

class Logout extends Component {
  componentDidMount() {
    this.props.userLogOut();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default connect(null, { userLogOut })(Logout);
