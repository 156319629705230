import React from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';

import { colors } from 'theme/theme';
import { Accessible } from 'components/Accessible';
import { aria } from 'constants/aria';

const StyledContextList = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: ${colors.white};
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  border-radius: 2px;
  max-width: 280px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transform-origin: top right;
  z-index: 11;

  .animate-enter & {
    transform: scale(0.3);
    opacity: 0.01;
  }

  .animate-enter.animate-enter-active & {
    transform: scale(1);
    opacity: 1;
    transition: transform 200ms, opacity 200ms;
  }

  .animate-leave & {
    opacity: 1;
  }

  .animate-leave.animate-leave-active & {
    opacity: 0.01;
    transition: opacity 150ms;
  }
`;

const ContextListItem = styled(Accessible)`
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  white-space: nowrap;
  cursor: pointer;
  transition: background 0.5s;

  ${props => props.type === 'alert' && `color: ${colors.redBrick}`};
  ${props => props.type === 'success' && `color: ${colors.greenSushi}`};

  .emoji {
    font-size: 20px;
  }

  &[disabled] {
    cursor: default;
    color: rgba(0, 0, 0, 0.3);
  }

  &:not([disabled]):hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

const ContextList = ({ options, onItemClick }) => (
  <StyledContextList>
    {options.length === 0 && (
      <ContextListItem disabled>
        <span className="emoji" role="img" aria-label={aria.various.emojiSob()} style={{ marginRight: '8px' }}>
          😭
        </span>{' '}
        No Options
      </ContextListItem>
    )}
    {options.map((option, i) => (
      <ContextListItem
        key={i}
        autoFocus={i === 0}
        type={option.type}
        disabled={option.disabled}
        onClick={option.disabled ? noop : onItemClick(option.action || noop)}
        aria-label={option.ariaLabel ?? option.label}
        aria-disabled={option.disabled}
        block
      >
        {option.label}
      </ContextListItem>
    ))}
  </StyledContextList>
);

export default ContextList;
