import React from 'react';
import styled from 'styled-components';
import Select from 'components/Select';

const Container = styled.div`
  display: flex;
  align-items: center;

  .label {
    margin-right: 16px;
    font-size: 14px;
    font-weight: 700;
  }
`;

const ApproverSelector = ({ loading, selected, relatedManagers, onApproverSelect }) => {
  const options = relatedManagers.map(user => ({
    label: user.name || `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));

  return (
    <Container>
      <div className="label">Viewing as</div>
      <Select value={selected} options={options} isLoading={loading} width={200} onChange={onApproverSelect} />
    </Container>
  );
};

export default ApproverSelector;
