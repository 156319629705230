import { Accessible } from 'components/Accessible';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'theme/theme';
import { ADDITIONAL_PROJECTS_NAME } from 'modules/projects';

const StyledProject = styled(Accessible)`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px 10px;
  min-height: 40px;

  ${props => props.collapse && `padding: 0`};
  ${props => !props.disabled && props.onClick && 'cursor: pointer;'};

  &:hover {
    ${props => !props.disabled && props.onClick && 'background: rgba(112, 112, 112, 0.05);'};
  }

  .Project__content {
    min-width: 0;
  }

  .Project__identifier {
    color: ${colors.blueDreams};
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.2;
    font-size: 10px;
  }

  .Project__title {
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.2;
  }

  .Project__pending {
    font-style: italic;
    color: ${colors.grayMortar};
  }

  .Project__subtitle {
    font-size: 12px;
    color: ${colors.grayDim};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.2;
  }
`;

const Project = ({
  ariaLabel,
  collapse,
  data,
  disabled,
  isAdditionalProject = false,
  isReadOnly = false,
  ...props
}) => {
  const isTextSelectable = isReadOnly || disabled;
  const elementType = isTextSelectable ? 'div' : 'button';

  const { clientName, projectId: projectKey, name: projectName, pending: projectPending, role: roleName } = data;

  let projectDescriptor = null;
  let projectIcon = null;

  let projectDetails = (
    <span>
      {clientName ?? 'Unknown Client'} &bull; {roleName ?? 'Unknown Role'}
    </span>
  );

  // Only PTO For now, Eventually might need to update this logic to handle other pending types
  if (projectPending) {
    projectDescriptor = '- Pending Approval';
    projectIcon = <i className="fa fa-clock-o" />;
  }

  if (isAdditionalProject) {
    projectDetails = ADDITIONAL_PROJECTS_NAME;
    if (roleName) projectDetails = roleName;
  }

  return (
    <StyledProject
      aria-label={ariaLabel}
      collapse={collapse}
      disabled={disabled}
      elementType={elementType}
      role="listitem"
      block
      {...props}
    >
      <div className="Project__content">
        {projectKey && <div className="Project__identifier">{projectKey}</div>}
        <div className={classNames('Project__title', { Project__pending: projectPending })} title={projectName}>
          {projectIcon} {projectName} {projectDescriptor}
        </div>
        <div className="Project__subtitle">{projectDetails}</div>
      </div>
    </StyledProject>
  );
};

Project.propTypes = {
  ariaLabel: PropTypes.string,
  collapse: PropTypes.bool,
  data: PropTypes.shape({
    clientName: PropTypes.string,
    name: PropTypes.string.isRequired,
    projectId: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  isAdditionalProject: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export { Project };
