import React from 'react';
import { colors } from 'theme/theme';
import Table from 'components/Table';
import LinkRow from 'components/LinkRow';
import Loader from 'components/Loader/Loader';
import { usePowerBIReports } from 'hooks/usePowerBIReports';

const PowerBIReportsList = ({ match }) => {
  const { reports, isLoading, error } = usePowerBIReports();

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Table>
      <tbody>
        {reports.map(({ id, name }) => {
          return (
            <LinkRow key={id} to={`${match.path}/${id}`} hover={colors.graySmoke}>
              <td>{name}</td>
              <td style={{ width: 45, fontSize: 24, color: colors.graySilver }}>
                <i className="fa fa-angle-right" />
              </td>
            </LinkRow>
          );
        })}
      </tbody>
    </Table>
  );
};

export default PowerBIReportsList;
