const global = {
  loginButtonO365: () => 'Log in to Moser Consulting Time Tracking with Office 365',
};

const timesheet = {
  dragStart: ({ dragPosition }) => `You have lifted the timesheet row at position ${dragPosition}`,
  dragEndWithDestination: ({ dragPositionStart, dragPositionEnd }) => {
    return `The timesheet row was moved from position ${dragPositionStart} to position ${dragPositionEnd}`;
  },
  dragEndWithoutDestination: ({ dragPositionStart }) => {
    return `The timesheet row has been returned to position ${dragPositionStart}`;
  },
  dragUpdateWithDestination: ({ dragPositionEnd }) => `You have moved the timesheet row to position ${dragPositionEnd}`,
  dragUpdateWithoutDestination: () => `You are not currently in a droppable area`,
  dragHelp: () => 'Timesheet row, sortable. Interact to start sorting.',
  entryComment: ({ entryName, entryDate }) => `Click to add comment for ${entryName} on ${entryDate}`,
  entryCommentClose: () => 'Close comment box',
  entryCommentInput: ({ entryName, entryDate }) => `Enter comment for ${entryName} on ${entryDate}`,
  entryInput: ({ entryName, entryDate }) => `Hours worked for ${entryName} on ${entryDate}`,
  headerDate: ({ date }) => String(date),
  newTimeCodeAlert: ({ projectId, projectName }) => `[${projectId}] ${projectName} is now available for use.`,
  periodSelectorCurrent: ({ dateRange }) => `Viewing Timesheet for ${dateRange}`,
  periodSelectorCurrentLink: () => 'Go to current timesheet period',
  periodSelectorPrevious: ({ dateRange }) => `Go to previous timesheet period for ${dateRange}`,
  periodSelectorNext: ({ dateRange }) => `Go to next timesheet period for ${dateRange}`,
  projectSelector: ({ rowName } = {}) => {
    if (!rowName) return 'Timesheet project selector, currently blank. Interact to select a project.';
    return `Timesheet project selector for ${rowName}. Interact to change.`;
  },
  rowAdd: ({ dateRange }) => `Add client row to timesheet for ${dateRange}`,
  rowDelete: ({ rowName, rowRole }) => `Delete row for ${rowName} - ${rowRole}`,
  saveButton: ({ dateRange }) => `Save timesheet for period ${dateRange}`,
  submitPending: () => 'Timesheet has already been submitted. Interact to unsubmit.',
  submitUnsubmitted: ({ totalHours } = {}) => {
    if (!totalHours) return `Interact to submit timesheet`;
    return `Interact to submit timesheet. Total timesheet hours is ${totalHours} hours.`;
  },
  teamStatusMessage: ({ employee, status }) => `Timesheet for ${employee} is in status ${status}.`,
};

const timeOff = {
  comment: () => 'Enter a reason for the time off request (maximum of 200 characters)',
  dateEndFull: () => 'Enter the end date for the full day or multi-day time off request',
  dateStartPartial: () => 'Enter the start date for the single day time off request',
  dateStartFull: () => 'Enter the start date for the full day or multi-day time off request',
  duration: () => 'Select whether the time off request is for a partial or full day',
  duplicateRequest: () => {
    return 'There is a PTO request that already contains the selected date(s). Please edit the existing request instead.';
  },
  editAlreadyApproved: () => {
    return `You are currently editing an approved request. Submitting this request will require reapproval from your team lead!`;
  },
  hours: ({ hoursInterval, hoursMin, hoursMax }) => {
    return `Enter the number of hours requested for this date. Must be between ${hoursMin} and ${hoursMax} rounded to the nearest ${hoursInterval} hours. For a full day request, please change time off type to full day instead of partial day.`;
  },
  hoursWorkday: () => {
    return 'Select the hours you work on a normal workday (note: this is for special circumstances. Majority of employees work the default selected 8 hours).';
  },
  includeWeekends: () => {
    return 'Select whether you work on weekends or not (note: this is for special circumstances. Majority of employees work the default Monday through Friday schedule).';
  },
  managerApproval: () => {
    return 'Check to confirm that my customer and/or engagement manager approves this request';
  },
  reason: () => 'Select the reason for this time off request',
  requestOptionsButton: ({ timeFrame, timeReason }) =>
    `Interact to edit or delete ${timeReason} Time Off Request for ${timeFrame}`,
  requestEditOption: ({ timeFrame, timeReason }) => `Edit ${timeReason} Time Off Request for ${timeFrame}`,
  requestDeleteOption: ({ timeFrame, timeReason }) => `Delete ${timeReason} Time Off Request for ${timeFrame}`,
  statHoursChange: ({ hoursCurrent, hoursDifference, hoursOriginal }) => {
    return `Your request changed from ${hoursOriginal} hours to ${hoursCurrent} hours, a difference of ${hoursDifference} hours.`;
  },
  statHoursRequested: ({ hoursCurrent }) => `You are currently requesting ${hoursCurrent} hours.`,
  statHoursOriginal: ({ hoursOriginal }) => `Your original request was for ${hoursOriginal} hours.`,
  statHoursYtd: ({ currentYear, hoursCurrent }) => {
    return `Your current ${currentYear} Year to Date Total is ${hoursCurrent} hours.`;
  },
  statHoursYtdOriginal: ({ currentYear, hoursOriginal }) => {
    return `Your original ${currentYear} Year to Date Total before this time off request was ${hoursOriginal} hours.`;
  },
  timeOffStatusMessage: ({ employeeName, status }) => `The status of ${employeeName} is ${status}`,
};

const various = {
  emojiCry: () => 'Sad Emoji',
  emojiSob: () => 'Sob Emoji',
};

export const aria = {
  global,
  timeOff,
  timesheet,
  various,
};
