import { connect } from 'react-redux';
import styled from 'styled-components';

import { StyledButton } from 'components/SubmitButton';
import { actions as modalActions } from 'modules/modal';
import { REJECT_TIMESHEET_MODAL } from 'modules/timesheet';
import { colors } from 'theme/theme';

const RejectButton = styled(StyledButton)`
  color: ${colors.white};

  &,
  :active {
    background-color: ${colors.redBrick};
  }

  :hover {
    color: ${colors.white};
    background-color: ${colors.redPersian};
  }
`;

const RejectButtonContainer = ({ showModal, loading }) => (
  <RejectButton
    type="button"
    onClick={() => {
      showModal('RejectTimesheet', { id: REJECT_TIMESHEET_MODAL, type: 'alert' });
    }}
    title="Reject Timesheet"
    disabled={loading}
  >
    <i className="fa fa-ban" />
    Reject Timesheet
  </RejectButton>
);

const mapDispatch = { showModal: modalActions.showModal };

export default connect(null, mapDispatch)(RejectButtonContainer);
