import React from 'react';
import styled from 'styled-components';

import { colors } from '../../theme/theme';

const StyledCell = styled.div`
  position: relative;
  padding-top: 100%;
  background: #fff;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;

  ${props => props.isWeekend && `background: #f7f7f7`};

  ${props => !props.isCurrentMonth && `color: #bababa`};
  ${props => props.isHighlighted && !props.isCurrentMonth && `color: ${colors.grayNight}`};

  ${props => props.isHighlighted && `background: ${colors.yellowDandelion}`};
  ${props => props.isHighlighted && `border-color: ${colors.yellowSunflower}`};

  ${props => props.isHighlighted && props.isWeekend && `background: ${colors.yellowSunflower}`};
  ${props => props.isHighlighted && props.isWeekend && `border-color: ${colors.yellowDuck}`};

  @media (min-width: 800px) {
    &:nth-child(-n + 7) {
      border-top: none;
    }

    &:nth-child(7n) {
      border-right: none;
    }
  }

  .Calendar__today {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 3px solid ${colors.blueDodger};
  }

  .Calendar__details {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    left: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    z-index: 1;
    pointer-events: none;

    .Calendar__day {
      display: none;
      align-self: flex-end;
    }

    .Calendar__number {
      display: inline-flex;
      ${props => props.isToday && `color: ${colors.blueDenim}`};
    }
  }

  .Calendar__content {
    position: relative;
    z-index: 1;
    ${props => !props.isCurrentMonth && `opacity: 0.9`};
  }
`;

const CalendarCell = ({ children, ...props }) => {
  return (
    <StyledCell {...props}>
      {props.isToday && <div className="Calendar__today"></div>}
      {children}
    </StyledCell>
  );
};

export default CalendarCell;
