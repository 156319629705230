import qs from 'qs';

const defaultHeaders = {
  Accept: 'application/json',
  'X-Key-Inflection': 'camel',
  'X-Requested-With': 'XMLHttpRequest',
  'Access-Control-Allow-Headers': '*',
};

const urlWithParams = (url, params) => {
  if (!params || Object.keys(params).length === 0) return url;
  return `${url}${url.indexOf('?') > -1 ? '&' : '?'}${qs.stringify(params)}`;
};

export default async function apiFetch(url, options = {}) {
  if (typeof url !== 'string') throw new Error('expected url to be a string');

  const { method = 'GET', body, params } = options;

  const init = {
    method: method.toUpperCase(),
    headers: new Headers(defaultHeaders),
    cache: 'default',
  };

  if (body) {
    init.headers.append('Content-Type', 'application/json');
    init.body = JSON.stringify(body);
  }

  const request = new Request(urlWithParams(url, params), init);
  const res = await fetch(request, { credentials: 'include' });

  if (!res.ok) throw new Error(res.statusText.length > 0 ? res.statusText : 'Request failed!');
  if (res.status === 204) return res;

  return await res.json();
}
