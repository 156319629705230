import React from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';

import { colors } from 'theme/theme';

const StyledCheckbox = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .Checkbox__label {
    margin-left: 1em;
  }

  .Checkbox__box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 18px;
    width: 18px;
    min-height: 18px;
    height: 18px;
    border: 1px solid ${colors.black};
    border-radius: 3px;
    transition: background 300ms, border-color 300ms, box-shadow 300ms;
  }

  .Checkbox__check {
    width: 10px;
    height: 6px;
    border-left: 2px solid ${colors.black};
    border-bottom: 2px solid ${colors.black};
    transform: translateY(-1px) rotate(-45deg);
    opacity: 0;
    transition: opacity 300ms, border-color 300ms;
  }

  &:hover .Checkbox__check,
  &:focus-within .Checkbox__check {
    opacity: 0.3;
  }

  &:focus-within {
    box-shadow: 0 0 0 2px ${colors.orangeGambage};
  }

  input:checked + .Checkbox__box {
    background: ${colors.blueFlower};
    border-color: ${colors.blueFlower};
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  input:checked + .Checkbox__box .Checkbox__check {
    opacity: 1;
    border-color: ${colors.white};
  }

  input {
    position: absolute;
    transform: scale(0);
  }
`;

const Checkbox = ({ checked, label, disabled, defaultChecked, className, style, onClick, onChange, ...rest }) => (
  <StyledCheckbox className={className} style={style} onClick={disabled ? noop : onClick}>
    <input
      tabIndex="0"
      type="checkbox"
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={disabled ? noop : onChange}
      {...rest}
    />
    <div className="Checkbox__box">
      <div className="Checkbox__check" />
    </div>
    {!!label && <div className="Checkbox__label">{label}</div>}
  </StyledCheckbox>
);

export default Checkbox;
