import omit from 'lodash/omit';
import { combineActions, createActions, handleActions } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { actions as toastActions } from 'modules/toast';
import * as api from 'utils/api';

const DEFAULT_REPORT = { ranReport: false, results: [] };

// default state
const defaultState = {
  loading: false,
  reports: {},
};

// actions
export const actions = createActions(
  {
    FETCH_ACCOUNTING_REPORT: ({
      billable,
      clientId,
      departmentId,
      employeeId,
      endDate,
      engagementManagerId,
      projectId,
      startDate,
    }) => ({
      type: 'accounting',
      billable,
      clientId,
      departmentId,
      employeeId,
      endDate,
      engagementManagerId,
      projectId,
      startDate,
    }),
    FETCH_ALLOCATION_REPORT: (startDate, endDate) => ({
      type: 'allocation',
      startDate,
      endDate,
    }),
    FETCH_GENERAL_TIME_REPORT: (startDate, endDate, projectId) => ({
      type: 'generalTime',
      startDate,
      endDate,
      projectId,
    }),
    FETCH_PROJECT_TIME_REPORT: (
      startDate,
      endDate,
      departmentId,
      clientId,
      employeeId,
      engagementManagerId,
      projectId
    ) => ({
      type: 'projectTime',
      startDate,
      endDate,
      clientId,
      departmentId,
      employeeId,
      engagementManagerId,
      projectId,
    }),
    FETCH_NOT_SUBMITTED_REPORT: startDate => ({
      type: 'notSubmitted',
      startDate,
    }),
  },
  'FETCH_REPORT_SUCCESS',
  'FETCH_REPORT_FAILURE'
);

// selectors
const selectRecordType = (reports = {}, type) => {
  if (reports[type]) {
    return { ...DEFAULT_REPORT, ...reports[type] };
  }

  return { ...DEFAULT_REPORT };
};

export const selectors = {
  loading: state => state.reports.loading,
  accounting: state => selectRecordType(state.reports.reports, 'accounting'),
  allocation: state => selectRecordType(state.reports.reports, 'allocation'),
  generalTime: state => selectRecordType(state.reports.reports, 'generalTime'),
  projectTime: state => selectRecordType(state.reports.reports, 'projectTime'),
  notSubmitted: state => selectRecordType(state.reports.reports, 'notSubmitted'),
};

// Helpers
const toast = (type, title, message) =>
  toastActions.createToast(type, {
    title,
    message,
  });

// sagas
function* fetchGeneralTimeReportSaga({ payload: { type, ...rest } }) {
  try {
    const { timesheetEntries } = yield call(api.fetchGeneralTimeReport, rest);

    if (timesheetEntries) {
      yield put(actions.fetchReportSuccess({ type, results: timesheetEntries }));
    }
  } catch (e) {
    yield put(actions.fetchReportFailure(e));
    yield put(
      toast('alert', 'Report Failed', 'Failed to generate a report. Please retry shortly or refresh the page.')
    );
  }
}

function* fetchProjectTimeReportSaga({ payload: { type, ...rest } }) {
  try {
    const { timesheetEntries } = yield call(api.fetchProjectTimeReport, rest);

    if (timesheetEntries) {
      yield put(actions.fetchReportSuccess({ type, results: timesheetEntries }));
    }
  } catch (e) {
    yield put(actions.fetchReportFailure(e));
    yield put(
      toast('alert', 'Report Failed', 'Failed to generate a report. Please retry shortly or refresh the page.')
    );
  }
}

function* fetchAllocationReportSaga({ payload: { type, ...rest } }) {
  try {
    const { results } = yield call(api.fetchAllocationReport, rest);

    if (results) {
      yield put(actions.fetchReportSuccess({ type, results }));
    }
  } catch (e) {
    yield put(actions.fetchReportFailure(e));
    yield put(
      toast('alert', 'Report Failed', 'Failed to generate a report. Please retry shortly or refresh the page.')
    );
  }
}

function* fetchAccountingReportSaga({ payload: { type, ...rest } }) {
  try {
    const { timesheetEntries } = yield call(api.fetchAccountingReport, rest);

    if (timesheetEntries) {
      yield put(actions.fetchReportSuccess({ type, results: timesheetEntries }));
    }
  } catch (e) {
    yield put(actions.fetchReportFailure(e));
    yield put(
      toast('alert', 'Report Failed', 'Failed to generate a report. Please retry shortly or refresh the page.')
    );
  }
}

function* fetchNotSubmittedReport({ payload: { type, ...rest } }) {
  try {
    const { results } = yield call(api.fetchNotSubmittedReport, rest);

    if (results) {
      yield put(actions.fetchReportSuccess({ type, results }));
    }
  } catch (e) {
    yield put(actions.fetchReportFailure(e));
    yield put(
      toast('alert', 'Report Failed', 'Failed to generate a report. Please retry shortly or refresh the page.')
    );
  }
}

export function* reportSaga() {
  yield all([
    takeLatest(actions.fetchAllocationReport, fetchAllocationReportSaga),
    takeLatest(actions.fetchAccountingReport, fetchAccountingReportSaga),
    takeLatest(actions.fetchGeneralTimeReport, fetchGeneralTimeReportSaga),
    takeLatest(actions.fetchProjectTimeReport, fetchProjectTimeReportSaga),
    takeLatest(actions.fetchNotSubmittedReport, fetchNotSubmittedReport),
  ]);
}

export default handleActions(
  {
    '@@router/LOCATION_CHANGE'(state) {
      return { ...state, reports: {} };
    },

    [combineActions(
      actions.fetchAccountingReport,
      actions.fetchAllocationReport,
      actions.fetchGeneralTimeReport,
      actions.fetchProjectTimeReport,
      actions.fetchNotSubmittedReport
    )](state, { payload: { type } }) {
      return { ...state, loading: true, reports: omit(state.reports, type) };
    },

    [actions.fetchReportSuccess](state, { payload: { type, results } }) {
      return {
        ...state,
        loading: false,
        reports: { ...state.reports, [type]: { ranReport: true, results } },
      };
    },

    [actions.fetchReportFailure](state, { payload: { type, results } }) {
      return {
        ...state,
        loading: false,
      };
    },
  },
  defaultState
);
