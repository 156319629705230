import React from 'react';

import ModalWrapper from 'components/Modals/ModalWrapper';
import LabeledInput from 'components/LabeledInput';
import TextArea from 'components/TextArea';
import changeMethod from 'utils/changeMethod';

const TimesheetRejectModal = props => {
  const { onSubmit, onUpdate, note } = props;

  return (
    <ModalWrapper
      {...props}
      closeText="Yes, Reject"
      cancelText="No, Cancel"
      valid={true}
      title={props.title || 'Confirm Rejection'}
      onClose={onSubmit}
      locked
      width={400}
    >
      <LabeledInput label={<span>Notes: </span>}>
        <TextArea name="note" value={note} onChange={changeMethod(onUpdate)} />
      </LabeledInput>
    </ModalWrapper>
  );
};

export default TimesheetRejectModal;
