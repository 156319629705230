import omit from 'lodash/omit';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions } from 'modules/team';

import TimesheetRejectModal from './TimesheetRejectModal';

class TimesheetRejectModalContainer extends PureComponent {
  state = {
    note: null,
  };

  submit = () => {
    const { rejectTimesheet } = this.props;
    rejectTimesheet({
      note: this.state.note,
    });
  };

  updateInput = (key, value) => {
    this.setState(state => ({
      ...state,
      [key]: value,
    }));
  };

  getChildProps = () => ({
    ...omit(this.props, ['submitRequest']),
    ...this.state,
    onSubmit: this.submit,
    onUpdate: this.updateInput,
  });

  render() {
    return <TimesheetRejectModal {...this.getChildProps()} />;
  }
}

const mapStateToProps = state => ({
  note: state.note,
});

export default connect(mapStateToProps, {
  rejectTimesheet: actions.teamRejectTimesheet,
})(TimesheetRejectModalContainer);
