export const font = '"Lato", sans-serif';

export const TRANSITION_LIGHTNING = '200ms';
export const TRANSITION_FAST = '300ms';
export const TRANSITION_MEDIUM = '500ms';
export const TRANSITION_SLOW = '700ms';

export const colors = {
  black: '#000000',
  blueFlower: '#3c94ef',
  blueDodger: '#1194f6',
  blueDenim: '#0d74c2',
  blueDreams: '#334A62',
  grayHoki: '#5F7583',
  grayNight: '#333',
  grayCharcoal: '#424242',
  grayMortar: '#555',
  grayDim: '#666',
  grayNeutral: '#808080',
  grayNobel: '#999',
  grayDark: '#aaa',
  graySilver: '#bbb',
  grayLight: '#d4d4d4',
  grayHint: '#dedede',
  grayTint: '#ebebeb',
  graySmoke: '#f4f4f4',
  greenMadang: '#c5e1a5',
  greenNorway: '#98ae7f',
  greenSushi: '#80a02d',
  greenOlive: '#417505',
  yellowDandelion: '#ffe082',
  yellowSunflower: '#ffd860',
  yellowDuck: '#ffcf3e',
  yellowPutty: '#ccb368',
  orangeGambage: '#f6b100',
  orangeButtercup: '#d7911d',
  redOrchid: '#e19393',
  redTurkish: '#a56c6c',
  redBrick: '#be272d',
  redPersian: '#d13838',
  white: '#ffffff',
};

export const hasShadow = {
  'box-shadow': '0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1)',
};

export const transitions = {
  inputBorderColor: ['border-color', '300ms', 'ease'],
};

export const colorPlaceholder = (color, opacity = 1) => ({
  '&::-webkit-input-placeholder': { color, opacity },
  '&::-moz-placeholder': { color, opacity },
  '&:-ms-input-placeholder': { color, opacity },
  '&:-moz-placeholder': { color, opacity },
});

export const formGroup = {
  'margin-bottom': 20,
};

export const formLabel = {
  display: 'inline-block',
  'margin-bottom': 10,
  'font-weight': 'bold',
};

export const formControls = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  'margin-top': 20,
};
