import React, { PureComponent } from 'react';
import omit from 'lodash/omit';

import { checkValidity } from 'utils/validation';

import PTOBulkActionModal from './PTOBulkActionModal';

const MAX_COMMENT_LENGTH = 200;

class PTOBulkActionModalContainer extends PureComponent {
  state = {
    comment: '',
  };

  validations = {
    comment: [`minlength: 5`, `maxlength:${MAX_COMMENT_LENGTH}`],
  };

  updateInput = (key, value) => {
    let resets = {};

    if (key === 'timeframeType') {
      resets = { endDate: undefined, hours: undefined };
    }

    this.setState(() => {
      return {
        ...resets,
        [key]: value,
      };
    });
  };

  getValidations = (...keys) => {
    return keys.map(key => ({
      key,
      validate: this.validations[key],
    }));
  };

  validate = props => {
    const { actionType } = this.props;
    const validations = actionType === 'reject' ? this.getValidations('comment') : [];
    return checkValidity(props, validations);
  };

  submit = () => {
    const { comment } = this.state;
    const { onClose } = this.props;
    onClose(comment);
  };

  getChildProps = () => ({
    ...omit(this.props, ['onClose']),
    ...this.state,
    MAX_COMMENT_LENGTH,
    onSubmit: this.submit,
    onUpdate: this.updateInput,
    validation: this.validate,
  });

  render() {
    return <PTOBulkActionModal {...this.getChildProps()} />;
  }
}

export default PTOBulkActionModalContainer;
