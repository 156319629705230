import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from 'theme/theme';

const Fab = ({ size = '1em', href, children, ...rest }) => {
  const props = { ...rest, size };
  const isExternal = !!href;

  if (isExternal) {
    return (
      <FabAnchor href={href} {...props}>
        {children}
      </FabAnchor>
    );
  }

  return <FabLink {...props}>{children}</FabLink>;
};

const FabBase = css`
  --fab-size: calc(var(--size) * 3.5);
  --shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);

  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 5;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: var(--size);
  background-color: ${colors.white};
  color: ${colors.black};
  min-width: var(--fab-size);
  height: var(--fab-size);
  font-size: var(--size);
  text-align: center;
  text-decoration: none;
  border-radius: var(--fab-size);
  box-shadow: var(--shadow);
  transition: 100ms ease-in-out;

  i {
    font-size: calc(var(--size) * 2);
  }

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1);
    box-shadow: inset var(--shadow);
  }
`;

const FabAnchor = styled.a`
  --size: ${p => p.size};
  ${FabBase}
`;

const FabLink = styled(Link)`
  --size: ${p => p.size};
  ${FabBase}
`;

export default Fab;
