import React from 'react';

import InputContainer from 'components/InputContainer';

const SimpleSearch = ({ label = '', onChange, ...props }) => (
  <InputContainer>
    <h3>{label}</h3>
    <input type="text" onChange={e => onChange(e.target.value)} {...props} />
  </InputContainer>
);

export default SimpleSearch;
