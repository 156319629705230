import React from 'react';
import Fab from 'components/Fab';

const HelpFabLink = () => {
  return (
    <Fab to="/help">
      <i className="fa fa-question" />
    </Fab>
  );
};

export default HelpFabLink;
