import { Accessible } from 'components/Accessible';
import { sortedUniqBy } from 'lodash';
import { ADDITIONAL_PROJECTS_ID } from 'modules/projects';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { sortUnique } from 'utils/array';
import { colors } from 'theme/theme';
import { Pane } from './Pane';

const StyledSelectorPane = styled(Pane)`
  .Pane__item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
  }

  .Pane__table {
    border-collapse: collapse;

    thead tr {
      background-color: ${colors.graySmoke};
    }

    th {
      padding: 10px 32px 10px 10px;
    }

    th {
      border-bottom: 1px solid ${colors.grayLight};
      min-width: 225px;
      text-align: left;
    }

    td {
      vertical-align: top;
      min-width: 225px;
      border-left: 1px solid ${colors.graySmoke};
      white-space: nowrap;

      &:first-child {
        border-left: none;
      }
    }
  }
`;

const FlexboxColumn = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  min-width: 0;
`;

const getClients = options => {
  // Using sortedUniqBy instead of sortUnique because options is already pre-sorted by clients
  // ahead of time (This is to keep "General" as the first option which may not always happen
  // alphabetically).
  return sortedUniqBy(options, client => client.clientName);
};

const getProjectsByClient = (selected, options) => {
  if (!selected.clientId) return [];
  const projectOptions = options.filter(option => option.clientId === selected.clientId);
  return sortUnique(projectOptions, project => project.projectName);
};

const getRolesByProject = (selected, options) => {
  if (!selected.clientId || !selected.projectId) return [];
  const roleOptions = options.filter(option => {
    return option.clientId === selected.clientId && option.projectId === selected.projectId && option.roleId !== null;
  });
  return sortUnique(roleOptions, role => role.roleName);
};

const parseOptions = (selected, options) => {
  const clients = getClients(options);
  const projects = getProjectsByClient(selected, options);
  const roles = getRolesByProject(selected, options);
  return { clients, projects, roles };
};

const SelectorPane = ({ options, onChange, onClose }) => {
  const [selected, setSelected] = useState({ clientId: null, projectId: null, roleId: null });

  const { clients, projects, roles } = useMemo(() => {
    return parseOptions(selected, options);
  }, [options, selected]);

  const isClientsVisible = clients.length > 0;
  const isProjectsVisible = projects.length > 0;
  const isRolesVisible = roles.length > 0;

  const onChangeSelection = useCallback(
    (selection, isAdditionalProject = false) => {
      onChange(selection, isAdditionalProject);
      onClose();
    },
    [onClose, onChange]
  );

  const selectClient = useCallback(clientId => {
    setSelected({ clientId, projectId: null, roleId: null });
  }, []);

  const selectProject = useCallback(
    projectId => {
      const nextSelected = { clientId: selected.clientId, projectId, roleId: null };
      setSelected(nextSelected);

      // Additional Project doesn't have a role. Run onChange directly.
      if (nextSelected.clientId === ADDITIONAL_PROJECTS_ID) {
        onChangeSelection(nextSelected, true);
      }
    },
    [selected, onChangeSelection]
  );

  const selectRole = useCallback(
    roleId => {
      const nextSelected = { clientId: selected.clientId, projectId: selected.projectId, roleId };
      onChangeSelection(nextSelected);
      setSelected(nextSelected);
    },
    [selected, onChangeSelection]
  );

  return (
    <StyledSelectorPane onClose={onClose}>
      <table cellSpacing={0} className="Pane__table">
        <thead>
          <tr>
            {isClientsVisible && <th>Client</th>}
            {isProjectsVisible && <th>Project</th>}
            {isRolesVisible && <th>Role</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            {isClientsVisible && (
              <td>
                <FlexboxColumn>
                  {clients.map((client, i) => (
                    <Accessible
                      block
                      className={`Pane__item ${selected.clientId === client.clientId ? 'selected' : ''}`}
                      role="listitem"
                      aria-label={`Select ${client.clientName} for client`}
                      key={i}
                      onClick={() => selectClient(client.clientId)}
                    >
                      {client.clientName}
                    </Accessible>
                  ))}
                </FlexboxColumn>
              </td>
            )}

            {isProjectsVisible && (
              <td>
                <FlexboxColumn>
                  {projects.map((project, i) => (
                    <Accessible
                      autoFocus={i === 0}
                      block
                      className={`Pane__item ${selected.projectId === project.projectId ? 'selected' : ''}`}
                      aria-label={`Select ${project.projectName} for client project`}
                      role="listitem"
                      key={i}
                      onClick={() => selectProject(project.projectId)}
                    >
                      {project.projectName}
                    </Accessible>
                  ))}
                </FlexboxColumn>
              </td>
            )}

            {isRolesVisible && (
              <td>
                <FlexboxColumn>
                  {roles.map((role, i) => (
                    <Accessible
                      autoFocus={i === 0}
                      block
                      className={`Pane__item ${selected.roleId === role.roleId ? 'selected' : ''}`}
                      aria-label={`Select ${role.roleName} for your role on the project`}
                      role="listitem"
                      key={i}
                      onClick={() => selectRole(role.roleId)}
                    >
                      {role.roleName}
                    </Accessible>
                  ))}
                </FlexboxColumn>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </StyledSelectorPane>
  );
};

export default SelectorPane;
