import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions, selectors } from 'modules/pto';

import PTOTable from './PTOTable';
import withPto from './withPto';

class PastPTOContainer extends PureComponent {
  render() {
    const { pto, loading, onEdit, onDelete } = this.props;
    return <PTOTable items={pto} loading={loading} onEdit={onEdit} onDelete={onDelete} />;
  }
}

const mapState = state => ({
  pto: selectors.past(state),
  loading: selectors.loading(state),
});

const connected = connect(mapState)(PastPTOContainer);

export default withPto({
  fetch: actions.fetchPastPto,
})(connected);
