import classnames from 'classnames';
import RetinaImage from 'react-retina-image';
import { NavLink } from 'react-router-dom';

import { Container } from 'components/layout';
import { POWERBI_REPORTS_PATH, POWERBI_REPORTS_TITLE } from 'components/PowerBIReports';

import StyledHeader, { HeaderContainer } from './Header.styled';

const Menu = ({ menuItems, className, linkClassName, linkActiveClassName }) => (
  <div className={className}>
    {menuItems.map(({ label, path, disabled, hidden }) => {
      if (hidden) return null;

      return !disabled ? (
        <NavLink key={label} to={path} className={linkClassName} activeClassName={linkActiveClassName}>
          {label}
        </NavLink>
      ) : (
        <span key={label} className={classnames(linkClassName, 'disabled')}>
          {label}
        </span>
      );
    })}
  </div>
);

const Header = ({ classes, isAdmin, isContractor, isManager, hasAnyRole }) => {
  const mainMenuItems = [
    { label: 'Timesheets', path: '/timesheets' },
    { label: 'Time Off', path: '/pto', hidden: isContractor },
    { label: 'Team', path: '/team', hidden: !isManager },
    { label: 'Reports', path: '/reports', hidden: !hasAnyRole },
    { label: POWERBI_REPORTS_TITLE, path: POWERBI_REPORTS_PATH },
  ];

  const userMenuItems = [
    { label: 'Admin', path: '/admin', hidden: !isAdmin },
    { label: 'Logout', path: '/logout' },
  ];

  return (
    <HeaderContainer>
      <StyledHeader>
        <Container>
          <div className="Header__details">
            <NavLink to="/timesheets">
              <RetinaImage src="/images/logo.png" className="Header__logo" />
            </NavLink>
            <Menu
              className="Header__menu"
              linkClassName="Header__menu-item"
              linkActiveClassName="Header__menu-item--active"
              menuItems={mainMenuItems}
            />
            <Menu
              className="Header__menu Header__menu-actions"
              linkClassName="Header__menu-item"
              linkActiveClassName="Header__menu-item--active"
              menuItems={userMenuItems}
            />
          </div>
        </Container>
      </StyledHeader>
    </HeaderContainer>
  );
};

export default Header;
