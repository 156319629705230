import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions, selectors } from 'modules/pto';

import PendingPTO from './PendingPTO';

class PendingPTOContainer extends PureComponent {
  componentDidMount() {
    this.props.fetchTeamPto({ status: 'pending' });
  }

  render() {
    const { loading, pto } = this.props;
    return <PendingPTO loading={loading} pto={pto} />;
  }
}

const mapStateToProps = state => ({
  pto: selectors.teamPto(state),
  loading: selectors.loading(state),
});

export default connect(mapStateToProps, { fetchTeamPto: actions.adminTeamPto })(PendingPTOContainer);
