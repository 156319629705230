import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'components/Table';
import Loader from 'components/Loader';
import StatusBadge from 'components/StatusBadge';
import { actions, selectors } from 'modules/pto';
import { colors } from 'theme/theme';
import useQueryParams from 'hooks/useQueryParams';
import LinkRow from 'components/LinkRow';

const PTOAdminTableOverview = () => {
  const dispatch = useDispatch();
  const { getParams, search } = useQueryParams();
  const { approverId: managerId } = getParams();

  useEffect(() => {
    dispatch(actions.adminTeamPtoOverview({ managerId }));
  }, [dispatch, managerId]);

  const loading = useSelector(selectors.loading);
  const team = useSelector(selectors.teamPtoOverview);
  const year = useSelector(selectors.teamPtoOverviewYear);
  const hasTeam = team.length > 0;

  const Row = ({ employee }) => {
    if (!employee) return null;

    const fullName = `${employee.lastName}, ${employee.firstName}`;
    const totalApproved = `${employee.totalApproved} Hours`;
    const takenYtd = `${employee.takenYtd} Hours`;

    return (
      <LinkRow to={`pto/${employee.spudUserId}/${year}${search}`}>
        <td>{fullName}</td>
        <td>{totalApproved}</td>
        <td>{takenYtd}</td>
        <td style={{ textAlign: 'center' }}>
          {employee.twoConsecutive ? <IconYes className="fa fa-check" /> : <IconNo className="fa fa-ban" />}
        </td>
        <td style={{ textAlign: 'right' }}>
          {employee.pendingRequest && <StatusBadge status="pending">Pending Request</StatusBadge>}
        </td>
      </LinkRow>
    );
  };

  return (
    <OverviewTable loading={loading}>
      <thead>
        <tr>
          <th>Employee</th>
          <th>{year} Time Off Approved</th>
          <th>Year to Date</th>
          <th style={{ textAlign: 'center' }}>2 Consecutive Days</th>
          <th style={{ textAlign: 'right' }}>{hasTeam && loading && <TableLoader collapse dynamic />}</th>
        </tr>
      </thead>
      <tbody>
        {hasTeam ? (
          team.map(employee => <Row key={employee.spudUserId} employee={employee} />)
        ) : (
          <tr>
            <td colSpan="5">{loading ? <Loader compact /> : <>No team members found.</>}</td>
          </tr>
        )}
      </tbody>
    </OverviewTable>
  );
};

const OverviewTable = styled(({ loading, ...rest }) => <Table {...rest} />)`
  tbody {
    transition: 200ms ease-in-out;
    ${p => p.loading && 'opacity: 0.5;'}
  }
`;

const TableLoader = styled(Loader)`
  display: inline-block;
  font-size: 24px;
  height: 1em;
`;

const IconYes = styled.i`
  color: ${colors.greenSushi};
`;

const IconNo = styled.i`
  color: ${colors.grayNobel};
`;

export default PTOAdminTableOverview;
