import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions as modalActions } from 'modules/modal';
import { actions, PTO_REQUEST_MODAL } from 'modules/pto';
import { renderTimeFrame } from 'utils/common';
import { PTO_REASONS } from 'utils/reference';

const withPto = ({ fetch }) => Component => {
  class Connected extends PureComponent {
    editPto = originalRequest => {
      const { showModal } = this.props;
      showModal('PTORequest', {
        id: PTO_REQUEST_MODAL,
        updating: true,
        originalRequest,
      });
    };

    deletePto = (id, details) => {
      const { showModal, deleteRequest } = this.props;

      showModal('confirm', {
        type: 'alert',
        title: 'Delete Request',
        message: (
          <div>
            <p>Are you sure you want to delete the following request?</p>
            <ul style={{ paddingLeft: '0', listStyleType: 'none' }}>
              <li>
                <strong>Type:</strong> {PTO_REASONS[details.reason]}
              </li>
              <li>
                <strong>Date(s):</strong> {renderTimeFrame(details.timeframeType, details.startDate, details.endDate)}
              </li>
            </ul>
          </div>
        ),
        closeText: `Yes, Delete`,
        cancelText: `No, Nevermind`,
        onClose: () => deleteRequest(id),
      });
    };

    componentDidMount() {
      const { year } = this.props;
      this.props.fetch({ year });
    }

    render() {
      return <Component onEdit={this.editPto} onDelete={this.deletePto} />;
    }
  }

  const mapStateToProps = state => ({
    pto: state.pto.upcoming,
    loading: state.pto.loading,
    year: state.pto.year,
  });

  return connect(mapStateToProps, {
    fetch,
    deleteRequest: actions.deleteRequest,
    showModal: modalActions.showModal,
  })(Connected);
};

export default withPto;
