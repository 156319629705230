import dayjs from 'lib/dayjs';

export const DATE_FORMATS = { display: 'DISPLAY', readable: 'READABLE' };
export const DISPLAY_DATE_FORMAT = 'DD MMM';
export const DISPLAY_DATE_WITH_YEAR_FORMAT = 'DD MMM YYYY';
export const READABLE_DATE_FORMAT = 'MMMM Do';
export const READABLE_DATE_WITH_YEAR_FORMAT = 'MMMM Do, YYYY';

export const getDateString = (dateInput, dateFormat = DATE_FORMATS.display) => {
  const dateDay = dayjs(dateInput);
  const isSameYear = dayjs().year() === dateDay.year();

  let format = isSameYear ? DISPLAY_DATE_FORMAT : DISPLAY_DATE_WITH_YEAR_FORMAT;
  if (dateFormat === DATE_FORMATS.readable) {
    format = isSameYear ? READABLE_DATE_FORMAT : READABLE_DATE_WITH_YEAR_FORMAT;
  }

  return dateDay.format(format);
};

export const getDateRangeString = (dateInput, dateFormat = DATE_FORMATS.display) => {
  if (!dateInput) return '';

  const { startDate, endDate } = dateInput;

  const startDay = dayjs(startDate);
  const endDay = dayjs(endDate);

  const yearDifferent = startDay.year() !== endDay.year();
  const monthDifferent = startDay.month() !== endDay.month();

  let startFormat = READABLE_DATE_FORMAT;
  let endFormat = READABLE_DATE_WITH_YEAR_FORMAT;
  let formatSeparator = 'to';

  if (dateFormat === DATE_FORMATS.display) {
    startFormat = 'DD';
    if (yearDifferent) {
      startFormat += ' MMM YYYY';
    } else if (monthDifferent) {
      startFormat += ' MMM';
    }

    endFormat = DISPLAY_DATE_WITH_YEAR_FORMAT;
    formatSeparator = '-';
  }

  const formattedStart = startDay.format(startFormat);
  const formattedEnd = endDay.format(endFormat);

  return `${formattedStart} ${formatSeparator} ${formattedEnd}`;
};
