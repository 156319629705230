import dayjs from 'lib/dayjs';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import AccountingReport from 'components/Reports/AccountingReport';
import { DATE_FORMAT } from 'lib/constants';
import { actions, selectors } from 'modules/reports';

const currentDate = new Date();

class AccountingReportContainer extends PureComponent {
  state = {
    startDate: dayjs(currentDate),
    endDate: dayjs(currentDate),
    billable: '',
    departmentId: '',
    clientId: '',
    employeeId: '',
    projectId: '',
    filteredProjects: [],
  };

  selectStartDate = date => {
    this.setState(() => ({ startDate: date }));
  };

  selectEndDate = date => this.setState(() => ({ endDate: date }));

  setDepartmentId = id => this.setState(() => ({ departmentId: String(id) }));

  setBillable = billable => {
    this.setState(() => ({ billable }));
  };

  setClientId = id => {
    this.setState(() => ({ clientId: String(id), projectId: '' }));
  };

  setProjectId = id => {
    this.setState(() => ({ projectId: String(id) }));
  };

  setEmployeeId = id => {
    this.setState(() => ({ employeeId: String(id) }));
  };

  setEngagementManagerId = id => {
    this.setState(() => ({ engagementManagerId: String(id) }));
  };

  setFilteredProjects = (filteredProjects, clearEmployee) => {
    this.setState(state => ({ filteredProjects, employeeId: clearEmployee ? '' : state.employeeId }));
  };

  runReport = () => {
    const {
      startDate,
      endDate,
      billable,
      departmentId,
      clientId,
      employeeId,
      engagementManagerId,
      projectId,
    } = this.state;
    this.props.fetchAccountingReport({
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
      billable,
      departmentId,
      clientId,
      employeeId,
      engagementManagerId,
      projectId,
    });
  };

  render() {
    const { loading } = this.props;

    return (
      <AccountingReport
        {...this.props}
        {...this.state}
        loading={loading}
        selectStartDate={this.selectStartDate}
        selectEndDate={this.selectEndDate}
        setDepartmentId={this.setDepartmentId}
        setBillable={this.setBillable}
        setClientId={this.setClientId}
        setEmployeeId={this.setEmployeeId}
        setEngagementManagerId={this.setEngagementManagerId}
        setProjectId={this.setProjectId}
        setFilteredProjects={this.setFilteredProjects}
        filteredProjects={this.state.filteredProjects}
        runReport={this.runReport}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: selectors.loading(state),
    report: selectors.accounting(state),
  };
};

export default connect(mapStateToProps, { fetchAccountingReport: actions.fetchAccountingReport })(
  AccountingReportContainer
);
