import React from 'react';
import { useSelector } from 'react-redux';

import ApproverSelector from 'components/Team/ApproverSelector';
import { selectors } from 'modules/approvers';
import { currentUser as currentUserSelector } from 'modules/currentUser';
import useQueryParams from 'hooks/useQueryParams';

const ApproverSelectorContainer = props => {
  const { getParams, setParams } = useQueryParams();
  const { approverId } = getParams();
  const loading = useSelector(selectors.loading);
  const relatedManagers = useSelector(selectors.relatedManagers);
  const currentUser = useSelector(currentUserSelector);

  const handleApproverSelect = approverId => {
    if (approverId === currentUser.id) return setParams({ approverId: '' });
    setParams({ approverId });
  };

  return (
    <ApproverSelector
      loading={loading}
      selected={approverId || currentUser.id}
      onApproverSelect={handleApproverSelect}
      relatedManagers={relatedManagers}
      {...props}
    />
  );
};
export default ApproverSelectorContainer;
