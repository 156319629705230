import React, { Children, cloneElement, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'theme/theme';

const TabGroup = ({ defaultTab, children }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const TabItems = () => {
    return Children.map(children, child => {
      const { title } = child.props;
      const newChild = cloneElement(child, { active: title === activeTab });
      return newChild;
    });
  };

  const tabs = Children.map(TabItems(), ({ props }) => props);
  const [firstTab = {}] = tabs;
  if (!activeTab) setActiveTab(firstTab.title);

  return (
    <div className="tab-group">
      <TabNav className="tab-group__nav">
        {tabs.map(tab => (
          <button
            key={tab.title}
            className={`tab-group__nav-button ${tab.active ? 'tab-group__nav-button--active' : ''}`}
            onClick={() => setActiveTab(tab.title)}
          >
            {tab.title}
          </button>
        ))}
      </TabNav>
      <TabView className="tab-group__view">
        <TabItems />
      </TabView>
    </div>
  );
};

const TabNav = styled.nav`
  position: relative;
  display: flex;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 1px;
    background-color: ${colors.graySilver};
  }

  .tab-group__nav-button {
    appearance: none;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    padding: 0.75em 1.5em;
    border: 0;
    border-bottom: 2px solid transparent;
    font-size: 16px;
    font-weight: 700;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    &--active {
      background-color: ${colors.grayTint};
      border-bottom-color: ${colors.orangeGambage};
    }
  }
`;

const TabView = styled.div`
  margin-top: 1.5em;
`;

export default TabGroup;
