import queryString from 'query-string';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { actions } from 'modules/pto';

import PTOOverviewOptions from './PTOOverviewOptions';

class PTOOverviewOptionsContainer extends PureComponent {
  state = {
    year: undefined,
  };

  search = () => {
    const { approverId: managerId } = queryString.parse(this.props.location.search);

    this.props.updateSearch({ ...this.state, managerId });
  };

  updateSingleOption = key => value => {
    this.setState(
      () => ({
        [key]: value,
      }),
      this.search
    );
  };

  render() {
    return <PTOOverviewOptions options={this.state} onOptionChange={this.updateSingleOption} />;
  }
}

export default withRouter(connect(null, { updateSearch: actions.adminTeamPtoOverview })(PTOOverviewOptionsContainer));
