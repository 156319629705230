import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import queryString from 'query-string';

const defaultParseOptions = { parseNumbers: true };

const useQueryParams = () => {
  const dispatch = useDispatch();
  const search = useSelector(state => state.router.location.search);

  const getParams = options => {
    const parsed = queryString.parse(search, { ...defaultParseOptions, ...options });
    return parsed;
  };

  const setParams = params => {
    const parsed = queryString.parse(search, defaultParseOptions);
    const newParams = { ...parsed, ...params };
    const cleaned = Object.entries(newParams).reduce((result, [key, value]) => {
      if (!String(value)) return result;
      return { ...result, [key]: value };
    }, {});
    const stringified = queryString.stringify(cleaned);
    dispatch(push(`?${stringified}`));
  };

  return { search, getParams, setParams };
};

export default useQueryParams;
