import styled, { keyframes } from 'styled-components';
import { colors } from 'theme/theme';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dashPosition = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

const colorChanging = keyframes`
  100%, 0% {
    stroke: ${colors.orangeGambage};
  }
  40% {
    stroke: ${colors.redPersian};
  }
  80%, 90% {
    stroke: ${colors.blueDodger};
  }
`;

const Loader = styled.div`
  padding: 20px;

  ${props => props.collapse && `padding: 0`};

  .Loader__container {
    position: relative;
    margin: 0 auto;
    width: 75px;

    ${props => props.compact && `width: 40px`};

    ${props => props.dynamic && `width: 1em`};
  }

  svg {
    animation: ${rotate} 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    margin: auto;
  }

  circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: ${dashPosition} 1.5s ease-in-out infinite, ${colorChanging} 6s ease-in-out infinite;
    stroke-linecap: round;
  }
`;
export default Loader;
