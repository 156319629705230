import styled from 'styled-components';

import { colors } from '../../theme/theme';

export const StyledCalendar = styled.div`
  display: grid;
  color: ${colors.grayNight};
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(3, auto);
  border: 1px solid ${colors.graySilver};
  border-radius: 5px;
  overflow: hidden;
`;

export const CalendarTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  grid-column: 1 / span 7;
  padding: 3px 5px;
  text-align: center;
  background: ${colors.grayTint};
  color: ${colors.black};

  .Calendar__title {
    flex: 1 1 auto;
    margin: 0 10px;
    font-size: 16px;
    font-weight: 700;
  }

  .Calendar__current {
    font-size: 12px;
    text-align: center;

    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .Calendar__left,
  .Calendar__right {
    flex: 0 0 auto;

    button {
      font-size: 24px;
    }
  }
`;

export const CalendarHeaders = styled.div`
  display: grid;
  width: 100%;
  grid-column: 1 / span 7;
  grid-template-columns: repeat(7, 1fr);
  background: ${colors.grayTint};
  color: ${colors.grayDim};
  border-bottom: 1px solid ${colors.graySilver};

  .Calendar__header {
    padding: 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    border-right: 1px solid transparent;
  }
`;

export const CalendarGrid = styled.div`
  display: grid;
  width: 100%;
  background: #eeeeee;
  grid-column: 1 / span 7;
  grid-template-columns: repeat(7, 1fr);
`;

export default StyledCalendar;
