import { sortBy, sortedUniqBy } from 'lodash';

export const sortUnique = (arr, fn) => {
  const sortedArr = sortBy(arr, fn);
  return sortedUniqBy(sortedArr, fn);
};

export const getIndex = (arr = [], item = {}, key = 'id') =>
  arr.findIndex(current => {
    return current[key] === item[key] || current[key] === item[key];
  });

export const findItem = (arr = [], item = {}, key = 'id') =>
  arr.find(current => {
    return current[key] === item[key] || current[key] === item[key];
  });

export const replaceItem = (arr = [], item = {}, key = 'id') => {
  const index = getIndex(arr, item, key);
  return index > -1 ? [...arr.slice(0, index), item, ...arr.slice(index + 1)] : [...arr, item];
};

export const removeItem = (arr = [], item = {}, key = 'id') => {
  const index = getIndex(arr, item, key);
  return index > -1 ? [...arr.slice(0, index), ...arr.slice(index + 1)] : arr;
};

export const updateItem = (arr = [], item = {}, key = 'id') => {
  const index = getIndex(arr, item, key);
  return index > -1 ? [...arr.slice(0, index), { ...arr[index], ...item }, ...arr.slice(index + 1)] : [...arr, item];
};

export const reorder = (list = [], fromIndex, toIndex) => {
  const result = [...list];
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);
  return result;
};

export default {};
