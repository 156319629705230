import React from 'react';
import styled from 'styled-components';

import { colors } from 'theme/theme';
import LinkRow from 'components/LinkRow';
import Loader from 'components/Loader';
import Table from 'components/Table';

const Badge = styled.div`
  background: ${colors.grayTint};
  border: 1px solid ${colors.graySilver};
  border-radius: 3px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: ${colors.grayDim};
`;

const PendingTimesheets = ({ loading, timesheets = [] }) => {
  if (loading) return <Loader />;

  return timesheets.length > 0 ? (
    <Table>
      <thead>
        <tr>
          <th>Employee</th>
          <th className="center">Total Hours</th>
          <th className="center">Capacity</th>
          <th className="center">Billable Hours</th>
          <th className="center">Utilization</th>
        </tr>
      </thead>
      <tbody>
        {timesheets.map(timesheet => (
          <LinkRow key={timesheet.id} to={`/team/timesheets/${timesheet.id}`} hover={'rgba(0,0,0,0.02)'}>
            <td>{timesheet.employee}</td>
            <td className="center">{parseFloat(timesheet.hoursTotal).toFixed(2)}</td>
            <td className="center">{timesheet.capacity}</td>
            <td className="center">{parseFloat(timesheet.hoursBillable).toFixed(2)}</td>
            <td className="center">{parseInt(timesheet.utilization * 100, 10)}%</td>
          </LinkRow>
        ))}
      </tbody>
    </Table>
  ) : (
    <Badge>No pending timesheets</Badge>
  );
};

export default PendingTimesheets;
