import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import dayjs from 'lib/dayjs';
import find from 'lodash/find';

import { TableContainer } from './Reports.styled';
import Table from 'components/Table';
import TitledCell from 'components/TitledCell';

const billingTermsArr = [
  { label: 'Net 30', value: '30' },
  { label: 'Net 45', value: '45' },
  { label: 'Net 60', value: '60' },
  { label: '2%/15, Net 30', value: '2%/15,30', disabled: true },
];

const AccountingReportTable = ({ results }) => {
  const fileName = `Accounting-Report-${dayjs().format('L')}`;

  const renderRow = row => {
    const rowBillType = row.engagement.billingType;

    let billingType;
    let hourlyRate;
    if (rowBillType === 'variable') {
      billingType = 'T&M';
      hourlyRate = row.rate;
    } else if (rowBillType === 'fixed') {
      billingType = 'Fixed';
      hourlyRate = 'N/A';
    }

    const billingTerms = find(billingTermsArr, function(t) {
      return row.engagement.clientBillingTerms === t.value;
    });

    const name = row.user.name.split(' ');
    const formattedName = `${name[1]}, ${name[0]}`;

    return (
      <tr key={row.id}>
        <TitledCell>{formattedName ?? ''}</TitledCell>
        <TitledCell>{row.user?.email ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.clientName ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.engagementManager ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.salesRepId ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.salesRep ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.projectId ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.name ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.billingClass ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.clientPoNumber ?? ''}</TitledCell>
        <TitledCell>{billingTerms?.label ?? ''}</TitledCell>
        <TitledCell>{billingType ?? ''}</TitledCell>
        <TitledCell>{row.engagement?.projectDepartment ?? ''}</TitledCell>
        <TitledCell>{row.role ?? ''}</TitledCell>
        <TitledCell>{row.date ?? ''}</TitledCell>
        <TitledCell>{row.hours ?? ''}</TitledCell>
        <TitledCell>{hourlyRate ?? ''}</TitledCell>
        <TitledCell>{row.note ?? ''}</TitledCell>
      </tr>
    );
  };

  return (
    <>
      <TableContainer>
        <div className="Table__actions">
          <div className="Table__comment">Hover over cell for full contents</div>
          <ReactHTMLTableToExcel
            className="download-table-xls-button"
            table="table-to-xls"
            filename={fileName}
            sheet="report1"
            buttonText="Download as XLS"
          />
        </div>
        <div className="Table__scroller">
          <Table id="table-to-xls">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Client Name</th>
                <th>Engagement Manager</th>
                <th>Sales Rep ID</th>
                <th>Sales Rep</th>
                <th>Project Code</th>
                <th>Project Name</th>
                <th>Billing Class</th>
                <th>Client PO#</th>
                <th>Client Billing Terms</th>
                <th>Billing Type</th>
                <th>Project Department</th>
                <th>Role</th>
                <th>Date</th>
                <th>Hours</th>
                <th>Hourly Rate</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>{results.map(renderRow)}</tbody>
          </Table>
        </div>
      </TableContainer>
    </>
  );
};

export default AccountingReportTable;
