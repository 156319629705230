import styled from 'styled-components';

import { colors } from 'theme/theme';

const SubHeader = styled.div`
  display: flex;
  background: ${colors.graySmoke};
  border-bottom: solid 1px ${colors.grayTint};
  padding: 15px 20px;

  .SubHeader__container {
    display: flex;
    align-items: center;
  }

  .SubHeader__items {
    margin-right: 20px;
  }

  .SubHeader__details {
    margin-left: auto;
  }
`;

export default SubHeader;
