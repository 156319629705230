import styled from 'styled-components';

import { colors } from 'theme/theme';

const InputContainer = styled.div`
  background-color: ${colors.white};
  border: solid 1px ${colors.graySilver};
  margin-right: 10px;
  margin-top: 10px;

  &:last-child {
    margin: 0;
    margin-top: 10px;
  }

  h3 {
    font-size: 12px;
    padding: 8px 14px 0 14px;
    color: ${colors.grayMortar};
    margin: 0 0 3px;
  }

  input {
    border: none;
    outline: none;
    box-shadow: none;
    height: 36px;
    width: 100%;
    font-size: 16px;
    padding: 5px 14px;

    &:hover {
      cursor: pointer;
    }
  }

  .react-select {
    padding: 0 14px;

    .react-select__menu {
      margin-left: -14px;
    }
  }

  .react-select__control {
    border: 0;
    font-size: 16px;

    &:hover {
      box-shadow: none;
    }

    .react-select__placeholder {
      padding: 0;
    }

    .react-select__input {
      padding: 0;
    }

    .react-select__value-container {
      padding: 0 !important;
    }

    .react-select__indicator {
      padding-right: 0;
    }
  }
`;

export default InputContainer;
