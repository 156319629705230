import includes from 'lodash/includes';
import omit from 'lodash/omit';
import xor from 'lodash/xor';
import queryString from 'query-string';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions as modalActions } from 'modules/modal';
import { actions, PTO_REQUEST_MODAL, selectors } from 'modules/pto';
import { selectors as referenceSelectors } from 'modules/reference';
import { PTO_STATUS_VALUE } from 'utils/reference';

import PTOAdminTable from './PTOAdminTable';

class PTOAdminTableContainer extends PureComponent {
  static defaultProps = {
    shouldFetch: true,
    status: undefined,
  };

  state = {
    selectedItems: [],
  };

  getParsedManagerId() {
    const { approverId } = queryString.parse(this.props.location.search);

    return approverId;
  }

  componentDidMount() {
    const { status, shouldFetch } = this.props;
    const { year, id: userId } = this.props.match.params;
    const startDate = `${year}-01-01`;
    const endDate = `${year}-12-31`;
    const managerId = this.getParsedManagerId();

    if (shouldFetch) this.props.fetchTeamPto({ userId, startDate, endDate, status, managerId });
  }

  componentWillReceiveProps() {
    this.setState(() => ({
      selectedItems: [],
    }));
  }

  teamMember = () => {
    const { id } = this.props.match.params;
    return this.props.teamMember(id);
  };

  approveBulk = ids => {
    const { approveBulk, showModal } = this.props;

    showModal('PTOBulkActionModal', {
      actionQuantity: ids.length,
      actionType: 'approve',
      type: 'success',
      title: 'Bulk Approve',
      closeText: `Yes, Approve`,
      cancelText: `No, Cancel`,
      onClose: comment => approveBulk(ids, comment, this.getParsedManagerId()),
    });
  };

  rejectBulk = ids => {
    const { rejectBulk, showModal } = this.props;

    showModal('PTOBulkActionModal', {
      actionQuantity: ids.length,
      actionType: 'reject',
      type: 'alert',
      title: 'Bulk Reject',
      closeText: `Yes, Reject`,
      cancelText: `No, Cancel`,
      onClose: comment => rejectBulk(ids, comment, this.getParsedManagerId()),
    });
  };

  details = details => {
    const { approve, reject, showModal } = this.props;
    const { ptoId } = details;

    showModal('PTOAdmin', {
      id: PTO_REQUEST_MODAL,
      details,
      onClose: (type, comment) => {
        if (type === PTO_STATUS_VALUE.approved) {
          approve(ptoId, comment, this.getParsedManagerId());
        } else if (type === PTO_STATUS_VALUE.rejected) {
          reject(ptoId, comment, this.getParsedManagerId());
        }
      },
    });
  };

  select = id => () => {
    const { pto: items } = this.props;
    const itemIds = items.map(item => item.id);

    const doSelect = (id, items, allItems) => {
      if (id === 'all') {
        return items.length === allItems.length ? [] : allItems;
      } else {
        return xor(items, [id]);
      }
    };

    this.setState(state => ({
      selectedItems: doSelect(id, state.selectedItems, itemIds),
    }));
  };

  getChildProps = () => ({
    ...omit(this.props, ['pto']),
    ...this.state,
    items: this.props.pto,
    teamMember: this.teamMember(),
    year: this.props.match.params.year,
    isSelected: id => includes(this.state.selectedItems, id),
    onBulkApprove: this.approveBulk,
    onBulkReject: this.rejectBulk,
    onApprove: this.approve,
    onDetails: this.details,
    onReject: this.reject,
    onSelectChange: this.select,
  });

  render() {
    return <PTOAdminTable {...this.getChildProps()} />;
  }
}

const mapStateToProps = state => ({
  pto: selectors.teamPto(state),
  loading: selectors.loading(state),
  teamMember: referenceSelectors.teamMember(state),
});

export default withRouter(
  connect(mapStateToProps, {
    fetchTeamPto: actions.adminTeamPto,
    approve: actions.adminApprove,
    approveBulk: actions.adminApproveBulk,
    reject: actions.adminReject,
    rejectBulk: actions.adminRejectBulk,
    showModal: modalActions.showModal,
  })(PTOAdminTableContainer)
);
