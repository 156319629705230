import React from 'react';
import styled from 'styled-components';

const buttonStyles = props => {
  if (props.as !== 'button') return '';
  return `
    all: unset;
    box-sizing: border-box;
  `;
};

const StyledAccessible = styled.button`
  ${buttonStyles}
  ${props => props.block && `width: 100%`};
`;

// Certain elements don't give us the keyboard click event (such as tr)
// These are the keyboard keys that can act as click (Space and Enter)
const VALID_CLICK_KEYCODES = [13, 32];

const Accessible = React.forwardRef(({ elementType = 'button', role = 'button', onClick, ...props }, ref) => {
  const handleClick = e => {
    if (!VALID_CLICK_KEYCODES.includes(e.keyCode)) return;
    // Keyboard event instead of click event might be
    // weird here, but can determine if this is an issue
    // as time goes on.
    onClick?.(e);
  };

  return (
    <StyledAccessible
      ref={ref}
      as={elementType}
      role={role}
      tabIndex="0"
      onClick={onClick}
      onKeyUp={handleClick}
      {...props}
    />
  );
});

export { Accessible };
