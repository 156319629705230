import React, { Fragment } from 'react';

import { Filters, NoResults } from './Reports.styled';
import AdditionalProjectSelect from 'containers/AdditionalProjectSelect.container';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import InputContainer from 'components/InputContainer';
import DatePicker from 'components/DatePicker';
import Loader from 'components/Loader';
import ResultsTable from './GeneralTimeReportTable';

const ProjectTimeReportLayout = ({
  startDate,
  endDate,
  projectId,
  selectStartDate,
  selectEndDate,
  setProjectId,
  report,
  runReport,
  loading,
}) => {
  let mainView = null;

  const breadcrumbs = [{ display: 'Reports', url: '/reports' }, { display: 'General Project Time Report' }];

  if (loading) {
    mainView = <Loader compact />;
  } else if (report.results.length > 0) {
    mainView = <ResultsTable records={report.results} />;
  } else if (report.ranReport) {
    mainView = <NoResults>No Records Were Found</NoResults>;
  } else {
    mainView = <NoResults>Make selections and click run report</NoResults>;
  }

  return (
    <Fragment>
      <Breadcrumbs routes={breadcrumbs} />
      <Filters>
        <div className="Filters__options">
          <InputContainer>
            <h3>Start Date</h3>
            <DatePicker
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={selectStartDate}
            />
          </InputContainer>
          <InputContainer>
            <h3>End Date</h3>
            <DatePicker
              selected={endDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={selectEndDate}
            />
          </InputContainer>
          <InputContainer>
            <h3>Project</h3>
            <AdditionalProjectSelect
              defaultValue={projectId}
              value={projectId ? projectId : ''}
              onChange={setProjectId}
              width={200}
            />
          </InputContainer>
        </div>
        <div className="Filters__actions">
          <Button info disabled={loading} onClick={() => runReport()}>
            Run Report
          </Button>
        </div>
      </Filters>
      {mainView}
    </Fragment>
  );
};

export default ProjectTimeReportLayout;
