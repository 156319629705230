import { createContext, useEffect, useContext, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router';
import { analytics } from '../lib/analytics/analytics';

export const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    analytics?.page({
      url: location.pathname,
    });
  }, [location]);

  const trackEvent = useCallback(args => {
    analytics?.track(...args);
  }, []);

  const value = useMemo(() => ({ trackEvent }), [trackEvent]);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (context === null) {
    throw new Error('useAuth must be used within AnalyticsProvider');
  }

  return context;
};
