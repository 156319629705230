import { useState } from 'react';
import styled from 'styled-components';
import { orderBy } from 'lodash';

const SortingIcon = styled.i`
  font-size: 14px;
  margin-left: 5px;
`;

const useSort = ({ defaultSortKey, defaultSortDirection, customSortHandler }) => {
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [sortDirection, setSortDirection] = useState(defaultSortDirection);
  const [sortedRows, setSortedRows] = useState([]);

  const getSortIndicator = key => {
    if (key === sortKey && sortDirection !== 'default') {
      const iconDirection = sortDirection === 'asc' ? 'up' : 'down';
      return <SortingIcon className={`fa fa-caret-${iconDirection}`} />;
    }
  };

  const onSort = (key, data) => {
    let sortedArr;
    let nextSortDirection = 'asc';

    if (key === sortKey && sortDirection !== 'default') {
      nextSortDirection = sortDirection === 'asc' ? 'desc' : 'default';
    }

    setSortDirection(nextSortDirection);
    setSortKey(key);

    if (customSortHandler) {
      sortedArr = customSortHandler(data, key, nextSortDirection);
    } else {
      sortedArr = orderBy(data, [key], [nextSortDirection]);
    }

    setSortedRows(sortedArr);
  };

  return { sortDirection, sortedRows, getSortIndicator, onSort };
};

export default useSort;
