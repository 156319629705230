import dayjs from 'lib/dayjs';
import { useDispatch } from 'react-redux';

import { userLogOut } from 'modules/currentUser';
import { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';

export const AutoLogoutTimer = props => {
  const dispatch = useDispatch();
  const onIdle = useCallback(() => dispatch(userLogOut()), [dispatch]);

  const currentTime = dayjs().unix();

  const oneDayFromNow = dayjs()
    .add(1, 'd')
    .unix();

  const timeTillLogoutSeconds = oneDayFromNow - currentTime;

  useIdleTimer({ onIdle, timeout: timeTillLogoutSeconds * 1000 });

  return props.children;
};
