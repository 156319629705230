import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import PeriodSelector from 'components/PeriodSelector';
import { selectors as periodSelectors } from 'modules/timesheetPeriods';
import useQueryParams from 'hooks/useQueryParams';

const TeamPeriodSelectorContainer = props => {
  const { getParams, setParams } = useQueryParams();
  const { startDate: queryStartDate } = getParams();
  const timesheetPeriods = useSelector(periodSelectors.periods);
  const currentPeriod = useSelector(periodSelectors.currentPeriod);

  const selectedPeriod = useMemo(() => {
    const foundPeriod = timesheetPeriods.find(period => period.startDate === queryStartDate);
    return foundPeriod ?? currentPeriod;
  }, [queryStartDate, timesheetPeriods, currentPeriod]);

  const handlePeriodSelect = ({ startDate }) => {
    setParams({ startDate });
  };

  return (
    <PeriodSelector
      {...props}
      selectedPeriod={selectedPeriod}
      timesheetPeriods={timesheetPeriods}
      currentPeriod={currentPeriod}
      onPeriodSelect={handlePeriodSelect}
    />
  );
};

export default TeamPeriodSelectorContainer;
