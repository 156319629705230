import React, { Fragment } from 'react';

import { colors } from 'theme/theme';
import Breadcrumbs from 'components/Breadcrumbs';
import LinkRow from 'components/LinkRow';
import Table from 'components/Table';

const BillingPeriodLayout = ({ classes }) => {
  const breadcrumbs = [{ display: 'Reports' }];

  const ReportTypes = [
    { name: 'Accounting Report', path: '/reports/accounting' },
    { name: 'Allocation Report', path: '/reports/allocation' },
    { name: 'General Project Time Report', path: '/reports/general-time' },
    { name: 'Project Time Report', path: '/reports/project-time' },
    {
      name: 'Timesheets Not Submitted Report',
      path: '/reports/timesheets-not-submitted',
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs routes={breadcrumbs} />

      <Table>
        <tbody>
          {ReportTypes.map(({ name, path }) => {
            return (
              <LinkRow key={path} to={path} hover={colors.graySmoke}>
                <td>{name}</td>
                <td style={{ width: 45, fontSize: 24, color: colors.graySilver }}>
                  <i className="fa fa-angle-right" />
                </td>
              </LinkRow>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default BillingPeriodLayout;
