import React from 'react';
import styled from 'styled-components';

import { PageBlock } from 'components/layout';
import { PTOOverviewOptions } from 'components/PTO/PTOOverviewOptions';
import PTOAdminTableOverview from 'components/PTO/PTOAdminTableOverview';

const Page = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(1, 1fr);
`;

const PTOOverview = () => (
  <Page>
    <PageBlock>
      <PTOOverviewOptions />
    </PageBlock>
    <PageBlock>
      <PTOAdminTableOverview />
    </PageBlock>
  </Page>
);

export default PTOOverview;
