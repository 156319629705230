import { createActions, handleActions } from 'redux-actions';
import { all, takeLatest, call, put } from 'redux-saga/effects';

import * as api from 'utils/api';

// default state
const defaultState = {
  users: [],
  loading: false,
};

// actions
export const actions = createActions('FETCH_USERS', 'FETCH_USERS_SUCCESS');

// selectors
export const selectors = {
  users: state => state.admin.users,
  loading: state => state.admin.loading,
};

// sagas
function* fetchUsersSaga({ payload }) {
  try {
    const { employees } = yield call(api.fetchUsers, payload);

    if (employees) {
      yield put(actions.fetchUsersSuccess(employees));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* adminSaga() {
  yield all([takeLatest(actions.fetchUsers, fetchUsersSaga)]);
}

// reducer
export default handleActions(
  {
    [actions.fetchUsers](state) {
      return { ...state, loading: true, users: defaultState.users };
    },
    [actions.fetchUsersSuccess](state, { payload: users }) {
      return { ...state, loading: false, users };
    },
  },
  defaultState
);
