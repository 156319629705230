import React from 'react';
import styled from 'styled-components';

import Button from './Button';

const StyledButtonIcon = styled(Button)`
  display: inline-flex;
  align-items: center;

  ${props => props.iconOnly && `font-size: 16px`};
  ${props => props.iconOnly && `font-size: 24px`};
  ${props => props.iconOnly && `margin: 0`};
  ${props => props.iconOnly && `padding: 8px`};

  .Button__icon {
    font-size: 16px;
    margin-right: 8px;

    ${props => props.iconOnly && `margin: 0`};
  }
`;

const ButtonIcon = ({ children, icon, ...props }) => {
  const iconOnly = props.iconOnly || (!children && icon);

  return (
    <StyledButtonIcon iconOnly={iconOnly} {...props}>
      {icon && <i className={`fa fa-${icon} Button__icon`} />}
      {children}
    </StyledButtonIcon>
  );
};

export { ButtonIcon };
