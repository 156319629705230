import { Project } from 'components/Project/Project';
import { ADDITIONAL_PROJECTS_ID } from 'modules/projects';
import React, { useCallback, useEffect, useRef } from 'react';
import Mark from 'mark.js';
import styled from 'styled-components';

import { Pane } from './Pane';

const StyledSearchPane = styled(Pane)`
  .Pane__message {
    padding: 10px;
  }
`;

const SearchPane = ({ options, query, onChange, onClose }) => {
  const markRef = useRef();
  const searchRef = useRef();

  // Highlighting Matched Parts
  useEffect(() => {
    const queryParts = query.split(' ');

    if (!searchRef.current) return;
    markRef.current = new Mark(searchRef.current);
    markRef.current.unmark({
      done: () => {
        markRef.current.mark(queryParts);
      },
    });
  }, [query]);

  const onSelect = useCallback(
    (selection, isAdditionalProject = false) => {
      onChange(selection, isAdditionalProject);
      onClose();
    },
    [onClose, onChange]
  );

  const renderResults = () => {
    if (!options.length) {
      return <div className="Pane__message">No Results Found!</div>;
    }

    return options.map(option => {
      const isAdditionalProject = option.clientId === ADDITIONAL_PROJECTS_ID;
      const keyProject = isAdditionalProject
        ? `${option.clientId}_${option.projectId}`
        : `${option.clientId}_${option.projectId}_${option.roleId}`;

      const projectOption = {
        ...option,
        name: option.projectName,
        projectId: option.projectKey,
        role: option.roleName,
      };

      return (
        <div className="Pane__item" key={keyProject}>
          <Project
            data={projectOption}
            isAdditionalProject={isAdditionalProject}
            onClick={() => {
              if (isAdditionalProject) {
                onSelect({ clientId: ADDITIONAL_PROJECTS_ID, projectId: option.projectId, roleId: null }, true);
                return;
              }
              onSelect({ clientId: option.clientId, projectId: option.projectId, roleId: option.roleId });
            }}
          />
        </div>
      );
    });
  };

  return (
    <StyledSearchPane title={`Results for "${query}"`} onClose={onClose}>
      <div ref={searchRef}>{renderResults()}</div>
    </StyledSearchPane>
  );
};

export { SearchPane };
