import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { connectRouter } from 'connected-react-router';

import admin, { adminSaga } from 'modules/admin';
import currentUser, { currentUserSaga, userLogOut } from 'modules/currentUser';
import modal from 'modules/modal';
import projects, { projectsSaga } from 'modules/projects';
import pto, { ptoSaga } from 'modules/pto';
import reference, { referenceSaga } from 'modules/reference';
import approvers, { approversSaga } from 'modules/approvers';
import timesheet, { timesheetSaga } from 'modules/timesheet';
import timesheetPeriods, { timesheetPeriodsSaga } from 'modules/timesheetPeriods';
import toast from 'modules/toast';
import reports, { reportSaga } from 'modules/reports';
import team, { teamSaga } from 'modules/team';

export function* rootSaga() {
  yield all([
    adminSaga(),
    currentUserSaga(),
    projectsSaga(),
    ptoSaga(),
    teamSaga(),
    approversSaga(),
    timesheetPeriodsSaga(),
    timesheetSaga(),
    referenceSaga(),
    reportSaga(),
  ]);
}

const createRootReducer = history =>
  combineReducers({
    admin,
    currentUser,
    modal,
    projects,
    pto,
    reference,
    approvers,
    team,
    timesheet,
    timesheetPeriods,
    toast,
    reports,
    router: connectRouter(history),
  });

export default history => (state, action) => {
  const rootReducer = createRootReducer(history);

  // Blow away all state if user logged out
  if (action.type === userLogOut.toString()) state = undefined;

  // Delegate to appReducer
  return rootReducer(state, action);
};
