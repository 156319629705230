import Button from 'components/Button';
import Icon from 'components/Icon';
import styled from 'styled-components';
import { colors } from 'theme/theme';

const StyledErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  margin: 100px auto;
  text-align: center;

  .Error__icon {
    font-size: 48px;
    color: ${colors.redPersian};
  }

  .Error__title {
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 10px;
  }

  .Error__message {
    font-weight: 300;
    font-size: 18px;
    margin: 0 0 25px;
  }

  .Error__retry {
  }
`;

export const ErrorMessage = ({
  icon = 'exclamation-triangle',
  title = 'Something Went Wrong',
  message = 'We ran into an issue. Please try what you were doing again shortly or report it if it persists!',
  // eslint-disable-next-line no-restricted-globals
  onRetry = () => location.reload(),
}) => {
  return (
    <StyledErrorMessage>
      <div className="Error__icon">
        <Icon type={icon} />
      </div>
      <div className="Error__title">{title}</div>
      <div className="Error__message">{message}</div>
      {onRetry && (
        <div className="Error__retry">
          <Button onClick={onRetry} info>
            Click here try again.
          </Button>
        </div>
      )}
    </StyledErrorMessage>
  );
};
