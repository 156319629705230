import React, { PureComponent } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import dayjs from 'lib/dayjs';

import { TableContainer } from './Reports.styled';
import Table from 'components/Table';
import TitledCell from 'components/TitledCell';

class AllocationReportTable extends PureComponent {
  renderRow = (row, i) => (
    <tr key={`${i}_${row.name}`}>
      <TitledCell>{row.name}</TitledCell>
      <TitledCell>{row.email}</TitledCell>
      <TitledCell>{row.project.code}</TitledCell>
      <TitledCell>{row.project.client}</TitledCell>
      <TitledCell>{row.project.department}</TitledCell>
      <TitledCell>{row.hours}</TitledCell>
    </tr>
  );

  render() {
    const { records } = this.props;
    const fileName = `Allocation-Report-${dayjs().format('L')}`;

    return (
      <TableContainer>
        <div className="Table__actions">
          <div className="Table__comment">Hover over cell for full contents</div>

          <ReactHTMLTableToExcel
            className="download-table-xls-button"
            table="table-to-xls"
            filename={fileName}
            sheet="report1"
            buttonText="Download as XLS"
          />
        </div>
        <div className="Table__scroller">
          <Table id="table-to-xls">
            <thead>
              <tr>
                <th>User Name</th>
                <th>User Email</th>
                <th>Project Code</th>
                <th>Project Client</th>
                <th>Project Department</th>
                <th>Project Hrs.</th>
              </tr>
            </thead>
            <tbody>{records.length > 0 && records.map(this.renderRow)}</tbody>
          </Table>
        </div>
      </TableContainer>
    );
  }
}

export default AllocationReportTable;
