import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SubmitButton from 'components/SubmitButton';
import { actions as modalActions } from 'modules/modal';
import { actions, selectors } from 'modules/team';

class SubmitButtonContainer extends PureComponent {
  onSubmit = () => {
    const { showModal, submitTimesheet } = this.props;

    showModal('confirm', {
      title: 'Submit Timesheet',
      type: 'success',
      onClose: submitTimesheet,
      closeText: 'Yes, Submit',
      cancelText: 'No, Cancel',
      message: `Are you sure you want to submit this timesheet? This employee won't be able to make any changes once submitted!`,
    });
  };

  render() {
    return <SubmitButton {...this.props} onSubmit={this.onSubmit} />;
  }
}

const mapState = state => ({
  disabled: selectors.memberTimesheetLoading(state),
});

const mapDispatch = {
  showModal: modalActions.showModal,
  submitTimesheet: actions.teamSubmitTimesheet,
};

export default connect(mapState, mapDispatch)(SubmitButtonContainer);
