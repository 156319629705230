import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentUser } from 'modules/currentUser';
import rollbar from 'lib/rollbar';

const GlobalPlugins = () => {
  const user = useSelector(currentUser);

  useEffect(() => {
    const person = user ? { id: user.id, username: user.name, email: user.email } : { id: null };
    rollbar.configure({ payload: { person } });
  }, [user]);

  return null;
};

export default GlobalPlugins;
