import React from 'react';
import dayjs from 'lib/dayjs';
import BaseDatePicker from 'react-datepicker';

const DatePicker = ({ ariaLabel, onChange, ...rest }) => {
  const onChangeDate = date => {
    if (!date) return onChange(null);
    onChange(dayjs(date));
  };

  const placeholder = rest.placeholder ?? 'Date Input';
  const labelHelperText = `Please enter the date in the format two digit month slash two digit day slash four digit year.
  Example: zero one slash one three slash two zero two zero for January 13th, 2020.`;

  const selected = rest.selected ? rest.selected.toDate() : null;
  const startDate = rest.startDate ? rest.startDate.toDate() : null;
  const endDate = rest.endDate ? rest.endDate.toDate() : null;
  const minDate = rest.minDate ? rest.minDate.toDate() : null;
  const maxDate = rest.maxDate ? rest.maxDate.toDate() : null;

  return (
    <>
      <BaseDatePicker
        {...rest}
        customInput={<input aria-label={`${ariaLabel ?? placeholder}. ${labelHelperText}`} type="text" />}
        selected={selected}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChangeDate}
      />
    </>
  );
};

export default DatePicker;
