import apiFetch from 'utils/apiFetch';

// Reference Data
export const fetchHolidays = () => apiFetch('/api/holidays');

// currentUser
export const fetchCurrentUser = () => apiFetch('/api/user');
export const fetchTeamMembers = () => apiFetch('/api/team_members');
export const fetchRelatedManagers = () => apiFetch('/api/related_managers');
export const fetchCodeAlerts = () => apiFetch('/api/code_alerts');
export const signOut = () => apiFetch('/api/signout');

export const timesheetPeriods = () => apiFetch('/api/timesheet_periods');

export const timesheet = ({ startDate: start_date, userId: user_id } = {}) => {
  const opts = {};
  opts.params = { start_date, user_id };
  return apiFetch('/api/timesheet', opts);
};

export const timesheetHistory = ({ id, userId: user_id }) => {
  const opts = {};
  opts.params = { user_id };
  return apiFetch(`/api/timesheet/${id}/history`, opts);
};

export const createTimesheet = body => apiFetch(`/api/timesheet`, { method: 'POST', body });

export const updateTimesheet = (id, body) => apiFetch(`/api/timesheet/${id}`, { method: 'PUT', body });

export const submitTimesheet = (id, body) => apiFetch(`/api/timesheet/${id}/submit`, { method: 'PATCH', body });

export const rescindTimesheet = id => apiFetch(`/api/timesheet/${id}/rescind`, { method: 'PATCH' });

export const projects = start_date => {
  const opts = {};
  opts.params = { start_date };
  return apiFetch('/api/projects', opts);
};

export const additionalProjects = () => apiFetch('/api/additional_projects');

// departments
export const fetchDepartments = () => apiFetch('/api/departments');

// clients
export const fetchClients = params => apiFetch('/api/clients', { params });

// engagements
export const fetchEngagements = params => apiFetch('/api/engagements', { params });

// PTO - Admin
export const fetchTeamPto = ({
  status,
  userId: user_id,
  startDate: start_date,
  endDate: end_date,
  managerId: manager_id,
} = {}) => {
  const opts = {};
  opts.params = { status, user_id, start_date, end_date, manager_id };
  return apiFetch(`/api/team/ptos`, opts);
};

export const fetchTeamPtoOverview = ({ year, managerId } = {}) => {
  const opts = { params: { year, manager_id: managerId } };

  return apiFetch(`/api/team/ptos/overview`, opts);
};

export const adminApprovePto = ({ id, managerComment, managerId }) => {
  return apiFetch(`/api/team/ptos/${id}/approve`, {
    method: 'POST',
    body: { manager_comment: managerComment, manager_id: managerId },
  });
};

export const adminApproveBulkPto = ({ ptoIds, managerComment, managerId }) => {
  return apiFetch(`/api/team/ptos/approve`, {
    method: 'POST',
    body: { pto_ids: ptoIds, manager_comment: managerComment, manager_id: managerId },
  });
};

export const adminRejectPto = ({ id, managerComment, managerId }) => {
  return apiFetch(`/api/team/ptos/${id}/reject`, {
    method: 'POST',
    body: { manager_comment: managerComment, manager_id: managerId },
  });
};

export const adminRejectBulkPto = ({ ptoIds, managerComment, managerId }) => {
  return apiFetch(`/api/team/ptos/reject`, {
    method: 'POST',
    body: { pto_ids: ptoIds, manager_comment: managerComment, manager_id: managerId },
  });
};

// PTO - User
export const fetchBalances = () => apiFetch('/api/ptos/balances');
export const fetchUpcomingPto = () => apiFetch('/api/ptos');
export const submitPtoRequest = ptoDetails =>
  apiFetch('/api/ptos', { method: 'POST', body: { pto_details: ptoDetails } });
export const updatePtoRequest = (id, ptoDetails) =>
  apiFetch(`/api/ptos/${id}`, { method: 'PUT', body: { pto_details: ptoDetails } });
export const deletePtoRequest = id => apiFetch(`/api/ptos/${id}`, { method: 'DELETE' });
export const fetchPastPto = ({ year } = {}) => {
  const opts = {};
  opts.params = { past: true, year };
  return apiFetch('/api/ptos', opts);
};

// Allocation Report
export const fetchAllocationReport = payload => {
  const opts = {};
  opts.params = payload;
  return apiFetch('/api/allocation_report', opts);
};

// projectTime Report
export const fetchProjectTimeReport = payload => {
  const opts = {};
  opts.params = payload;
  return apiFetch('/api/project_time_report', opts);
};

// generalTime Report
export const fetchGeneralTimeReport = payload => {
  const opts = {};
  opts.params = payload;
  return apiFetch('/api/general_time_report', opts);
};

// projectTime Report
export const fetchAccountingReport = payload => {
  const opts = {};
  opts.params = payload;
  return apiFetch('/api/accounting_report', opts);
};

export const fetchNotSubmittedReport = ({ startDate: start_date } = {}) => {
  const opts = {};
  if (start_date) opts.params = { start_date };
  return apiFetch('/api/not_submitted_report', opts);
};

// team
export const fetchTeamTimesheets = ({ startDate: start_date, approverId: approver_id } = {}) => {
  const opts = { params: { start_date, approver_id } };
  return apiFetch('/api/team/timesheets', opts);
};

export const fetchTeamPendingTimesheets = () => apiFetch('/api/team/timesheets/pending');

export const fetchMemberTimesheet = id => {
  return apiFetch(`/api/team/timesheets/${id}`);
};

export const fetchMemberTimesheetHistory = id => {
  return apiFetch(`/api/team/timesheets/${id}/history`);
};

export const teamSubmitTimesheet = (id, body) => {
  return apiFetch(`/api/team/timesheets/${id}/submit`, { method: 'PATCH', body });
};

export const teamApproveTimesheet = id => {
  return apiFetch(`/api/team/timesheets/${id}/approve`, { method: 'PATCH' });
};

export const teamRejectTimesheet = (id, note) => {
  return apiFetch(`/api/team/timesheets/${id}/reject`, { method: 'PATCH', params: { note } });
};

export const teamUnapproveTimesheet = id => {
  return apiFetch(`/api/team/timesheets/${id}/unapprove`, { method: 'PATCH' });
};

// users
export const fetchEngagementManagers = () => apiFetch('/api/employees/engagement_managers');

// resources
export const fetchResources = () => apiFetch('/api/resources/all_resources');
export const updateResource = (id, body) => apiFetch(`/api/resources/${id}`, { method: 'PATCH', body });

// admin
export const fetchUsers = ({ isActive: account_enabled, userQuery: query } = {}) => {
  const opts = {};
  opts.params = { account_enabled, query };
  return apiFetch('/api/employees', opts);
};
