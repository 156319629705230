import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { actions } from 'modules/timesheet';

import BillingPeriod from 'containers/BillingPeriodContainer';
import BillingPeriodPrompt from 'containers/BillingPeriodPromptContainer';
import PeriodSelector from 'containers/PeriodSelectorContainer';
import SaveButton from 'containers/SaveButtonContainer';
import SubmitButton from 'containers/SubmitButtonContainer';
import RescindButton from 'containers/RescindButtonContainer';
import TabGroup from 'components/TabGroup';
import TabItem from 'components/TabItem';
import TimesheetHistory from 'containers/TimesheetHistoryContainer';
import { useCommands } from 'hooks/useCommands';
import { TimesheetComments } from 'components/Timesheets/TimesheetComments';
import { useSelector } from 'react-redux';
import { selectors } from 'modules/timesheet';
import CodeAlerts from 'containers/CodeAlertContainer';

const ControlBar = styled.div`
  position: sticky;
  top: -25px;
  display: flex;
  background: #f4f4f4;
  align-items: center;
  margin: -10px;
  margin-bottom: 10px;

  > * {
    margin: 10px;
  }

  > .selector {
    flex: 0 0 auto;
  }

  > .right {
    flex: 0 0 auto;
    margin-left: auto;
  }
`;

const TIMESHEET_COMMANDS = {
  INSERT_TIMESHEET_ROW: { key: 'i', description: 'Insert Timesheet Row' },
  SAVE_TIMESHEET: { key: ['Enter', 's'], description: 'Save Timesheet' },
  SUBMIT_TIMESHEET: { key: 'p', description: 'Submit Timesheet' }, // TBD
};

const BillingPeriodLayout = () => {
  const dispatch = useDispatch();
  const timesheet = useSelector(selectors.localTimesheet);
  const timesheetLoading = useSelector(selectors.loading);
  const timesheetRows = timesheet ? timesheet.timesheetRows : [];

  useCommands(TIMESHEET_COMMANDS, {
    INSERT_TIMESHEET_ROW: () => dispatch(actions.addTimesheetRow()),
    SAVE_TIMESHEET: () => dispatch(actions.saveTimesheet()),
  });

  return (
    <>
      <CodeAlerts />

      <ControlBar style={{ zIndex: 10 }}>
        <span className="selector">
          <PeriodSelector />
        </span>
        <div className="right">
          <SaveButton />
        </div>
      </ControlBar>

      <BillingPeriod />

      <ControlBar style={{ zIndex: 7 }}>
        <SubmitButton />
        <RescindButton />
      </ControlBar>

      <TabGroup>
        <TabItem title="Comments">
          <TimesheetComments loading={timesheetLoading} rows={timesheetRows} />
        </TabItem>
        <TabItem title="History">
          <TimesheetHistory />
        </TabItem>
      </TabGroup>

      <BillingPeriodPrompt />
    </>
  );
};

export default BillingPeriodLayout;
