import React, { PureComponent } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import dayjs from 'lib/dayjs';

import { TableContainer } from './Reports.styled';
import Table from 'components/Table';
import TitledCell from 'components/TitledCell';

class ProjectTimeReportTable extends PureComponent {
  renderRow = row => {
    return (
      <tr key={row.id}>
        <TitledCell>{row.id}</TitledCell>
        <TitledCell>{row.name}</TitledCell>
        <TitledCell>{row.title}</TitledCell>
        <TitledCell>{row.email}</TitledCell>
        <TitledCell>{row.manager}</TitledCell>
        <TitledCell>{row.totalHours}</TitledCell>
      </tr>
    );
  };

  render() {
    const { records } = this.props;
    const fileName = `Project-Time-Report-${dayjs().format('L')}`;

    return (
      <TableContainer>
        <div className="Table__actions">
          <div className="Table__comment">Hover over cell for full contents</div>

          <ReactHTMLTableToExcel
            className="download-table-xls-button"
            table="table-to-xls"
            filename={fileName}
            sheet="report1"
            buttonText="Download as XLS"
          />
        </div>
        <div className="Table__scroller">
          <Table id="table-to-xls">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Title</th>
                <th>Email</th>
                <th>Manager</th>
                <th>Total (Hrs)</th>
              </tr>
            </thead>
            <tbody>{records.length > 0 && records.map(this.renderRow)}</tbody>
          </Table>
        </div>
      </TableContainer>
    );
  }
}

export default ProjectTimeReportTable;
