import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import InputContainer from 'components/InputContainer';
import Loader from 'components/Loader';
import Select from 'components/Select';
import SimpleSearch from 'components/SimpleSearch';
import Table from 'components/Table';

const FilterSection = styled.div`
  display: flex;
  margin: 0 0 15px;
`;

const renderLoading = (colSpan = 5, numItems = 1) => {
  const arr = [...Array(numItems)];

  return (
    <Fragment>
      {arr.map((_, i) => (
        <tr key={i}>
          <td colSpan={colSpan} style={{ padding: 0 }}>
            <Loader compact />
          </td>
        </tr>
      ))}
    </Fragment>
  );
};

const renderContent = users => {
  if (users.length === 0) {
    return (
      <tr>
        <td colSpan="5" style={{ textAlign: 'center' }}>
          No Users Found!
        </td>
      </tr>
    );
  } else {
    return users.map(user => (
      <tr key={user.id}>
        <td>{user.nameReverse}</td>
        <td>{user.jobTitle}</td>
        <td style={{ minWidth: 150 }}>{user.accountEnabled ? 'Active' : 'Inactive'}</td>
        <td>{user.email}</td>
        <td className="center">
          <Link to={`/admin/users/${user.id}/timesheets`}>View Timesheet</Link>
        </td>
      </tr>
    ));
  }
};

const AdminUsers = ({ users = [], loading, isActive, setIsActiveAndSearch, userQuery, setUserQueryAndSearch }) => {
  return (
    <div>
      <FilterSection>
        <SimpleSearch onChange={setUserQueryAndSearch} label={'User Search'} placeholder={'Search by name'} />
        <InputContainer>
          <h3>Active</h3>
          <Select
            defaultValue={true}
            value={isActive !== '' ? isActive : null}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            onChange={setIsActiveAndSearch}
            isClearable
            width={220}
          />
        </InputContainer>
      </FilterSection>
      <Table>
        <thead>
          <tr>
            <th>Employee</th>
            <th>Title</th>
            <th style={{ minWidth: 150 }}>Status</th>
            <th>Email</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>{loading ? renderLoading(4) : renderContent(users)}</tbody>
      </Table>
    </div>
  );
};

export default AdminUsers;
