import React from 'react';
import find from 'lodash/find';
import styled from 'styled-components';
import dayjs from 'lib/dayjs';

import { parsedFloat } from 'utils/common';

const StyledPTOBalance = styled.div`
  display: inline-flex;
  padding-right: 15px;

  .PTOBalance__description {
    margin-right: 15px;
  }

  .PTOBalance__value {
    font-weight: 700;
  }
`;

const calculateBalance = (yearBalances = {}, year) => {
  const requestedHours = yearBalances[year] ?? 0;
  return parsedFloat(requestedHours);
};

const calculateBalanceByRequests = (requests = []) => {
  return requests.reduce((totalHours, currentRequest) => {
    const currentDetail = currentRequest?.mostRecentDetail;
    if (!currentDetail) return totalHours;

    // Only count pending and approved hours
    if (!['pending', 'approved'].includes(currentDetail.status)) return totalHours;

    const requestHours = currentDetail.hours ?? 0;
    return totalHours + parsedFloat(requestHours);
  }, 0);
};

const PTOBalance = ({ balances, requests, year = dayjs().year(), isViewingHistory }) => {
  const { ptoRequested } = find(balances, { type: 'pto' }) ?? {};

  const ytdRequested = isViewingHistory ? calculateBalanceByRequests(requests) : calculateBalance(ptoRequested, year);
  const ytdLabel = isViewingHistory ? 'Total' : 'YTD';

  return (
    <StyledPTOBalance>
      <div className="PTOBalance__description">
        {year} {ytdLabel}
      </div>
      <div className="PTOBalance__value">{ytdRequested} Hours</div>
    </StyledPTOBalance>
  );
};

export default PTOBalance;
