import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import dayjs from 'lib/dayjs';
import noop from 'lodash/noop';

import StyledBillingPeriod, {
  BillingPeriodTable,
  HeaderCell,
  FooterCell,
  DragCell,
  ProjectCell,
  TimesheetRow,
} from './BillingPeriod.styled';
import DraggableRows from 'components/BillingPeriod/DraggableRows';
import IconGrip from 'components/Icons/IconGrip';
import { DATE_FORMAT } from 'lib/constants';
import EntryInput from './EntryInput';
import Loader from 'components/Loader';
import LoaderBlock from 'components/LoaderBlock';
import CommentBubble from 'components/CommentBubble';
import { objectValues, floatString } from 'utils/common';
import { Accessible } from 'components/Accessible';
import { BillingPeriodProject } from './BillingPeriodProject';
import { DATE_FORMATS, getDateRangeString, getDateString } from 'utils/dates';
import { aria } from 'constants/aria';

const CLOSED_STATE = 'closed';

const shouldDisable = (startDate, endDate, projectState) => currentDay => {
  if (projectState === CLOSED_STATE) return true;

  if (startDate) {
    const rowDay = dayjs(currentDay, DATE_FORMAT);
    const startDay = dayjs(startDate, DATE_FORMAT);
    if (rowDay.isBefore(startDay)) return true;
  }

  if (endDate) {
    const rowDay = dayjs(currentDay, DATE_FORMAT);
    const startDay = dayjs(endDate, DATE_FORMAT);
    if (rowDay.isAfter(startDay)) return true;
  }

  return false;
};

// sums all entry hours for a row
const rowHoursTotal = ({ timesheetEntries }) =>
  timesheetEntries ? timesheetEntries.reduce((sum, x) => sum + parseFloat(x.hours), 0) : 0;

// finds an entry for the given date
const rowEntryForDate = (row, date) => {
  const entry = row.timesheetEntries.find(entry => entry.date === date);

  return {
    hours: entry ? parseFloat(entry.hours) : 0,
    note: entry ? entry.note : '',
  };
};

// builds a map of day totals
const dayHoursTotal = (dates, timesheetRows) => {
  const totals = {};

  dates.forEach(({ date }) => {
    const dayTotal = timesheetRows
      .map(row => parseFloat(rowEntryForDate(row, date).hours))
      .filter(hours => hours > 0)
      .reduce((sum, x) => sum + x, 0);

    if (dayTotal) totals[date] = dayTotal;
  });

  return totals;
};

const BillingPeriod = ({
  disabled,
  editing,
  editProject = noop,
  focusedEntry,
  loading,
  showNotes,
  timesheetPeriod = { dates: [] },
  timesheetRows = [],
  toggleNotes,
  handleEntryChange = () => noop,
  handleEntryFocus = noop,
  handleEntryNoteChange = noop,
  onAddClick = noop,
  onRemoveClick = noop,
  onRowOrderChange = noop,
}) => {
  const onDragStart = (item, provided) => {
    provided.announce(aria.timesheet.dragStart({ dragPosition: item.source.index + 1 }));
  };

  const onDragUpdate = (item, provided) => {
    let message = aria.timesheet.dragUpdateWithoutDestination();

    if (item.destination) {
      message = aria.timesheet.dragUpdateWithDestination({
        dragPositionEnd: item.destination.index + 1,
      });
    }

    provided.announce(message);
  };

  const onDragEnd = (item, provided) => {
    let message = aria.timesheet.dragEndWithoutDestination({
      dragPositionStart: item.source.index + 1,
    });

    if (item.destination) {
      message = aria.timesheet.dragEndWithDestination({
        dragPositionStart: item.source.index + 1,
        dragPositionEnd: item.destination.index + 1,
      });
    }

    provided.announce(message);
  };

  const renderRow = (row, i) => {
    const { dates = [] } = timesheetPeriod;

    const isRowDisabled = disabled || row.readOnly;
    const isRowEditing = editing === row.id;

    const isDisabled = shouldDisable(row.startDate, row.endDate, row.state);

    return (
      <Draggable isDragDisabled={isRowDisabled} key={row.id} draggableId={`${row.id}`} index={i}>
        {provided => (
          <TimesheetRow ref={provided.innerRef} {...provided.draggableProps}>
            {!disabled && (
              <DragCell disabled={isRowDisabled} {...provided.dragHandleProps} aria-labelledby="timesheet-row-aria">
                <IconGrip />
              </DragCell>
            )}
            <ProjectCell editing={isRowEditing} disabled={isRowDisabled}>
              <BillingPeriodProject
                disabled={isRowDisabled}
                editing={isRowEditing}
                projectRow={row}
                onEdit={editProject}
              />
            </ProjectCell>
            {dates.map(day => {
              const entry = rowEntryForDate(row, day.date);

              const readableDate = getDateString(day.date, DATE_FORMATS.readable);
              const entryNameRole = row.role ? `${row.name} - ${row.role}` : row.name;

              const focused = focusedEntry?.row?.id === row.id && focusedEntry?.date === day.date;

              return (
                <td className="TimesheetRow__cell" key={day.date}>
                  <EntryInput
                    aria-label={aria.timesheet.entryInput({ entryName: entryNameRole, entryDate: readableDate })}
                    value={entry.hours > 0 && floatString(entry.hours)}
                    onUpdate={val => handleEntryChange(row, day.date, val)}
                    onFocus={() => handleEntryFocus(row, day.date)}
                    disabled={isDisabled(day.date) || isRowDisabled}
                    isHoliday={day.isHoliday}
                    isToday={day.isToday}
                    isWeekend={day.isWeekend}
                  />
                  {focused && showNotes && (
                    <div className="BillingPeriod__notes">
                      <CommentBubble
                        aria-label={aria.timesheet.entryCommentInput({
                          entryName: entryNameRole,
                          entryDate: readableDate,
                        })}
                        value={entry.note}
                        onChange={val => handleEntryNoteChange(row.id, day.date, val)}
                        onClose={toggleNotes}
                      />
                    </div>
                  )}
                  {focused && !showNotes && (
                    <Accessible
                      className="BillingPeriod__notes-toggle"
                      aria-label={aria.timesheet.entryComment({ entryName: entryNameRole, entryDate: readableDate })}
                      onClick={() => toggleNotes(row, day.date)}
                    >
                      <i className="fa fa-commenting" title="Entry Has Notes" />
                    </Accessible>
                  )}
                  {entry.note && entry.note.length > 0 && (
                    <i className="BillingPeriod__notes-icon fa fa-comment" title="Show Notes" />
                  )}
                </td>
              );
            })}
            <td className="BillingPeriodTable__total">{floatString(rowHoursTotal(row))}</td>
            {!isRowDisabled && (
              <td style={{ minWidth: 20 }}>
                <button
                  aria-label={aria.timesheet.rowDelete({
                    rowName: row.name ?? 'Unknown Project',
                    rowRole: row.role ?? 'Unknown Role',
                  })}
                  className="BillingPeriod__button BillingPeriod__button-remove"
                  onClick={() => onRemoveClick(row)}
                >
                  <i className="fa fa-times" />
                </button>
              </td>
            )}
          </TimesheetRow>
        )}
      </Draggable>
    );
  };

  const renderLoading = () => (
    <StyledBillingPeriod>
      <BillingPeriodTable>
        <thead>
          <tr className="BillingPeriodTable__header">
            <th className="BillingPeriodTable__header-label" />
            {[...Array(15)].map((_, i) => (
              <HeaderCell key={i} style={{ padding: 5 }}>
                <LoaderBlock height={32} />
              </HeaderCell>
            ))}
            <th style={{ width: 70, padding: '5px 10px 5px 5px' }}>
              <LoaderBlock height={32} />
            </th>
          </tr>
        </thead>

        <tbody>
          <TimesheetRow>
            <td colSpan="17">
              <Loader compact />
            </td>
          </TimesheetRow>
        </tbody>

        <tfoot>
          <tr className="BillingPeriodTable__footer">
            <td />
            {[...Array(15)].map((_, i) => (
              <FooterCell key={i} style={{ padding: 5 }}>
                <LoaderBlock height={32} />
              </FooterCell>
            ))}
            <td className="BillingPeriodTable__total">
              <LoaderBlock height={32} />
            </td>
            <td />
          </tr>
        </tfoot>
      </BillingPeriodTable>
    </StyledBillingPeriod>
  );

  const render = () => {
    if (loading) return renderLoading();

    const { dates = [] } = timesheetPeriod;
    const totals = dayHoursTotal(dates, timesheetRows);

    return (
      <StyledBillingPeriod>
        <BillingPeriodTable>
          <thead>
            <tr className="BillingPeriodTable__header">
              {/* empty <th> to account for drag order column */}
              {!disabled && <th />}
              <th className="BillingPeriodTable__header-label" />
              {dates
                .map(({ date, isToday }) => ({
                  date,
                  isToday,
                  dayDate: dayjs(date),
                }))
                .map(({ date, isToday, dayDate }) => (
                  <HeaderCell
                    isToday={isToday}
                    key={date}
                    aria-label={aria.timesheet.headerDate({ date: getDateString(dayDate, DATE_FORMATS.readable) })}
                  >
                    <div className="HeaderCell__day">{dayDate.format('dd')}</div>
                    <div className="HeaderCell__date">{dayDate.format('MMM D')}</div>
                  </HeaderCell>
                ))}
              <th style={{ minWidth: 20 }}>Total</th>
              {/* empty <th> to account for remove button column */}
              {!disabled && <th style={{ minWidth: 30 }} />}
            </tr>
          </thead>

          <DraggableRows
            onRowOrderChange={onRowOrderChange}
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
            onDragEnd={onDragEnd}
            rows={timesheetRows}
          >
            {provided => {
              const readableDateRange = getDateRangeString(timesheetPeriod, DATE_FORMATS.readable);

              return (
                <>
                  {dates.length > 0 && timesheetRows.map(renderRow)}
                  {provided.placeholder}

                  {/* Spacer row that contains the "add row" button */}
                  <tr>
                    <td className="BillingPeriod__spacer" colSpan={(dates.length ? dates.length : 0) + 3}>
                      {!disabled && (
                        <button
                          className="BillingPeriod__button BillingPeriod__button-add"
                          onClick={() => !disabled && onAddClick()}
                          aria-label={aria.timesheet.rowAdd({ dateRange: readableDateRange })}
                          title={aria.timesheet.rowAdd({ dateRange: readableDateRange })}
                        >
                          <i className="fa fa-plus" />
                        </button>
                      )}
                    </td>
                  </tr>
                </>
              );
            }}
          </DraggableRows>

          <tfoot>
            <tr className="BillingPeriodTable__footer">
              {/* empty <td> to account for drag order column */}
              {!disabled && <td />}
              {/* empty <F> to account for project name column */}
              <td />
              {dates.map(({ date, isToday }) => (
                <FooterCell isToday={isToday} key={date}>
                  {floatString(totals[date])}
                </FooterCell>
              ))}
              <td className="BillingPeriodTable__total">
                {floatString(objectValues(totals).reduce((sum, x) => sum + x, 0))}
              </td>
              {/* empty <td> to account for remove button column */}
              <td />
            </tr>
          </tfoot>
        </BillingPeriodTable>
        <div id="timesheet-row-aria" style={{ display: 'none' }}>
          {aria.timesheet.dragHelp()}
        </div>
      </StyledBillingPeriod>
    );
  };

  return render();
};

export default BillingPeriod;
