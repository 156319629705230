import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from 'components/Header';
import { hasAnyRole, isAdmin, isContractor, isManager } from 'modules/currentUser';

const mapState = state => ({
  isAdmin: isAdmin(state),
  isContractor: isContractor(state),
  isManager: isManager(state),
  hasAnyRole: hasAnyRole(state),
});

export default withRouter(connect(mapState, null)(Header));
