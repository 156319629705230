import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions, selectors } from 'modules/pto';

import PastPTOOptions from './PastPTOOptions';

class PastPTOOptionsContainer extends PureComponent {
  updateSingleOption = key => value => {
    this.props.updateSearch({ [key]: value });
  };

  render() {
    return <PastPTOOptions selectedYear={this.props.year} onOptionChange={this.updateSingleOption} />;
  }
}

const mapState = state => ({
  year: selectors.year(state),
});

const mapDispatch = {
  updateSearch: actions.fetchPastPto,
};

export default connect(mapState, mapDispatch)(PastPTOOptionsContainer);
