import numWords from 'number-to-words';

import Banner from 'components/Banner';
import { plural } from 'utils/common';

const PTOAdminBulkEdit = ({ count, onApprove, onReject }) => {
  const actions = [
    { action: onApprove, display: 'Approve' },
    { action: onReject, display: 'Reject' },
  ];

  return count > 0 ? (
    <Banner info actions={actions}>
      {numWords.toWords(count)} {plural(count, { 0: 'Requests', 1: 'Request' })} Selected
    </Banner>
  ) : null;
};

export default PTOAdminBulkEdit;
