import React from 'react';
import { View } from 'components/layout';
import Loader from 'components/Loader';

const LoaderView = props => {
  return (
    <View>
      <Loader {...props} />
    </View>
  );
};

export default LoaderView;
