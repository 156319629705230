import React from 'react';
import styled from 'styled-components';

import { colors } from 'theme/theme';
import PTOAdminTable from '../PTOAdminTable';
import Loader from 'components/Loader';

const Badge = styled.div`
  background: ${colors.grayTint};
  border: 1px solid ${colors.graySilver};
  border-radius: 3px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: ${colors.grayDim};
`;

const PendingPTO = ({ loading = true, pto = [] }) => {
  if (loading) return <Loader />;
  return pto.length > 0 ? <PTOAdminTable shouldFetch={false} status="pending" /> : <Badge>No pending requests</Badge>;
};

export default PendingPTO;
