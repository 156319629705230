import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';

import BillingPeriod from 'components/BillingPeriod';
import { useCommands } from 'hooks/useCommands';
import { actions as modalActions } from 'modules/modal';
import { actions as projectsActions } from 'modules/projects';
import { actions, selectors } from 'modules/timesheet';
import { nodeExistsIn } from 'utils/common';

const TIMESHEET_COMMANDS = {
  DELETE_TIMESHEET_ROW: { key: 'd', description: 'Delete Timesheet Row' },
};

const hasProject = details => details.projectId !== null || details.additionalProjectId !== null;
const hasEntries = ({ timesheetEntries }) => timesheetEntries.length > 0;

const BillingPeriodContainer = ({
  addRow,
  disabled,
  fetchAdditionalProjects,
  fetchProjects,
  fetchTimesheet,
  loading,
  removeRow,
  showModal,
  timesheet,
  updateEntry,
  updateEntryNote,
  updateRowOrder,
}) => {
  const [editing, setEditing] = useState(null);
  const [focusedEntry, setFocusedEntry] = useState({ row: null, rowContent: null, date: null });
  const [showNotes, setShowNotes] = useState(false);

  useCommands(TIMESHEET_COMMANDS, {
    DELETE_TIMESHEET_ROW: () => {
      if (!focusedEntry.row) return;
      onRemove(focusedEntry.row);
    },
  });

  useEffect(() => {
    fetchTimesheet();
    fetchProjects();
    fetchAdditionalProjects();
  }, [fetchTimesheet, fetchProjects, fetchAdditionalProjects]);

  useEffect(() => {
    const checkClickTarget = e => {
      if (!focusedEntry.row || !focusedEntry.date) {
        return;
      }

      let doBlur = true;
      const { target } = e;
      const validTargets = ['CommentBubble', 'EntryInput', 'BillingPeriod__notes-toggle'];

      for (let i = 0, len = validTargets.length; i < len; i++) {
        if (nodeExistsIn(target, validTargets[i])) {
          doBlur = false;
          break;
        }
      }

      if (doBlur) {
        handleEntryExit();
      }
    };

    document.addEventListener('click', checkClickTarget);
    return () => {
      document.removeEventListener('click', checkClickTarget);
    };
  }, [focusedEntry]);

  const onRemove = details => {
    if (!hasProject(details) || !hasEntries(details)) {
      return removeRow(details.id);
    }

    showModal('confirm', {
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this row?',
      type: 'alert',
      closeText: 'Yes, Delete',
      cancelText: 'No, Cancel',
      onClose: () => removeRow(details.id),
    });
  };

  const editProject = editing => setEditing(editing);

  const handleEntryChange = (row, date, value) => {
    updateEntry(row.id, date, value);
  };

  const handleEntryFocus = (row, date) => {
    setShowNotes(false);
    setFocusedEntry({ date, row });
  };

  const handleEntryExit = () => {
    setFocusedEntry({ date: null, row: null });
  };

  const toggleNotes = (row, date) => {
    if (showNotes) return setShowNotes(false);

    setShowNotes(true);
    setFocusedEntry({ date, row });
  };

  const addTimesheetRow = () => {
    // Temporary until we move editing to redux state possibly
    const nextId = uuid();
    setEditing(nextId);
    addRow(nextId);
  };

  return (
    <BillingPeriod
      editing={editing}
      editProject={editProject}
      focusedEntry={focusedEntry}
      handleEntryFocus={handleEntryFocus}
      handleEntryChange={handleEntryChange}
      handleEntryNoteChange={updateEntryNote}
      onRowOrderChange={updateRowOrder}
      loading={loading}
      onAddClick={addTimesheetRow}
      onRemoveClick={onRemove}
      showNotes={showNotes}
      timesheetPeriod={timesheet && timesheet.timesheetPeriod}
      timesheetRows={timesheet && timesheet.timesheetRows}
      toggleNotes={toggleNotes}
      disabled={disabled}
    />
  );
};

const mapStateToProps = state => ({
  loading: selectors.loading(state),
  timesheet: selectors.localTimesheet(state),
  disabled: selectors.disabled(state),
});

export default connect(mapStateToProps, {
  addRow: actions.addTimesheetRow,
  fetchAdditionalProjects: projectsActions.fetchAdditionalProjects,
  fetchProjects: projectsActions.fetchProjects,
  fetchTimesheet: actions.fetchTimesheet,
  removeRow: actions.removeTimesheetRow,
  saveTimesheet: actions.saveTimesheet,
  showModal: modalActions.showModal,
  updateEntry: actions.updateTimesheetEntry,
  updateEntryNote: actions.updateTimesheetEntryNote,
  updateRowOrder: actions.updateRowOrder,
  updateRowProject: actions.updateTimesheetProject,
})(BillingPeriodContainer);
