import styled from 'styled-components';

export const SiteContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 100vh;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
`;
