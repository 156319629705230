import 'lib/polyfills';

import React from 'react';
import ReactDOM from 'react-dom';

import { unregister as unregisterServiceWorker } from 'lib/registerServiceWorker';

import App from 'components/App';

import 'react-datepicker/dist/react-datepicker.css';

ReactDOM.render(<App />, document.getElementById('root'));

unregisterServiceWorker();
