import React, { useState, useEffect } from 'react';
import Select from 'components/Select';
import { fetchClients } from 'utils/api';

const ClientSelectContainer = ({ clientId, setClientId, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);

  const handleGetClients = async () => {
    try {
      setLoading(true);
      const { clients } = await fetchClients();
      setClients(clients);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetClients();
  }, []);

  const options = clients.map(({ name: label, id }) => ({ label, value: String(id) }));

  return <Select isLoading={loading} value={clientId} options={options} onChange={setClientId} isClearable {...rest} />;
};

export default ClientSelectContainer;
