import React, { useCallback, useState } from 'react';
import dayjs from 'lib/dayjs';

import CalendarCell from './CalendarCell';
import StyledCalendar, { CalendarTitle, CalendarHeaders, CalendarGrid } from './BasicCalendar.styled';
import { noop } from 'utils/common';
import { ButtonIcon } from 'components/ButtonIcon';
import { useMemo } from 'react';

const MAX_DATES = 42;

const generateCalendar = month => {
  const headers = [];
  const days = [];
  const today = dayjs();

  const start = dayjs(month)
    .startOf('month')
    .startOf('week');

  let current = dayjs(start);

  for (let i = 0; i < MAX_DATES; i++) {
    if (i < 7) headers.push(current.format('ddd'));

    days.push({
      moment: dayjs(current),
      date: current.format('MM/DD/YYYY'),
      day: current.format('ddd'),
      weekday: current.format('dddd'),
      number: current.format('D'),
      month: current.format('MMMM'),
      year: current.format('YYYY'),
      isToday: current.isSame(today, 'day'),
      isWeekend: [0, 6].includes(current.day()),
      isMWF: [1, 3, 5].includes(current.day()),
      isCurrentMonth: current.isSame(month, 'month'),
    });

    current = current.add(1, 'd');
  }

  return { headers, days, rows: Math.floor(days.length / 7) };
};

const BasicCalendar = ({ startDate, endDate, isDayHighlighted = noop }) => {
  const [month, setMonth] = useState(startDate);

  const { headers, days, rows } = useMemo(() => generateCalendar(month), [month]);

  const isNextEnabled = useMemo(() => {
    if (startDate.isSame(endDate, 'month')) return false;
    return month.isBefore(endDate) && !month.isSame(endDate, 'month');
  }, [month, startDate, endDate]);

  const isPreviousEnabled = useMemo(() => {
    if (startDate.isSame(endDate, 'month')) return false;
    return month.isAfter(startDate) && !month.isSame(startDate, 'month');
  }, [month, startDate, endDate]);

  const onNext = useCallback(() => {
    const nextMonth = dayjs(month).add(1, 'month');
    setMonth(nextMonth);
  }, [month]);

  const onPrevious = useCallback(() => {
    const nextMonth = dayjs(month).subtract(1, 'month');
    setMonth(nextMonth);
  }, [month]);

  return (
    <StyledCalendar rows={rows}>
      <CalendarTitle>
        <div className="Calendar__left">
          <ButtonIcon icon="arrow-circle-left" onClick={onPrevious} disabled={!isPreviousEnabled} transparent />
        </div>
        <div className="Calendar__title">{month.format('MMMM YYYY')}</div>
        <div className="Calendar__right">
          <ButtonIcon icon="arrow-circle-right" onClick={onNext} disabled={!isNextEnabled} transparent />
        </div>
      </CalendarTitle>
      <CalendarHeaders>
        {headers.map(header => (
          <div key={header} className="Calendar__header">
            {header}
          </div>
        ))}
      </CalendarHeaders>
      <CalendarGrid>
        {days.map(day => (
          <CalendarCell
            key={`${day.month}__${day.number}`}
            moment={day.moment}
            isHighlighted={isDayHighlighted(day)}
            isToday={day.isToday}
            isMWF={day.isMWF}
            isWeekend={day.isWeekend}
            isCurrentMonth={day.isCurrentMonth}
          >
            <div className="Calendar__details">
              <div className="Calendar__day">{day.day}</div>
              <div className="Calendar__number">{day.number}</div>
            </div>
          </CalendarCell>
        ))}
      </CalendarGrid>
    </StyledCalendar>
  );
};

export { BasicCalendar };
