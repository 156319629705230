import React from 'react';
import styled from 'styled-components';

import { colors } from 'theme/theme';

const StyledPageBlock = styled.div`
  .PageBlock__title {
    margin: 0 0 15px;

    h2 {
      font-size: 16px;
      color: ${colors.grayMortar};
    }
  }

  .PageBlock__content {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }
  }

  .PageBlock__actions {
    text-align: right;
  }
`;

const PageBlock = ({ actions, children, title }) => (
  <StyledPageBlock>
    {title && (
      <div className="PageBlock__title">
        <h2>{title}</h2>
      </div>
    )}
    <div className="PageBlock__content">{children}</div>
    {actions && <div className="PageBlock__actions">{actions}</div>}
  </StyledPageBlock>
);

export default PageBlock;
