import styled from 'styled-components';
import { colors } from 'theme/theme';

export const Filters = styled.div`
  display: flex;
  margin: 0 0 25px;

  .Filters__options {
    display: flex;
    margin-right: 25px;
    margin-top: 5px;
    flex-flow: wrap;
  }

  .Filters__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
`;

export const NoResults = styled.div`
  margin-top: 125px;
  text-align: center;
  font-size: 24px;
  color: ${colors.graySilver};
`;

export const TableContainer = styled.div`
  table {
    border: none;
  }

  .Table__actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .download-table-xls-button {
    width: 165px;
    background-color: ${colors.greenSushi};
    border: 1px solid ${colors.greenOlive};
    margin-bottom: 20px;
    color: ${colors.white};
    font-size: 16px;
    font-weight: bold;
    padding: 8px 10px;
    cursor: pointer;
  }

  .Table__comment {
    margin: 0 0 20px;
    font-size: 12px;
    font-style: italic;
  }

  .Table__scroller {
    width: 100%;
    overflow-x: auto;
    border: solid 1px ${colors.graySilver};
  }

  th,
  td {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Cell__contained {
    white-space: pre-wrap;
  }
`;
