import React, { Fragment } from 'react';

import { Filters, NoResults } from './Reports.styled';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import ClientSelect from 'containers/ClientSelectContainer';
import ProjectSelect from 'containers/ProjectSelectContainer';
import DepartmentSelect from 'containers/DepartmentSelect.container';
import InputContainer from 'components/InputContainer';
import DatePicker from 'components/DatePicker';
import Loader from 'components/Loader';
import ResultsTable from './ProjectTimeReportTable';
import EmployeeSelect from 'containers/EmployeeSelect.container';
import EngagementManagerSelect from 'containers/EngagementManagerSelect.container';

const ProjectTimeReportLayout = ({
  startDate,
  endDate,
  departmentId,
  clientId,
  projectId,
  employeeId,
  engagementManagerId,
  selectStartDate,
  selectEndDate,
  setDepartmentId,
  setClientId,
  setProjectId,
  setEmployeeId,
  setEngagementManagerId,
  setFilteredProjects,
  filteredProjects,
  report,
  runReport,
  loading,
}) => {
  let mainView = null;

  const breadcrumbs = [{ display: 'Reports', url: '/reports' }, { display: 'Project Time Report' }];

  if (loading) {
    mainView = <Loader compact />;
  } else if (report.results.length > 0) {
    mainView = <ResultsTable records={report.results} />;
  } else if (report.ranReport) {
    mainView = <NoResults>No Records Were Found</NoResults>;
  } else {
    mainView = <NoResults>Make selections and click run report</NoResults>;
  }

  return (
    <Fragment>
      <Breadcrumbs routes={breadcrumbs} />
      <Filters>
        <div className="Filters__options">
          <InputContainer>
            <h3>Start Date</h3>
            <DatePicker
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={selectStartDate}
            />
          </InputContainer>
          <InputContainer>
            <h3>End Date</h3>
            <DatePicker
              selected={endDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={selectEndDate}
            />
          </InputContainer>
          <InputContainer>
            <h3>Department</h3>
            <DepartmentSelect
              defaultValue={departmentId}
              value={departmentId ? departmentId : ''}
              onChange={setDepartmentId}
              width={200}
            />
          </InputContainer>
          <InputContainer>
            <h3>Engagement Manager</h3>
            <EngagementManagerSelect
              defaultValue=""
              value={engagementManagerId ? String(engagementManagerId) : null}
              onChange={setEngagementManagerId}
              width={220}
            />
          </InputContainer>
          <InputContainer>
            <h3>Client</h3>
            <ClientSelect
              clientId={clientId}
              setClientId={setClientId}
              width={250}
              setFilteredProjects={setFilteredProjects}
            />
          </InputContainer>
          <InputContainer>
            <h3>Project</h3>
            <ProjectSelect projectId={projectId} setProjectId={setProjectId} selectedClientId={clientId} width={250} />
          </InputContainer>
          <InputContainer>
            <h3>Employee</h3>
            <EmployeeSelect
              defaultValue=""
              value={employeeId ? String(employeeId) : null}
              onChange={setEmployeeId}
              width={220}
              projectId={projectId ? String(projectId) : null}
              filteredProjects={filteredProjects}
            />
          </InputContainer>
        </div>
        <div className="Filters__actions">
          <Button info disabled={loading} onClick={() => runReport()}>
            Run Report
          </Button>
        </div>
      </Filters>
      {mainView}
    </Fragment>
  );
};

export default ProjectTimeReportLayout;
