import classnames from 'classnames';
import PropTypes from 'prop-types';

const Icon = ({ type, className, style, color }) => (
  <i className={classnames('fa', `fa-${type}`, className)} style={{ color, ...style }} />
);

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
};

export default Icon;
