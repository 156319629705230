import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const pushRoute = (...args) => history.push(...args);
export const replaceRoute = (...args) => history.replace(...args);
export const goBack = (...args) => history.goBack(...args);

export const getApiUrl = () => {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') return 'localhost:3000';
  return hostname;
};

export const reload = () => {
  window.location.reload();
};

export const azureRedirect = admin => {
  let apiLoginUrl = `https://${getApiUrl()}/azure`;
  if (admin) apiLoginUrl += '/admin_consent';
  apiLoginUrl += '/login';

  window.location.assign(`${apiLoginUrl}?return_to=${window.location.href}`);
};
