import { createActions, handleActions } from 'redux-actions';
import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { fetchCurrentUserSuccess } from 'modules/currentUser';
import { actions as timesheetActions } from 'modules/timesheet';
import * as api from 'utils/api';

// default state
const defaultState = {
  periods: [],
  selected: null,
};

// actions
export const actions = createActions(
  'FETCH_TIMESHEET_PERIODS',
  'FETCH_TIMESHEET_PERIODS_SUCCESS',
  'SELECT_TIMESHEET_PERIOD'
);

// selectors
export const selectors = {
  periods: state => state.timesheetPeriods.periods,
  selected: state => state.timesheetPeriods.selected,
};

selectors.currentPeriod = createSelector([selectors.periods], periods => periods.find(p => p.current));

selectors.selectedPeriod = createSelector([selectors.periods, selectors.selected], (periods, selected) => {
  return periods.find(p => {
    if (selected) return p.startDate === selected;
    return p.current;
  });
});

// sagas
function* fetchTimesheetPeriodsSaga() {
  try {
    const { timesheetPeriods } = yield call(api.timesheetPeriods);

    if (timesheetPeriods) {
      const selected = timesheetPeriods.find(p => p.current)?.startDate;
      yield put(actions.fetchTimesheetPeriodsSuccess({ selected, timesheetPeriods }));
    }
  } catch (e) {
    console.log(e);
  }
}

function* selectTimesheetPeriodSaga({ payload }) {
  yield put(timesheetActions.fetchTimesheet(payload));
}

export function* timesheetPeriodsSaga() {
  yield take(fetchCurrentUserSuccess);
  yield all([
    takeLatest(actions.fetchTimesheetPeriods, fetchTimesheetPeriodsSaga),
    takeLatest(actions.selectTimesheetPeriod, selectTimesheetPeriodSaga),
    put(actions.fetchTimesheetPeriods()),
  ]);
}

// reducer
export default handleActions(
  {
    [timesheetActions.fetchTimesheet](state, { payload }) {
      const selected = payload?.startDate ?? null;
      return { ...state, selected };
    },
    [actions.fetchTimesheetPeriodsSuccess](state, { payload }) {
      const { selected, timesheetPeriods: periods } = payload;
      return { ...state, periods, selected };
    },
    [actions.selectTimesheetPeriod](state, { payload = {} }) {
      return { ...state, selected: payload.startDate };
    },
  },
  defaultState
);
