import React from 'react';
import styled from 'styled-components';

import { colors } from 'theme/theme';

const StyledSearchInput = styled.div`
  padding: 5px;

  .SearchInput__input {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    font-size: 14px;
    height: 30px;
    padding: 0 5px;
    border-radius: 3px;
    background-color: ${colors.white};
    border: 1px solid ${colors.grayLight};
    outline: none;
  }
`;

const SearchInput = ({ onChange, value, ...props }) => (
  <StyledSearchInput>
    <input
      className="SearchInput__input"
      aria-label="Project Selector - Search by Client, Project, or Role"
      autoFocus
      placeholder="Search by Client, Project, or Role"
      type="text"
      {...props}
      onChange={e => onChange(e.target.value)}
      value={value}
    />
  </StyledSearchInput>
);

export default SearchInput;
