import React from 'react';

import { Container } from 'components/layout';
import StyledSubheader from 'components/SubHeader/SubHeader.styled.js';
import SubHeaderLink from 'components/SubHeader/SubHeaderLink';

const SubHeader = ({ items, children }) => (
  <StyledSubheader>
    <Container className="SubHeader__container">
      <div className="SubHeader__items">
        {items.map((item, i) => (
          <SubHeaderLink key={i} to={item.to} disabled={item.disabled}>
            {item.display}
          </SubHeaderLink>
        ))}
      </div>
      {children && <div className="SubHeader__details">{children}</div>}
    </Container>
  </StyledSubheader>
);

export default SubHeader;
