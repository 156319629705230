import { PureComponent } from 'react';
import { connect } from 'react-redux';

import NotSubmittedReport from 'components/Reports/NotSubmittedReport';
import { actions, selectors } from 'modules/reports';
import { selectors as periodSelectors } from 'modules/timesheetPeriods';

class NotSubmittedReportContainer extends PureComponent {
  componentDidMount() {
    this.props.fetchNotSubmittedReport();
  }

  render() {
    const { loading, ranReport } = this.props;
    return <NotSubmittedReport {...this.props} {...this.state} loading={loading} ranReport={ranReport} />;
  }
}

const mapStateToProps = state => {
  return {
    loading: selectors.loading(state),
    report: selectors.notSubmitted(state),
    timesheetPeriods: periodSelectors.periods(state),
  };
};

export default connect(mapStateToProps, { fetchNotSubmittedReport: actions.fetchNotSubmittedReport })(
  NotSubmittedReportContainer
);
