import { colors } from 'theme/theme';
import { TIMESHEET_STATUSES } from 'lib/constants';

const statusColor = status => {
  const statusColors = {
    [TIMESHEET_STATUSES.UNSUBMITTED]: colors.grayNight,
    [TIMESHEET_STATUSES.PENDING]: colors.orangeButtercup,
    [TIMESHEET_STATUSES.APPROVED]: colors.greenSushi,
    [TIMESHEET_STATUSES.UNAPPROVED]: colors.redBrick,
    [TIMESHEET_STATUSES.REJECTED]: colors.redBrick,
  };
  return statusColors[status] || colors.grayNight;
};

export default statusColor;
