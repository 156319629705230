import { all, takeLatest, call, put } from 'redux-saga/effects';
import { createActions, handleActions } from 'redux-actions';

import * as api from 'utils/api';

const defaultState = {
  holidays: [],
  teamMembers: [],
};

// Selectors
export const selectors = {
  holidays: state => state.reference.holidays,
  teamMembers: state => state.reference.teamMembers,
  teamMember: state => id => state.reference.teamMembers.find(member => member.id === Number(id)),
};

// actions
export const actions = createActions(
  'FETCH_HOLIDAYS',
  'FETCH_HOLIDAYS_SUCCESS',
  'FETCH_HOLIDAYS_FAILURE',
  'FETCH_TEAM_MEMBERS',
  'FETCH_TEAM_MEMBERS_SUCCESS',
  'FETCH_TEAM_MEMBERS_FAILURE'
);

// sagas
function* fetchHolidaysSaga({ payload }) {
  try {
    const { holidays } = yield call(api.fetchHolidays);
    yield put(actions.fetchHolidaysSuccess(holidays));
  } catch (e) {
    console.log(e);
  }
}
function* fetchTeamMembersSaga({ payload }) {
  try {
    const { teamMembers } = yield call(api.fetchTeamMembers);
    const mapped = teamMembers.map(member => ({
      ...member,
      fullName: `${member.firstName} ${member.lastName}`,
    }));

    yield put(actions.fetchTeamMembersSuccess(mapped));
  } catch (e) {
    console.log(e);
  }
}

export function* referenceSaga() {
  yield all([
    takeLatest(actions.fetchHolidays, fetchHolidaysSaga),
    takeLatest(actions.fetchTeamMembers, fetchTeamMembersSaga),
  ]);
}

// reducer
export default handleActions(
  {
    [actions.fetchHolidaysSuccess](state, { payload: holidays }) {
      return { ...state, holidays };
    },
    [actions.fetchTeamMembersSuccess](state, { payload: teamMembers }) {
      return { ...state, teamMembers };
    },
  },
  defaultState
);
