import omit from 'lodash/omit';

import { push } from 'connected-react-router';
import { combineActions, createActions, handleActions } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as api from 'utils/api';
import { sortReadOnlyTimesheetRows } from 'utils/sortReadOnlyTimesheetRows';

// default state
const defaultState = {
  timesheets: [],
  pendingTimesheets: [],
  timesheetsLoading: true,
  memberTimesheet: null,
  memberTimesheetLoading: true,
  memberTimesheetHistory: [],
  memberTimesheetHistoryLoading: false,
};

// actions
export const actions = createActions(
  'FETCH_TEAM_TIMESHEETS',
  'TEAM_TIMESHEETS_RESPONSE',
  'FETCH_TEAM_PENDING_TIMESHEETS',
  'TEAM_PENDING_TIMESHEETS_RESPONSE',
  'FETCH_MEMBER_TIMESHEET',
  'MEMBER_TIMESHEET_RESPONSE',
  'FETCH_MEMBER_TIMESHEET_HISTORY',
  'FETCH_MEMBER_TIMESHEET_HISTORY_RESPONSE',
  'TEAM_SUBMIT_TIMESHEET',
  'TEAM_SUBMIT_TIMESHEET_RESPONSE',
  'TEAM_APPROVE_TIMESHEET',
  'TEAM_APPROVE_TIMESHEET_RESPONSE',
  'TEAM_REJECT_TIMESHEET',
  'TEAM_REJECT_TIMESHEET_RESPONSE',
  'TEAM_UNAPPROVE_TIMESHEET',
  'TEAM_UNAPPROVE_TIMESHEET_RESPONSE'
);

// selectors
export const selectors = {
  timesheets: state => state.team.timesheets,
  timesheetsLoading: state => state.team.timesheetsLoading,
  pendingTimesheets: state => state.team.pendingTimesheets,
  memberTimesheet: state => state.team.memberTimesheet,
  memberTimesheetLoading: state => state.team.memberTimesheetLoading,
  memberTimesheetHistory: state => state.team.memberTimesheetHistory,
  memberTimesheetHistoryLoading: state => state.team.memberTimesheetHistoryLoading,
};

selectors.memberTimesheetRows = createSelector([selectors.memberTimesheet], timesheet => {
  if (!timesheet || timesheet.timesheetRows.length <= 0) return [];

  const sortedRows = timesheet.timesheetRows.sort((a, b) => {
    const nameCompare = a.name ? a.name.localeCompare(b.name) : 0;
    const projectIdCompare = a.projectId ? a.projectId.localeCompare(b.projectId) : 0;
    const roleCompare = a.role ? a.role.localeCompare(b.role) : 0;
    return nameCompare || projectIdCompare || roleCompare || a.id - b.id;
  });
  return sortReadOnlyTimesheetRows(sortedRows);
});

// sagas
function* fetchTeamTimesheetsSaga({ payload }) {
  try {
    const { startDate, approverId } = payload;
    const { timesheets } = yield call(api.fetchTeamTimesheets, { startDate, approverId });

    if (timesheets) {
      yield put(actions.teamTimesheetsResponse(timesheets));
    }
  } catch (e) {
    console.log(e);
  }
}

function* fetchTeamPendingTimesheetsSaga() {
  try {
    const { timesheets } = yield call(api.fetchTeamPendingTimesheets);

    if (timesheets) {
      yield put(actions.teamPendingTimesheetsResponse(timesheets));
    }
  } catch (e) {
    console.log(e);
  }
}

function* fetchTimesheetSaga({ payload: id }) {
  try {
    const { timesheet } = yield call(api.fetchMemberTimesheet, id);

    if (timesheet) {
      yield put(actions.memberTimesheetResponse(timesheet));
    } else {
      const e = new Error('Timesheet failed to load.');
      yield put(actions.memberTimesheetResponse(e));
    }
  } catch (e) {
    yield put(actions.memberTimesheetResponse(e));
  }
}

const removeTempIds = rows => rows.map(r => (r.isNew ? omit(r, 'id') : r));

function* fetchTimesheetHistory({ payload: id }) {
  try {
    const { timesheetStatusRevisions } = yield call(api.fetchMemberTimesheetHistory, id);

    if (timesheetStatusRevisions) {
      yield put(actions.fetchMemberTimesheetHistoryResponse(timesheetStatusRevisions));
    } else {
      const e = new Error('Timesheet history failed to load.');
      yield put(actions.fetchMemberTimesheetHistoryResponse(e));
    }
  } catch (e) {
    yield put(actions.fetchMemberTimesheetHistoryResponse(e));
  }
}

function* submitTimesheetSaga() {
  const { id, timesheetRows } = yield select(selectors.memberTimesheet);

  try {
    const { timesheet } = yield call(api.teamSubmitTimesheet, id, { timesheetRows: removeTempIds(timesheetRows) });

    if (timesheet) {
      yield put(actions.teamSubmitTimesheetResponse(timesheet));
      const search = yield select(state => state.router.location.search);
      yield put(push({ pathname: '/team/timesheets', search }));
    } else {
      const e = new Error('Submit timesheet failed');
      yield put(actions.teamSubmitTimesheetResponse(e));
    }
  } catch (e) {
    yield put(actions.teamSubmitTimesheetResponse(e));
  }
}

function* approveTimesheetSaga() {
  const { id } = yield select(selectors.memberTimesheet);

  try {
    const { timesheet } = yield call(api.teamApproveTimesheet, id);

    if (timesheet) {
      yield put(actions.teamApproveTimesheetResponse(timesheet));
      const search = yield select(state => state.router.location.search);
      const path = search === '' ? { pathname: '/team/pending' } : { pathname: '/team/timesheets', search };
      yield put(push(path));
    } else {
      const e = new Error('Approve timesheet failed');
      yield put(actions.teamApproveTimesheetResponse(e));
    }
  } catch (e) {
    yield put(actions.teamApproveTimesheetResponse(e));
  }
}

function* rejectTimesheetSaga(params) {
  const { id } = yield select(selectors.memberTimesheet);
  const { note } = params.payload;

  try {
    const { timesheet } = yield call(api.teamRejectTimesheet, id, note);

    if (timesheet) {
      yield put(actions.teamRejectTimesheetResponse(timesheet));
      const search = yield select(state => state.router.location.search);
      const path = search === '' ? { pathname: '/team/pending' } : { pathname: '/team/timesheets', search };
      yield put(push(path));
    } else {
      const e = new Error('Reject timesheet failed.');
      throw put(actions.teamRejectTimesheetResponse(e));
    }
  } catch (e) {
    yield put(actions.teamRejectTimesheetResponse(e));
  }
}

function* unapproveTimesheetSaga() {
  const { id } = yield select(selectors.memberTimesheet);

  try {
    const { timesheet } = yield call(api.teamUnapproveTimesheet, id);

    if (timesheet) {
      yield put(actions.teamUnapproveTimesheetResponse(timesheet));
      const search = yield select(state => state.router.location.search);
      yield put(push({ pathname: '/team/timesheets', search }));
    } else {
      const e = new Error('Unapprove timesheet failed.');
      throw put(actions.teamUnapproveTimesheetResponse(e));
    }
  } catch (e) {
    yield put(actions.teamUnapproveTimesheetResponse(e));
  }
}

export function* teamSaga() {
  yield all([
    takeLatest(actions.fetchTeamTimesheets, fetchTeamTimesheetsSaga),
    takeLatest(actions.fetchTeamPendingTimesheets, fetchTeamPendingTimesheetsSaga),
    takeLatest(actions.fetchMemberTimesheet, fetchTimesheetSaga),
    takeLatest(actions.fetchMemberTimesheetHistory, fetchTimesheetHistory),
    takeLatest(actions.teamSubmitTimesheet, submitTimesheetSaga),
    takeLatest(actions.teamApproveTimesheet, approveTimesheetSaga),
    takeLatest(actions.teamRejectTimesheet, rejectTimesheetSaga),
    takeLatest(actions.teamUnapproveTimesheet, unapproveTimesheetSaga),
  ]);
}

// reducer
export default handleActions(
  {
    [actions.fetchTeamTimesheets](state) {
      return { ...state, timesheetsLoading: true, timesheets: defaultState.timesheets };
    },
    [actions.fetchTeamPendingTimesheets](state) {
      return { ...state, timesheetsLoading: true, pendingTimesheets: defaultState.pendingTimesheets };
    },
    [combineActions(
      actions.teamSubmitTimesheet,
      actions.teamApproveTimesheet,
      actions.teamUnapproveTimesheet,
      actions.teamRejectTimesheet
    )](state) {
      return { ...state, memberTimesheetLoading: true };
    },
    [actions.fetchMemberTimesheet](state) {
      return {
        ...state,
        memberTimesheet: defaultState.memberTimesheet,
        memberTimesheetLoading: true,
      };
    },
    [actions.teamTimesheetsResponse](state, { payload: timesheets }) {
      return { ...state, timesheetsLoading: false, timesheets };
    },
    [actions.fetchMemberTimesheetHistory](state) {
      return {
        ...state,
        memberTimesheetHistory: defaultState.memberTimesheetHistory,
        memberTimesheetHistoryLoading: true,
      };
    },
    [actions.fetchMemberTimesheetHistoryResponse](state, { payload: history }) {
      return {
        ...state,
        memberTimesheetHistory: history,
        memberTimesheetHistoryLoading: false,
      };
    },
    [actions.teamPendingTimesheetsResponse](state, { payload: timesheets }) {
      return { ...state, timesheetsLoading: false, pendingTimesheets: timesheets };
    },
    [combineActions(
      actions.memberTimesheetResponse,
      actions.teamSubmitTimesheetResponse,
      actions.teamApproveTimesheetResponse,
      actions.teamRejectTimesheetResponse,
      actions.teamUnapproveTimesheetResponse
    )]: {
      next(state, { payload }) {
        const memberTimesheet = { ...payload };
        return { ...state, memberTimesheet, memberTimesheetLoading: false };
      },
      throw(state) {
        return { ...state, memberTimesheetLoading: false };
      },
    },
  },
  defaultState
);
