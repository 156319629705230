import { Accessible } from 'components/Accessible';
import styled from 'styled-components';
import { colors } from 'theme/theme';

export const PeriodSelectorContainer = styled.div`
  display: inline-flex;
  align-items: flex-end;

  .PeriodSelectorContainer__current {
    margin-left: 3px;
  }
`;

export const PeriodSelector = styled.div`
  display: flex;
  align-items: center;
`;

export const CurrentPeriod = styled(Accessible)`
  flex: 1 1 auto;
  white-space: nowrap;
`;

export const Arrow = styled.button`
  all: unset;
  display: inline-flex;
  flex: 0 0 auto;
  align-self: stretch;
  justify-content: center;
  color: ${props => (!props.disabled ? colors.grayNight : colors.grayDark)};
  cursor: ${props => (!props.disabled ? 'pointer' : 'not-allowed')};
  padding: 6px 10px;
  border: 2px solid transparent;

  &:first-child {
    margin-right: 4px;
  }

  &:last-child {
    margin-left: 4px;
  }
`;

export const CurrentLink = styled(Accessible)`
  color: ${colors.grayNight};
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.5s;

  &:hover {
    color: ${colors.grayCharcoal};
  }
`;

export default PeriodSelectorContainer;
