import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { actions } from 'modules/reference';

class ReferenceData extends PureComponent {
  componentDidMount() {
    this.props.fetchHolidays();
    this.props.fetchTeamMembers();
  }

  render() {
    return this.props.children;
  }
}

const connected = connect(null, {
  fetchHolidays: actions.fetchHolidays,
  fetchTeamMembers: actions.fetchTeamMembers,
})(ReferenceData);

export default withRouter(connected);
