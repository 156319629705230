import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { aria } from 'constants/aria';

export const StyledButton = styled(Button)`
  font-weight: normal;

  & > i {
    font-size: 16px;
    margin-right: 6px;
  }
`;

const icons = {
  approved: <i className="fa fa-check" />,
  pending: <i className="fa fa-hourglass" />,
  unsubmitted: <i className="fa fa-warning" />,
};

const texts = {
  approved: 'APPROVED',
  pending: 'SUBMITTED, PENDING APPROVAL',
  unsubmitted: 'SUBMIT FOR APPROVAL',
};

const labels = {
  pending: aria.timesheet.submitPending,
  unsubmitted: aria.timesheet.submitUnsubmitted,
};

const SubmitButton = ({ approved, disabled, status, totalHours, onSubmit }) => {
  const icon = icons[status] || icons.unsubmitted;
  const text = texts[status] || texts.unsubmitted;
  const label = labels[status] || labels.unsubmitted;
  const labelAria = label({ totalHours });

  return (
    <StyledButton onClick={approved ? null : onSubmit} disabled={approved || disabled} aria-label={labelAria}>
      {icon} {text}
    </StyledButton>
  );
};

export default SubmitButton;
