import { useMemo, useState } from 'react';
import { connect } from 'react-redux';

import PeriodSelector from 'components/PeriodSelector';
import { actions } from 'modules/reports';
import { selectors as periodSelectors } from 'modules/timesheetPeriods';

const PeriodSelectorContainer = props => {
  const [userSelectedPeriod, setUserSelectedPeriod] = useState(null);

  const handlePeriodSelect = period => {
    setUserSelectedPeriod(period);
    props.fetchNotSubmittedReport(period.startDate);
  };

  const selectedPeriod = useMemo(() => {
    const foundPeriod = props.timesheetPeriods.find(period => period.startDate === userSelectedPeriod?.startDate);
    return foundPeriod ?? props.currentPeriod;
  }, [userSelectedPeriod, props.timesheetPeriods, props.currentPeriod]);

  return <PeriodSelector {...props} selectedPeriod={selectedPeriod} onPeriodSelect={handlePeriodSelect} />;
};

const mapStateToProps = state => ({
  timesheetPeriods: periodSelectors.periods(state),
  currentPeriod: periodSelectors.currentPeriod(state),
});

export default connect(mapStateToProps, { fetchNotSubmittedReport: actions.fetchNotSubmittedReport })(
  PeriodSelectorContainer
);
