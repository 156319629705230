import React from 'react';
import styled from 'styled-components';

import MemberTimesheet from 'containers/MemberTimesheetContainer';

const Layout = styled.div`
  margin-top: 30px;
`;

const TimesheetLayout = ({ match }) => (
  <Layout>
    <MemberTimesheet id={parseInt(match.params.id, 10)} />
  </Layout>
);

export default TimesheetLayout;
