import dayjs from 'lib/dayjs';
import packageJson from '../../package.json';

import { DATE_FORMAT, DATE_FORMAT_READABLE } from 'lib/constants';

export const arrayToOptions = (arr = []) =>
  arr.map(item => ({
    label: `${item}`,
    value: item,
  }));

export const objectValues = obj => Object.keys(obj).map(k => obj[k]);

export const noop = () => {};
export const plural = (count, options = {}) => options[count] || options[0];

export const getDisplayName = Component => Component.displayName || Component.name || 'Component';

const usdFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
});

export const toUsd = number => usdFormatter.format(number);

export const isToday = (date, format = DATE_FORMAT) => {
  const currentDate = dayjs(date, format);
  return dayjs().isSame(currentDate, 'day');
};

export const isWeekend = date => {
  const weekDay = dayjs(date).day();
  return weekDay === 0 || weekDay === 6; // 0 - Sunday, 6 - Saturday
};

export const isWeekday = date => {
  const weekDay = dayjs(date).day();
  return weekDay !== 0 && weekDay !== 6;
};

export const parsedFloat = (input = 0, precision = 2) => {
  const number = parseFloat(input);
  const parsed = +(Math.round(number + `e+${precision}`) + `e-${precision}`);
  return isNaN(parsed) ? 0 : parsed;
};

// returns any number as a string with 2 decimal places (or 0)
export const floatString = num => {
  const ret = parsedFloat(num, 2);
  return `${ret}`;
};

// Given a PTO Type and date strings, give clean format back
export const renderTimeFrame = (type, startDate, endDate) => {
  const dayStart = dayjs(startDate, DATE_FORMAT);
  const dayEnd = endDate ? dayjs(endDate, DATE_FORMAT) : null;
  const dayStartFormat = dayStart.format(DATE_FORMAT_READABLE);
  const dayEndFormat = dayEnd ? dayEnd.format(DATE_FORMAT_READABLE) : null;

  if (type === 'partial_day') {
    return `${dayStartFormat}`;
  } else {
    return dayStart.isSame(dayEnd, 'day') ? `${dayStartFormat}` : `${dayStartFormat} - ${dayEndFormat}`;
  }
};

export const nodeExistsIn = (current, validClass) => {
  while (current) {
    if (current.className && current.className.includes(validClass)) {
      return true;
    }
    current = current.parentNode;
  }

  return false;
};

export const APP_NAME = packageJson.displayName ?? '';
export const APP_VERSION = packageJson.version ?? '';
export const IS_DEV = process.env.REACT_APP_ENV === 'development';
export const IS_PROD = process.env.REACT_APP_ENV === 'production';
