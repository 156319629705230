import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SaveButton from 'components/SaveButton';
import { actions, selectors } from 'modules/timesheet';
import { selectors as periodSelectors } from 'modules/timesheetPeriods';

class SaveButtonContainer extends PureComponent {
  onSave = () => {
    const { saveTimesheet } = this.props;
    saveTimesheet();
  };

  render() {
    return <SaveButton {...this.props} onSave={this.onSave} />;
  }
}

const mapState = state => {
  return {
    currentPeriod: periodSelectors.selectedPeriod(state),
    disabled: !selectors.saveable(state),
  };
};

const mapDispatch = {
  saveTimesheet: actions.saveTimesheet,
};

export default connect(mapState, mapDispatch)(SaveButtonContainer);
