import dayjs from 'lib/dayjs';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import AllocationReport from 'components/Reports/AllocationReport';
import { DATE_FORMAT } from 'lib/constants';
import { actions, selectors } from 'modules/reports';

class AllocationReportContainer extends PureComponent {
  state = {
    startDate: dayjs().startOf('month'),
    endDate: dayjs().endOf('month'),
  };

  selectStartDate = date => this.setState(() => ({ startDate: date }));
  selectEndDate = date => this.setState(() => ({ endDate: date }));

  runReport = () => {
    const { startDate, endDate } = this.state;
    this.props.fetchAllocationReport(startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT));
  };

  render() {
    const { loading } = this.props;

    return (
      <AllocationReport
        {...this.props}
        {...this.state}
        loading={loading}
        selectStartDate={this.selectStartDate}
        selectEndDate={this.selectEndDate}
        runReport={this.runReport}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: selectors.loading(state),
    report: selectors.allocation(state),
  };
};

export default connect(mapStateToProps, { fetchAllocationReport: actions.fetchAllocationReport })(
  AllocationReportContainer
);
