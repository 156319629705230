import styled from 'styled-components';

import { colors } from 'theme/theme';
import { TIMESHEET_STATUSES } from 'lib/constants';
import { Accessible } from './Accessible';

const { PENDING, APPROVED, REJECTED, UNAPPROVED } = TIMESHEET_STATUSES;

const StatusBadge = styled(Accessible)`
  border: 1px solid transparent;
  border-radius: 3px;
  text-align: center;
  text-transform: capitalize;
  color: ${colors.grayCharcoal};
  padding: 0 15px;
  height: 35px;
  line-height: 35px;

  ${props => props.status === APPROVED && `background-color: ${colors.greenMadang}`};
  ${props => props.status === APPROVED && `border-color: ${colors.greenNorway}`};

  ${props => props.status === PENDING && `background-color: ${colors.yellowDandelion}`};
  ${props => props.status === PENDING && `border-color: ${colors.yellowPutty}`};

  ${props => [UNAPPROVED, REJECTED].includes(props.status) && `background-color: ${colors.redOrchid}`};
  ${props => [UNAPPROVED, REJECTED].includes(props.status) && `border-color: ${colors.redTurkish}`};
`;

StatusBadge.defaultProps = {
  block: true,
};

export default StatusBadge;
