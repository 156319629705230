import dayjs from 'lib/dayjs';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import GeneralTimeReport from 'components/Reports/GeneralTimeReport';
import { DATE_FORMAT } from 'lib/constants';
import { actions, selectors } from 'modules/reports';

const currentDate = new Date();

class GeneralTimeReportContainer extends PureComponent {
  state = {
    startDate: dayjs(currentDate),
    endDate: dayjs(currentDate),
    projectId: '',
  };

  selectStartDate = date => {
    this.setState(() => ({ startDate: date }));
  };

  selectEndDate = date => this.setState(() => ({ endDate: date }));

  setProjectId = id => this.setState(() => ({ projectId: String(id) }));

  runReport = () => {
    const { startDate, endDate, projectId } = this.state;
    this.props.fetchGeneralTimeReport(startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT), projectId);
  };

  render() {
    const { loading } = this.props;

    return (
      <GeneralTimeReport
        {...this.props}
        {...this.state}
        loading={loading}
        selectStartDate={this.selectStartDate}
        selectEndDate={this.selectEndDate}
        setProjectId={this.setProjectId}
        runReport={this.runReport}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: selectors.loading(state),
    report: selectors.generalTime(state),
  };
};

export default connect(mapStateToProps, { fetchGeneralTimeReport: actions.fetchGeneralTimeReport })(
  GeneralTimeReportContainer
);
