import dayjs from 'lib/dayjs';
import noop from 'lodash/noop';
import isUndefined from 'lodash/isUndefined';

const validatorMethods = {
  date: string => !isUndefined(string) && dayjs(string).isValid(),
  exists: string => !isUndefined(string) && `${string}`.length > 0,
  minlength: (string, length) => !isUndefined(string) && `${string}`.length >= length,
  maxlength: (string, length) => !isUndefined(string) && `${string}`.length <= length,
  number: string => !isUndefined(string) && !isNaN(parseFloat(string)),
};

const validateItem = (obj, key, validators) => {
  const validFields = validators.filter(v => {
    const [type, params] = v.split(':');
    const validator = validatorMethods[type] || noop;
    return validator(obj[key], params);
  });

  return validFields.length === validators.length;
};

export const checkValidity = (obj, validations) => {
  return (
    validations.filter(({ key, validate }) => {
      return validateItem(obj, key, validate);
    }).length === validations.length
  );
};
