import React, { Fragment } from 'react';

import { NoResults } from './Reports.styled';
import Loader from 'components/Loader';
import Breadcrumbs from 'components/Breadcrumbs';
import ResultsTable from './NotSubmittedReportTable';

import PeriodSelector from 'containers/reports/PeriodSelectorContainer';

const NotSubmittedReportLayout = ({ report, timesheetPeriods, loading }) => {
  let mainView = null;

  const breadcrumbs = [{ display: 'Reports', url: '/reports' }, { display: 'Timesheet Not Submitted Report' }];

  if (loading) {
    mainView = <Loader compact />;
  } else if (report.results.length > 0) {
    mainView = <ResultsTable records={report.results} />;
  } else if (report.ranReport) {
    mainView = <NoResults>No Records Were Found</NoResults>;
  } else {
    mainView = <NoResults>Make selections and click run report</NoResults>;
  }

  return (
    <Fragment>
      <Breadcrumbs routes={breadcrumbs} />
      <div className="selector">
        <PeriodSelector timesheetPeriods={timesheetPeriods} />
      </div>
      {mainView}
    </Fragment>
  );
};

export default NotSubmittedReportLayout;
