import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const DraggableRows = ({ onRowOrderChange, children, onDragEnd, rows, ...rest }) => {
  const handleDragEnd = (result, provided) => {
    if (onDragEnd) onDragEnd(result, provided);

    if (!result.destination) return;

    const destinationRow = rows[result.destination.index];
    if (destinationRow.readOnly) return;

    if (result.destination.index === result.source.index) return;

    onRowOrderChange({ fromIndex: result.source.index, toIndex: result.destination.index });
  };

  return (
    <DragDropContext {...rest} onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            {children(provided)}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableRows;
