import { Accessible } from 'components/Accessible';
import { aria } from 'constants/aria';
import React from 'react';
import injectSheet from 'react-jss';
import RetinaImage from 'react-retina-image';

import styles from './Login.styles';

const Login = ({ classes, handleSignIn }) => (
  <div className={classes.loginWrapper}>
    <div className={classes.Login}>
      <div className={classes.loginTitle}>
        <RetinaImage src="/images/logo-login.png" alt="Moser Consulting | Time Tracking" />
      </div>
      <Accessible
        autoFocus
        className={classes.azureLogin}
        aria-label={aria.global.loginButtonO365()}
        onClick={handleSignIn}
      >
        <RetinaImage src="/images/office-365-logo.png" />
        <span>Sign in with Office 365</span>
      </Accessible>
    </div>
  </div>
);

export default injectSheet(styles)(Login);
