import { View } from 'components/layout';
import styled from 'styled-components';
import { ErrorMessage } from './ErrorMessage';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const CriticalErrorView = () => {
  return (
    <StyledContainer>
      <View fab={null}>
        <ErrorMessage />
      </View>
    </StyledContainer>
  );
};
