import { colors, formGroup, formLabel, formControls } from 'theme/theme';

export default {
  loginWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: colors.grayHoki,
  },
  loginTitle: {
    fontSize: '0',
    maxWidth: '100%',
    margin: '0 0 25px',
  },
  formGroup,
  formLabel,
  formControls,
  success: {
    textAlign: 'center',
    color: colors.greenSushi,
  },
  error: {
    textAlign: 'center',
    color: colors.redBrick,
  },
  azureLogin: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    border: '1px solid rgba(255,255,255,.54)',
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.5)',
    borderRadius: '3px',
    padding: '8px 0',
    cursor: 'pointer',
    width: '300px',
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: 0.8,
    },
    '& img': {
      width: '28px',
      height: '34px',
      verticalAlign: 'middle',
    },
    '& span': {
      marginLeft: '20px',
      color: colors.grayCharcoal,
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '500',
    },
  },
};
