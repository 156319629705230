import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { fetchCodeAlerts, updateResource } from 'utils/api';
import Banner from 'components/Banner';
import { aria } from 'constants/aria';

const AlertsContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledBanner = styled(Banner)`
  min-height: 24px;
  font-size: 12px;
  margin: 0 0 10px;
  border-radius: 2px;
  text-transform: none;
  line-height: 1;
`;

const CodeAlertContainer = () => {
  const [codeAlerts, setCodeAlerts] = useState([]);

  const handleGetCodeAlerts = async () => {
    try {
      const { codeAlerts } = await fetchCodeAlerts();
      setCodeAlerts(codeAlerts);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateCodeAlert = async ({ id, spudUserId }) => {
    await updateResource(id, { code_viewed: true, spud_user_id: spudUserId });

    return handleGetCodeAlerts();
  };

  useEffect(() => {
    handleGetCodeAlerts();
  }, []);

  const renderCodeAlerts = codeAlert => {
    const projectId = codeAlert.projectId ? `[${codeAlert.projectId}]` : '';
    const alertMessage = `${projectId} ${codeAlert.name || ''} ${codeAlert.role || ''} is now available for use.`;

    return (
      <StyledBanner
        key={codeAlert.id}
        infoAlternate
        enableCloseAction
        aria-live="polite"
        aria-label={aria.timesheet.newTimeCodeAlert({
          projectId: codeAlert.projectId || '',
          projectName: codeAlert.name || '',
        })}
        handleClose={() => handleUpdateCodeAlert(codeAlert)}
      >
        <p style={{ margin: 0 }}>{alertMessage}</p>
      </StyledBanner>
    );
  };

  return <AlertsContainer>{codeAlerts.map(codeAlert => renderCodeAlerts(codeAlert))}</AlertsContainer>;
};

export default CodeAlertContainer;
