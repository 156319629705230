import React, { Component } from 'react';
import uniqBy from 'lodash/uniqBy';

import Select from 'components/Select';
import { fetchUsers, fetchResources } from '../utils/api';

const buildOptions = options => options.map(({ name: label, id }) => ({ label, value: String(id) }));
const buildResourceOptions = options =>
  options.map(({ name: label, spudUserId }) => ({ label, value: String(spudUserId) }));

export default class EmployeeSelectContainer extends Component {
  state = { options: [], resources: [], resourceOptions: [] };

  componentDidMount() {
    this.fetch();

    if (this.props.onChange) {
      this.props.onChange(this.props.value || this.props.defaultValue);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.projectId !== nextProps.projectId) {
      const filterEmployees = this.state.resources.filter(e => e.engagementId === Number(nextProps.projectId));
      this.setState({ resourceOptions: buildResourceOptions(uniqBy(filterEmployees, 'spudUserId')) });
    }

    if (this.props.filteredProjects !== nextProps.filteredProjects) {
      const filteredProjects = nextProps.filteredProjects.map(p => p.value);
      const filteredEmployees = this.state.resources.filter(e => filteredProjects.includes(String(e.engagementId)));
      this.setState({ resourceOptions: buildResourceOptions(uniqBy(filteredEmployees, 'spudUserId')) });
    }
  }

  fetch = async () => {
    const { employees } = await fetchUsers();
    const { resources } = await fetchResources();
    this.setState({ options: buildOptions(employees), resources });
  };

  getChildProps() {
    return { ...this.props, ...this.state };
  }

  render() {
    return (
      <Select
        {...this.getChildProps()}
        options={this.state.resourceOptions.length > 0 ? this.state.resourceOptions : this.state.options}
        isClearable
      />
    );
  }
}
