import styled from 'styled-components';

export const Comment = styled.div`
  font-size: 14px;
  margin: 3px 0 0;
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 20px 25px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 0 25px;

  &:last-child {
    margin: 0;
  }
`;

export const Stat = styled.div`
  display: flex;
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }

  div:first-child {
    margin-right: 15px;
  }

  div:last-child {
    margin-left: auto;
  }
`;
