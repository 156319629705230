import React, { useCallback, useEffect, useMemo, useState } from 'react';
import noop from 'lodash/noop';
import styled from 'styled-components';

import SearchInput from './SearchInput';
import SelectorPane from './SelectorPane';
import { useSelector } from 'react-redux';
import { ADDITIONAL_PROJECTS_ID, selectors } from 'modules/projects';
import { SearchPane } from './SearchPane';
import { find } from 'lodash';
import { caseInsensitiveIncludes } from 'utils/string';

const StyledProjectSelector = styled.div`
  position: relative;
  height: 100%;
  z-index: 8;
`;

const getMatches = (clients, queryParts) => {
  return queryParts.reduce((matchedResults, queryPart) => {
    return matchedResults.filter(result => {
      return caseInsensitiveIncludes(result.searchString, queryPart);
    });
  }, clients);
};

const ProjectSelector = ({ projectRow, onChange = noop, onClose = noop }) => {
  const [query, setQuery] = useState('');
  const options = useSelector(selectors.clients);

  const trimmedQuery = query.trim();
  const isSearching = trimmedQuery.length > 0;

  const matchedResults = useMemo(() => {
    if (!trimmedQuery.length) return options;

    const queryParts = trimmedQuery.toLowerCase().split(' ');
    return getMatches(options, queryParts);
  }, [options, trimmedQuery]);

  const onChangeSelection = useCallback(
    (selection, isAdditionalProject = false) => {
      const { clientId, projectId, roleId } = selection;

      const selectedOption = find(options, { clientId, projectId, roleId });
      if (!selectedOption) {
        console.error(`No selection found for`, { options, selection });
        return;
      }

      if (isAdditionalProject) {
        const { projectId: additionalProjectId, projectName: name } = selectedOption;

        onChange({
          // Display Values
          name,
          role: null,

          // API Values
          additionalProjectId,
          engagementId: null,
          resourceId: null,
        });
      } else {
        const {
          clientName,
          projectId: engagementId,
          projectKey: projectId,
          projectName: name,
          roleId,
          roleName: role,
          roleStartDate,
          roleEndDate,
        } = selectedOption;

        onChange({
          // Display Values
          clientName,
          name,
          role,
          startDate: roleStartDate,
          endDate: roleEndDate,

          // API Values
          additionalProjectId: null,
          engagementId,
          projectId,
          resourceId: roleId,
        });
      }

      onClose();
    },
    [options, onChange, onClose]
  );

  // Handle Escape/Enter Press
  useEffect(() => {
    const onEscOrEnter = e => {
      if (e.keyCode === 27) onClose();
      if (e.keyCode === 13 && !query.length) onClose();
    };

    document.addEventListener('keydown', onEscOrEnter);
    return () => {
      document.removeEventListener('keydown', onEscOrEnter);
    };
  }, [query.length, onClose]);

  const renderSearchResults = () => {
    if (isSearching) {
      return <SearchPane options={matchedResults} query={query} onChange={onChangeSelection} onClose={onClose} />;
    }

    const currentValue = projectRow.additionalProjectId
      ? { clientId: ADDITIONAL_PROJECTS_ID, projectId: projectRow.additionalProjectId }
      : { clientId: projectRow.clientId, projectId: projectRow.projectId };

    return (
      <SelectorPane
        options={options}
        messageError={null}
        value={currentValue}
        onChange={onChangeSelection}
        onClose={onClose}
      />
    );
  };

  return (
    <StyledProjectSelector onClick={e => e.stopPropagation()}>
      <SearchInput maxLength="50" onChange={setQuery} value={query} />
      {renderSearchResults()}
    </StyledProjectSelector>
  );
};

export { ProjectSelector };
