import React from 'react';

import Button from 'components/Button';
import { DATE_FORMATS, getDateRangeString } from 'utils/dates';
import { aria } from 'constants/aria';

const SaveButton = ({ currentPeriod, disabled, onSave }) => {
  const readableDateRange = getDateRangeString(currentPeriod, DATE_FORMATS.readable);

  return (
    <Button
      onClick={onSave}
      disabled={disabled}
      aria-label={aria.timesheet.saveButton({ dateRange: readableDateRange })}
    >
      SAVE
    </Button>
  );
};

export default SaveButton;
