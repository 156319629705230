import React, { PureComponent } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import styled from 'styled-components';

import { colors } from 'theme/theme';
import ContextList from './ContextList';

const StyledContext = styled.div`
  position: relative;
`;

const ContextBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

const ContextButton = styled.button`
  width: 35px;
  height: 35px;
  background: ${colors.white};
  color: ${colors.grayDim};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  transition: background 0.5s;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

class ContextMenu extends PureComponent {
  static defaultProps = {
    options: [],
  };

  state = {
    visible: false,
  };

  callAction = action => () => {
    this.toggleMenu();
    action();
  };

  toggleMenu = () => {
    this.setState(state => ({
      visible: !state.visible,
    }));
  };
  render() {
    const { ariaLabel, options } = this.props;
    const { visible } = this.state;

    const renderList = () => (
      <div key="context">
        <ContextBackdrop onClick={this.toggleMenu} />
        <ContextList options={options} onItemClick={this.callAction} />
      </div>
    );

    return (
      <StyledContext>
        <ReactCSSTransitionGroup transitionName="animate" transitionEnterTimeout={200} transitionLeaveTimeout={150}>
          {visible && renderList()}
        </ReactCSSTransitionGroup>
        <ContextButton aria-label={ariaLabel} onClick={this.toggleMenu}>
          <i className="fa fa-ellipsis-v" />
        </ContextButton>
      </StyledContext>
    );
  }
}

export default ContextMenu;
