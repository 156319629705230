import { models } from 'powerbi-client';
import { EmbedType } from 'powerbi-client-react';
import { useFetch } from 'hooks/useFetch';

export const usePowerBIReport = (reportId = '', options) => {
  const { data, ...rest } = useFetch(reportId ? `/api/powerbi/reports/${reportId.replaceAll('-', '_')}` : null, {
    revalidateOnFocus: false,
    ...options,
  });

  const report = data ?? null;

  const { name = '', id, embedUrl, token: accessToken } = report ?? {};

  const embedConfig = {
    id,
    embedUrl,
    accessToken,
    type: EmbedType.Report,
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.Read,
  };

  return { report, name, embedConfig, ...rest };
};
