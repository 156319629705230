import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from 'components/NotFound';
import ReportsList from './ReportsList';
import { Container, View } from 'components/layout';

import AccountingReport from 'containers/reports/AccountingReportContainer';
import AllocationReport from 'containers/reports/AllocationReportContainer';
import GeneralTimeReport from 'containers/reports/GeneralTimeReportContainer';
import ProjectTimeReport from 'containers/reports/ProjectTimeReportContainer';
import NotSubmittedReport from 'containers/reports/NotSubmittedReportContainer';

export default ({ match }) => (
  <View>
    <Container>
      <Switch>
        <Route exact path={`${match.path}`} component={ReportsList} />
        <Route exact path={`${match.path}/accounting`} component={AccountingReport} />
        <Route exact path={`${match.path}/allocation`} component={AllocationReport} />
        <Route exact path={`${match.path}/general-time`} component={GeneralTimeReport} />
        <Route exact path={`${match.path}/project-time`} component={ProjectTimeReport} />
        <Route exact path={`${match.path}/timesheets-not-submitted`} component={NotSubmittedReport} />
        <Route component={NotFound} />
      </Switch>
    </Container>
  </View>
);
