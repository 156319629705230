import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BillingPeriod from 'components/BillingPeriod';
import { actions, selectors } from 'modules/timesheet';

class ReadOnlyBillingPeriodContainer extends PureComponent {
  componentDidMount() {
    const { userId, fetchTimesheet } = this.props;
    fetchTimesheet({ userId });
  }

  render() {
    const { loading, timesheet } = this.props;

    return (
      <BillingPeriod
        loading={loading}
        timesheetUser={timesheet && timesheet.employee}
        timesheetPeriod={timesheet && timesheet.timesheetPeriod}
        timesheetRows={timesheet && timesheet.timesheetRows}
        disabled={true}
      />
    );
  }
}

const mapStateToProps = state => ({
  loading: selectors.loading(state),
  timesheet: selectors.localTimesheet(state),
});

export default connect(mapStateToProps, { fetchTimesheet: actions.fetchTimesheet })(ReadOnlyBillingPeriodContainer);
