import find from 'lodash/find';
import omit from 'lodash/omit';
import dayjs from 'lib/dayjs';
import { combineActions, createActions, handleActions } from 'redux-actions';
import { all, call, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import uuid from 'uuid/v4';

import { TIMESHEET_STATUSES } from 'lib/constants';
import { currentUser as currentUserSelector } from 'modules/currentUser';
import { actions as modalActions } from 'modules/modal';
import { actions as periodActions, selectors as periodSelectors } from 'modules/timesheetPeriods';
import { actions as toastActions } from 'modules/toast';
import * as api from 'utils/api';
import { reorder, updateItem } from 'utils/array';
import { sortReadOnlyTimesheetRows } from 'utils/sortReadOnlyTimesheetRows';

const { PENDING, APPROVED } = TIMESHEET_STATUSES;

export const REJECT_TIMESHEET_MODAL = 'REJECT_TIMESHEET_MODAL';

// default state
const defaultState = {
  backup: null,
  local: null,
  loading: true,
  saving: false,
  history: [],
  historyLoading: false,
};

const newTimesheetRow = nextId => ({
  id: nextId ?? uuid(),
  projectId: null,
  resourceId: null,
  additionalProjectId: null,
  role: null,
  name: null,
  timesheetEntries: [],
  isNew: true,
});

// how long between blur of input/note change and save
const AUTOSAVE_TIMEOUT = 30 * 1000;

// actions
export const actions = createActions(
  {
    UPDATE_TIMESHEET_ENTRY: (rowId, date, value) => ({
      rowId,
      date,
      value,
    }),
    UPDATE_TIMESHEET_ENTRY_NOTE: (rowId, date, value) => ({
      rowId,
      date,
      value,
    }),
    UPDATE_ROW_PROJECT: (rowId, updates) => ({ rowId, updates }),
    ADD_TIMESHEET_ROW_SUCCESS: nextId => newTimesheetRow(nextId),
  },
  'UPDATE_ROW_ORDER',
  'ADD_TIMESHEET_ROW',
  'FETCH_TIMESHEET',
  'FETCH_TIMESHEET_SUCCESS',
  'FETCH_TIMESHEET_HISTORY',
  'FETCH_TIMESHEET_HISTORY_RESPONSE',
  'REMOVE_TIMESHEET_ROW',
  'SAVE_TIMESHEET',
  'SAVE_TIMESHEET_SUCCESS',
  'SAVE_TIMESHEET_FAILURE',
  'SUBMIT_TIMESHEET',
  'SUBMIT_TIMESHEET_SUCCESS',
  'SUBMIT_TIMESHEET_CANCELED',
  'RESCIND_TIMESHEET',
  'RESCIND_TIMESHEET_SUCCESS',
  'RESCIND_TIMESHEET_CANCELED'
);

// selectors
export const selectors = {
  backupTimesheet: state => state.timesheet.backup,
  localTimesheet: state => state.timesheet.local,
  loading: state => state.timesheet.loading,
  saving: state => state.timesheet.saving,
  showNotes: state => state.timesheet.showNotes,
  history: state => state.timesheet.history,
  historyLoading: state => state.timesheet.historyLoading,
};

selectors.edited = createSelector(
  [selectors.backupTimesheet, selectors.localTimesheet],
  (backup, local) => backup !== local
);

selectors.hasPendingRows = createSelector(
  [selectors.localTimesheet],
  timesheet => timesheet && timesheet.timesheetRows.filter(row => row.pending).length > 0
);

selectors.valid = createSelector(
  [selectors.localTimesheet],
  timesheet =>
    timesheet && timesheet.timesheetRows.filter(row => !row.engagementId && !row.additionalProjectId).length === 0
);

selectors.saveable = createSelector(
  [selectors.edited, selectors.loading, selectors.saving],
  (isEdited, isLoading, isSaving) => isEdited && !isLoading && !isSaving
);

selectors.disabled = createSelector(
  [selectors.localTimesheet, selectors.saving, selectors.loading],
  (timesheet, isSaving, isLoading) =>
    isSaving || isLoading || !timesheet || [PENDING, APPROVED].includes(timesheet.status)
);

selectors.status = createSelector([selectors.backupTimesheet], timesheet => timesheet && timesheet.status);

selectors.totalHours = createSelector([selectors.localTimesheet], timesheet => {
  if (!timesheet) return 0;

  return timesheet.timesheetRows.reduce((totalOverallHours, timesheetRow) => {
    const totalRowHours = timesheetRow.timesheetEntries.reduce((entryHours, timesheetEntry) => {
      return entryHours + parseFloat(timesheetEntry.hours);
    }, 0);

    return totalRowHours + totalOverallHours;
  }, 0);
});

selectors.pending = createSelector([selectors.backupTimesheet], timesheet => timesheet && timesheet.status === PENDING);

selectors.approved = createSelector(
  [selectors.backupTimesheet],
  timesheet => timesheet && timesheet.status === APPROVED
);

// sagas
function* addTimesheetRowSaga({ payload: nextId }) {
  try {
    const isDisabled = yield select(selectors.disabled);
    if (isDisabled) return;

    const user = yield select(currentUserSelector);
    if (!user || !user.startDate) throw Error('Invalid start date');

    yield put(actions.addTimesheetRowSuccess(nextId));
  } catch (e) {
    yield put(
      modalActions.showModal('alert', {
        title: 'Missing Start Date',
        message: `Please contact HR at hr@moserit.com to have your employment start date added in Engagements
          before creating a timesheet.`,
        type: 'alert',
        closeText: 'OK',
      })
    );
  }
}

function* fetchTimesheetSaga({ payload }) {
  try {
    const { timesheet } = yield call(api.timesheet, payload);

    if (timesheet) {
      yield put(actions.fetchTimesheetSuccess(timesheet));
    }
  } catch (e) {
    yield put(
      toastActions.createToast('alert', {
        id: 'TIMESHEET_FETCH',
        title: `Timesheets Failed To Load`,
        message: `Unable to load timesheets! Please refresh the page and relogin to Office 365`,
      })
    );
  }
}

function* fetchTimesheetHistorySaga() {
  const { id } = yield select(selectors.localTimesheet);
  const user = yield select(currentUserSelector);

  if (!id) throw new Error('A timesheet id is required to fetch history');

  try {
    const { timesheetStatusRevisions } = yield call(api.timesheetHistory, { id, userId: user.id });

    if (timesheetStatusRevisions) {
      yield put(actions.fetchTimesheetHistoryResponse(timesheetStatusRevisions));
    } else {
      const e = new Error('Timesheet history failed to load.');
      yield put(actions.fetchTimesheetHistoryResponse(e));
    }
  } catch (e) {
    yield put(actions.fetchTimesheetHistoryResponse(e));
  }
}

const removeTempIds = rows => rows.map(r => (r.isNew ? omit(r, 'id') : r));

function* saveTimesheetSaga() {
  try {
    const edited = yield select(selectors.edited);
    const valid = yield select(selectors.valid);

    if (!edited) {
      yield put(actions.saveTimesheetFailure());
      return;
    }

    if (!valid) {
      yield put(actions.saveTimesheetFailure());
      yield put(
        modalActions.showModal('alert', {
          title: 'Timesheet Error',
          closeText: 'Got It',
          type: 'alert',
          message: `It looks like something might be wrong with your timesheet.
          Please make sure all rows have a valid project selected.`,
        })
      );
      return;
    }

    const { id, timesheetRows } = yield select(selectors.localTimesheet);
    const { id: timesheetPeriodId } = yield select(periodSelectors.selectedPeriod);

    const { timesheet } = !id
      ? yield call(api.createTimesheet, {
          timesheetPeriodId,
          timesheetRows: removeTempIds(timesheetRows),
        })
      : yield call(api.updateTimesheet, id, {
          timesheetPeriodId,
          timesheetRows: removeTempIds(timesheetRows),
        });

    if (timesheet) {
      yield put(actions.saveTimesheetSuccess(timesheet));
      yield put(
        toastActions.createToast('success', {
          id: 'TIMESHEET_SAVE',
          title: `Timesheet Saved`,
          message: `Timesheet was successfully saved!`,
        })
      );
    }
  } catch (e) {
    yield put(actions.saveTimesheetFailure());
    yield put(
      toastActions.createToast('alert', {
        id: 'TIMESHEET_SAVE',
        title: `Timesheet Error`,
        message: `Timesheet failed to save, please try again or reload the page!`,
      })
    );
  }
}

function* submitTimesheetSaga() {
  if (yield select(selectors.edited)) {
    yield put(actions.submitTimesheetCanceled());
    return;
  }

  try {
    const { id, timesheetRows } = yield select(selectors.localTimesheet);
    const { timesheet } = yield call(api.submitTimesheet, id, { timesheetRows: removeTempIds(timesheetRows) });
    if (timesheet) {
      yield put(actions.submitTimesheetSuccess(timesheet));
      yield put(
        toastActions.createToast('success', {
          id: 'TIMESHEET_SUBMIT',
          title: `Timesheet Submitted`,
          message: `Timesheet was submitted successfully!`,
        })
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* rescindTimesheetSaga() {
  try {
    const { id } = yield select(selectors.localTimesheet);
    const { timesheet } = yield call(api.rescindTimesheet, id);
    if (timesheet) {
      yield put(actions.rescindTimesheetSuccess(timesheet));
      yield put(
        toastActions.createToast('success', {
          id: 'TIMESHEET_RESCIND',
          title: `Timesheet Unsubmitted`,
          message: `Timesheet was unsubmitted successfully!`,
        })
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* autoSaveSaga() {
  const { save } = yield race({
    cancel: take([actions.saveTimesheet, periodActions.selectTimesheetPeriod]),
    save: delay(AUTOSAVE_TIMEOUT),
  });
  const isValid = yield select(selectors.valid);

  if (save && isValid) {
    yield put(actions.saveTimesheet());
  }
}

export function* timesheetSaga() {
  yield all([
    takeLatest(actions.addTimesheetRow, addTimesheetRowSaga),
    takeLatest(
      [actions.updateTimesheetEntry, actions.updateTimesheetEntryNote, actions.updateRowProject],
      autoSaveSaga
    ),
    takeLatest(actions.fetchTimesheet, fetchTimesheetSaga),
    takeLatest(actions.fetchTimesheetHistory, fetchTimesheetHistorySaga),
    takeLatest(actions.saveTimesheet, saveTimesheetSaga),
    takeLatest(actions.submitTimesheet, submitTimesheetSaga),
    takeLatest(actions.rescindTimesheet, rescindTimesheetSaga),
  ]);
}

// Helpers
const updateEntry = (entry = {}, date, key, val) => ({
  date: entry.date || date,
  hours: entry.hours || 0,
  note: entry.note || '',
  [key]: val,
});

const updateRow = (state, payload, key, valueFn) => {
  const { rowId, date, value } = payload;

  const timesheetRows = state.local.timesheetRows.map(row => {
    if (row.id !== rowId) return row;

    const entry = find(row.timesheetEntries, { date });
    const entryUpdated = updateEntry(entry, date, key, valueFn ? valueFn(value) : value);

    return {
      ...row,
      timesheetEntries: updateItem(row.timesheetEntries, entryUpdated, 'date'),
    };
  });

  return { ...state, local: { ...state.local, timesheetRows } };
};

// reducer
export default handleActions(
  {
    [actions.fetchTimesheet](state) {
      return { ...state, loading: true, history: defaultState.history };
    },
    [combineActions(
      actions.fetchTimesheetSuccess,
      actions.saveTimesheetSuccess,
      actions.submitTimesheetSuccess,
      actions.rescindTimesheetSuccess
    )](state, { payload }) {
      const timesheet = {
        ...payload,
        timesheetRows: sortReadOnlyTimesheetRows(payload.timesheetRows),
      };

      return {
        ...state,
        loading: false,
        saving: false,
        local: timesheet,
        backup: timesheet,
      };
    },
    [actions.saveTimesheetFailure](state) {
      return { ...state, loading: false, saving: false };
    },
    [actions.fetchTimesheetHistory](state) {
      return { ...state, history: defaultState.history, historyLoading: true };
    },
    [actions.fetchTimesheetHistoryResponse](state, { payload: history }) {
      return { ...state, history, historyLoading: false };
    },
    [actions.addTimesheetRowSuccess](state, { payload: newRow }) {
      const timesheetRows = sortReadOnlyTimesheetRows([...state.local.timesheetRows, newRow]);
      return { ...state, local: { ...state.local, timesheetRows } };
    },
    [actions.removeTimesheetRow](state, { payload: rowId }) {
      const timesheetRows = state.local.timesheetRows.filter(row => row.id !== rowId);
      return { ...state, local: { ...state.local, timesheetRows } };
    },
    [actions.updateTimesheetEntry](state, { payload }) {
      return updateRow(state, payload, 'hours', value => (value ? parseFloat(value) : 0));
    },
    [actions.updateTimesheetEntryNote](state, { payload }) {
      return updateRow(state, payload, 'note');
    },
    [actions.updateRowOrder](state, { payload }) {
      return {
        ...state,
        local: {
          ...state.local,
          timesheetRows: reorder(state.local.timesheetRows, payload.fromIndex, payload.toIndex),
        },
      };
    },
    [actions.updateRowProject](state, { payload: { rowId, updates } }) {
      const projectEndDate = updates.endDate ? dayjs(updates.endDate) : undefined;

      const timesheetRows = state.local.timesheetRows.map(row => {
        if (row.id !== rowId) return row;

        const updatedEntries = row.timesheetEntries.filter(entry => {
          if (!projectEndDate) return true;
          return projectEndDate.diff(entry.date, 'd') >= 0;
        });

        return {
          id: row.id,
          isNew: row.isNew,
          timesheetEntries: updatedEntries,
          ...updates,
        };
      });

      return { ...state, local: { ...state.local, timesheetRows } };
    },
    [combineActions(actions.saveTimesheet, actions.submitTimesheet, actions.rescindTimesheet)](state) {
      return { ...state, saving: true };
    },
    [actions.submitTimesheetCanceled](state) {
      return { ...state, saving: false, loading: false };
    },
  },
  defaultState
);
