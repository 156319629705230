import React, { lazy, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import { AutoLogoutTimer } from 'components/AutoLogoutTimer';
import { SiteContainer, MainContainer } from 'components/layout/Site';
import Reports from 'components/Reports';
import PowerBIReports, { POWERBI_REPORTS_PATH } from 'components/PowerBIReports';
import AuthGate from 'components/AuthGate';
import LoaderView from 'components/LoaderView';
import Admin from 'components/Admin';
import Notice from 'containers/NoticeContainer';
import Header from 'containers/HeaderContainer';
import Login from 'components/Login';
import Logout from 'components/Logout';
import ModalContainer from 'containers/Modal.container';
import ToastContainer from 'containers/Toast.container';
import NotFound from 'components/NotFound';
import PTO from 'components/PTO';
import ReferenceData from 'components/ReferenceData';
import Team from 'components/Team';
import Timesheets from 'components/Timesheets';
import GlobalStyle from 'theme/global';
import GlobalPlugins from 'containers/GlobalPlugins';
import store, { history } from 'lib/store';
import { ErrorBoundary } from 'react-error-boundary';
import { CriticalErrorView } from 'components/Errors/CriticalErrorView';
import { ErrorView } from 'components/Errors/ErrorView';
import { AnalyticsProvider } from '../../providers/AnalyticsProvider';

const Help = lazy(() => import(/* webpackChunkName: "help" */ 'components/Help'));

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <GlobalStyle />
      <GlobalPlugins />
      <ErrorBoundary fallback={<CriticalErrorView />}>
        <AnalyticsProvider>
          <AuthGate loginComponent={Login}>
            <AutoLogoutTimer>
              <ReferenceData>
                <SiteContainer>
                  <Notice />
                  <Header />
                  <MainContainer>
                    <ErrorBoundary fallback={<ErrorView />}>
                      <Suspense fallback={<LoaderView />}>
                        <Switch>
                          <Route exact path="/" render={() => <Redirect to="/timesheets" />} />
                          <Route path="/timesheets" component={Timesheets} />
                          <Route path="/pto" component={PTO} />
                          <Route path="/team" component={Team} />
                          <Route path="/reports" component={Reports} />
                          <Route path={POWERBI_REPORTS_PATH} component={PowerBIReports} />
                          <Route path="/help" component={Help} />
                          <Route path="/admin" component={Admin} />
                          <Route path="/logout" component={Logout} />
                          <Route component={NotFound} />
                        </Switch>
                      </Suspense>
                    </ErrorBoundary>
                    <ToastContainer />
                  </MainContainer>
                  <ModalContainer />
                </SiteContainer>
              </ReferenceData>
            </AutoLogoutTimer>
          </AuthGate>
        </AnalyticsProvider>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

export default App;
