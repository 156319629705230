import React from 'react';
import { useParams } from 'react-router';
import Loader from 'components/Loader';
import PowerBIEmbed from 'components/PowerBIEmbed';
import { usePowerBIReport } from 'hooks/usePowerBIReport';

const PowerBIReport = () => {
  const { reportId } = useParams();

  const { embedConfig, isLoading, error } = usePowerBIReport(reportId);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return <PowerBIEmbed embedConfig={embedConfig} autoHeight />;
};

export default PowerBIReport;
