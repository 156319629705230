import React from 'react';

const IconGrip = () => {
  return (
    <svg viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0-2c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2M2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2m2 8c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default IconGrip;
