import React from 'react';

import { parsedFloat, renderTimeFrame } from 'utils/common';
import { PTO_REASONS } from 'utils/reference';
import ContextMenu from 'components/ContextMenu';
import Loader from 'components/Loader';
import StatusText from 'components/StatusText';
import Table from 'components/Table';
import PTOBalance from 'components/PTO/PTOBalance';
import { aria } from 'constants/aria';

const getColSpan = disabled => (disabled ? 5 : 6);

const renderLoading = (disabled, numItems = 1) => {
  const arr = [...Array(numItems)];
  const colSpan = getColSpan(disabled);

  return arr.map((_, i) => (
    <tr key={i}>
      <td colSpan={colSpan} style={{ padding: 0 }}>
        <Loader compact />
      </td>
    </tr>
  ));
};

const renderContent = (items, { disabled, onEdit, onDelete }) => {
  if (items.length === 0) {
    const colSpan = getColSpan(disabled);
    return (
      <tr>
        <td colSpan={colSpan} style={{ textAlign: 'center' }}>
          No Requests Found!
        </td>
      </tr>
    );
  }

  return items.map(item => {
    const { id, mostRecentDetail: details } = item;
    const { comment, reason, status, startDate, timeframeType: type, endDate, hours } = details;
    const timeReason = PTO_REASONS[reason] ?? 'Unknown Type';
    const timeFrame = renderTimeFrame(type, startDate, endDate);

    return (
      <tr key={id}>
        <td className="Table__nowrap">{timeReason}</td>
        <td className="Table__nowrap">{timeFrame}</td>
        <td className="Table__nowrap">{parsedFloat(hours)} Hours</td>
        <td style={{ width: '30%' }}>{comment}</td>
        <td>
          <StatusText status={status} />
        </td>
        {!disabled && (
          <td style={{ fontSize: 16, width: 75 }}>
            <ContextMenu
              ariaLabel={aria.timeOff.requestOptionsButton({ timeFrame, timeReason })}
              options={[
                {
                  ariaLabel: aria.timeOff.requestEditOption({ timeFrame, timeReason }),
                  label: 'Edit',
                  action: () => onEdit(details),
                },
                {
                  ariaLabel: aria.timeOff.requestDeleteOption({ timeFrame, timeReason }),
                  label: 'Delete',
                  type: 'alert',
                  action: () => onDelete(id, details),
                },
              ]}
            />
          </td>
        )}
      </tr>
    );
  });
};

const PTOTable = ({ items, loading, onEdit, onDelete, disabled = false }) => {
  const colSpan = getColSpan(disabled);

  return (
    <Table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Date(s)</th>
          <th>Duration</th>
          <th>Comment</th>
          <th>Status</th>
          {!disabled && <th>&nbsp;</th>}
        </tr>
      </thead>
      <tbody>{loading ? renderLoading(disabled) : renderContent(items, { disabled, onEdit, onDelete })}</tbody>
      <tfoot>
        <tr>
          <td colSpan={colSpan} style={{ textAlign: 'right' }}>
            <PTOBalance requests={items} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default PTOTable;
