import styled from 'styled-components';

import { colors } from 'theme/theme';

const transparentOr = (color1, color2 = 'transparent') => props => (props.transparent ? color2 : color1);

const Table = styled.table`
  background-color: ${transparentOr(colors.white)};
  padding: 0;
  border: solid 1px ${transparentOr(colors.graySilver)};
  border-radius: 3px;
  border-spacing: 0;
  table-layout: auto;
  width: 100%;
  font-size: 14px;

  thead tr th {
    background-color: ${transparentOr(colors.grayTint)};
    border-bottom: solid 1px ${transparentOr(colors.graySilver)};
  }

  tfoot tr td {
    background-color: ${transparentOr(colors.grayTint)};
    border-top: solid 1px ${transparentOr(colors.graySilver)};
  }

  tbody tr {
    td {
      border-bottom: solid 1px ${transparentOr('rgba(151, 151, 151, 0.3)')};
    }

    &:last-child td {
      border-bottom: none;
    }
  }

  th,
  td {
    height: 50px;
    vertical-align: middle;
    text-align: left;
    padding: 0 20px;

    &.center {
      text-align: center;
    }

    &.Table__nowrap {
      white-space: nowrap;
    }
  }
`;

export default Table;
