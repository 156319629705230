import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from 'theme/theme';

const MenuLink = styled(NavLink)`
  display: inline-flex;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  margin-right: 25px;
  color: ${colors.grayDim};
  padding: 0 0 3px;
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom: 2px solid ${colors.orangeGambage};
  }
`;

const MenuLinkDisabled = styled(MenuLink)`
  color: ${colors.graySilver};
  cursor: not-allowed;
  pointer-events: none;
`;

const SubHeaderLink = props => {
  return props.disabled ? <MenuLinkDisabled {...props} /> : <MenuLink {...props} />;
};

export default SubHeaderLink;
