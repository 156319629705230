import React, { useState } from 'react';

import { CellInput } from './BillingPeriod.styled';
import { parsedFloat } from 'utils/common';

const HOURS_MIN = 0;
const HOURS_MAX = 24;

const formatHoursInput = (input = '') => {
  // Filter out invalid characters
  let valueString = input.replace(/[^0-9.]*/g, '');

  // Prevent weird numbers (Ex: 2.5.55)
  if (valueString.indexOf('.') > -1) {
    const [wholeNumbers, decimals] = valueString.split('.');
    valueString = [wholeNumbers, decimals.slice(0, 2)].join('.');
  }

  let valueParsed = parsedFloat(valueString, 2);
  const clampedValue = Math.max(HOURS_MIN, Math.min(HOURS_MAX, valueParsed));

  if (clampedValue !== valueParsed) {
    valueParsed = clampedValue;
    valueString = `${clampedValue}`;
  }

  return [valueString, valueParsed];
};

const EntryInput = ({ isHoliday, isToday, isWeekend, value: valueIncoming, onUpdate, ...props }) => {
  const [value, setValue] = useState(valueIncoming || '');

  const onChange = e => {
    const { value: nextValue } = e.target;
    const [valueString, valueParsed] = formatHoursInput(nextValue);

    setValue(valueString);
    onUpdate(valueParsed);
  };

  return (
    <CellInput
      {...props}
      type="text"
      className="EntryInput"
      value={value}
      onChange={onChange}
      isHoliday={isHoliday}
      isToday={isToday}
      isWeekend={isWeekend}
    />
  );
};

export default EntryInput;
