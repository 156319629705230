import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as api from 'utils/api';
import { getItem, setItem } from 'utils/localStorage';
import * as nav from 'utils/navigation';

// sign in failed response messages
const failedLogInTypes = [
  {
    name: 'permission_failed',
    message: 'Sorry, you are not enabled to login to this application',
  },
];

const PREFERENCES_STORAGE_KEY = 'USER_PREFERENCES';
const defaultPreferences = {
  hoursWorkday: 8,
  includeWeekends: false,
};

// default state
const defaultState = {
  isLoading: false,
  data: null,
  preferences: getItem(PREFERENCES_STORAGE_KEY, defaultPreferences),
};

// actions
export const userLogin = createAction('USER_LOGIN');
export const userLoginFailed = createAction('USER_LOGIN_FAILED');
export const userLogOut = createAction('USER_LOG_OUT');
export const fetchCurrentUser = createAction('FETCH_CURRENT_USER');
export const fetchCurrentUserSuccess = createAction('FETCH_CURRENT_USER_SUCCESS');
export const updateUserPreferences = createAction('UPDATE_USER_PREFERENCES');

// selectors
export const isLoading = state => state.currentUser.isLoading;
export const isInvalid = state => state.currentUser.isInvalid;
export const currentUser = state => state.currentUser.data;
export const currentUserErrors = state => state.currentUser.errors;
export const userPreferences = state => state.currentUser.preferences;

export const hasAnyRole = createSelector([currentUser], user => user && !!user.roles.length);

export const isAdmin = createSelector([currentUser], user => user && user.roles.includes('admin'));

export const isContractor = createSelector([currentUser], user => user && user.contractor);

export const isManager = createSelector([currentUser], user => user && user.manager);

// sagas
export function* fetchCurrentUserSaga() {
  try {
    const { currentUser, error } = yield call(api.fetchCurrentUser);
    if (currentUser) {
      yield put(fetchCurrentUserSuccess(currentUser));
    } else {
      const errorType = failedLogInTypes.find(errorType => {
        return errorType.name === error;
      });
      yield put(userLoginFailed(errorType));
    }
  } catch (e) {
    yield put(userLoginFailed(e));
  }
}

function* userLoginSaga() {
  yield call(nav.azureRedirect);
}

function* userLogOutSaga() {
  try {
    const { success } = yield call(api.signOut);
    if (success) return { success };
  } catch (err) {
    return { error: 'Something went awry' };
  }
}

export function* currentUserSaga() {
  yield all([
    takeEvery(fetchCurrentUser, fetchCurrentUserSaga),
    takeEvery(userLogin, userLoginSaga),
    takeEvery(userLogOut, userLogOutSaga),
    put(fetchCurrentUser()),
  ]);
}

// reducer
export default handleActions(
  {
    [fetchCurrentUser]() {
      return { ...defaultState, isLoading: true };
    },
    [fetchCurrentUserSuccess](state, { payload }) {
      return { ...defaultState, data: { ...payload } };
    },
    [updateUserPreferences](state, { payload }) {
      const preferences = setItem(PREFERENCES_STORAGE_KEY, payload);
      return { ...state, preferences };
    },
    [userLoginFailed](state, { payload }) {
      return { ...defaultState, isLoading: false };
    },
  },
  defaultState
);
