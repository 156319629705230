import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import noop from 'lodash/noop';

import { actions } from 'modules/timesheet';
import { Accessible } from 'components/Accessible';
import { Project } from 'components/Project/Project';
import { ProjectSelector } from 'components/ProjectSelector/ProjectSelector';
import styled from 'styled-components';
import { aria } from 'constants/aria';

const StyledBillingPeriod = styled.div`
  .ProjectCell__static {
    cursor: pointer;
    padding: 10px;
    font-size: 14px;

    &:hover {
      background: rgba(112, 112, 112, 0.05);
    }
  }
`;

const BillingPeriodProject = ({ disabled = false, editing, projectRow, onEdit = noop }) => {
  const dispatch = useDispatch();

  const handleEdit = useCallback(() => {
    if (disabled) return;
    onEdit(projectRow.id);
  }, [disabled, projectRow, onEdit]);

  const handleSelect = useCallback(
    changes => {
      dispatch(actions.updateRowProject(projectRow.id, changes));
    },
    [dispatch, projectRow]
  );

  const render = () => {
    // Editing - Show Selector
    if (editing) {
      return <ProjectSelector onChange={handleSelect} onClose={() => onEdit(null)} projectRow={projectRow} />;
    }

    // Not Editing - Something Selected
    if (projectRow.additionalProjectId || projectRow.engagementId) {
      const isAdditionalProject = !!projectRow.additionalProjectId;

      let label;
      if (isAdditionalProject) {
        label = aria.timesheet.projectSelector({
          rowName: projectRow.name,
        });
      } else {
        const rowName = projectRow.role ? `${projectRow.clientName} - ${projectRow.role}` : projectRow.clientName;
        label = aria.timesheet.projectSelector({
          rowName,
        });
      }

      return (
        <Project
          ariaLabel={label}
          data={projectRow}
          isAdditionalProject={isAdditionalProject}
          disabled={disabled}
          onClick={handleEdit}
        />
      );
    }

    // Empty Project
    return (
      <Accessible
        aria-label={aria.timesheet.projectSelector()}
        role="listitem"
        autoFocus
        block
        className="ProjectCell__static"
        onClick={handleEdit}
      >
        <em>{'< select a project >'}</em>
      </Accessible>
    );
  };

  return <StyledBillingPeriod>{render()}</StyledBillingPeriod>;
};

export { BillingPeriodProject };
