import React from 'react';
import styled from 'styled-components';

import { colors } from 'theme/theme';
import Button from 'components/Button';

const StyledBanner = styled.div`
  display: flex;
  min-height: 55px;
  align-items: center;
  color: ${colors.white};
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid ${colors.grayLight};
  border-radius: 3px;
  text-transform: uppercase;
  padding: 8px 8px 8px 20px;
  margin: 0 0 15px;
  transition: background 300ms, border-color 300ms;

  ${props => props.info && `background: ${colors.blueDodger}`};
  ${props => props.info && `border-color: ${colors.blueDenim}`};
  ${props => props.info && `color: ${colors.white}`};

  ${props => props.infoAlternate && `background: #F4DD91`};
  ${props => props.infoAlternate && `border-color: #F3B33E;`};
  ${props => props.infoAlternate && `color: ${colors.black}`};

  ${props => props.success && `background: ${colors.greenSushi}`};
  ${props => props.success && `border-color: ${colors.greenSushi}`};
  ${props => props.success && `color: ${colors.white}`};

  ${props => props.alert && `background: ${colors.redPersian}`};
  ${props => props.alert && `border-color: ${colors.redPersian}`};
  ${props => props.alert && `color: ${colors.white}`};

  &:last-child {
    margin: 0;
  }

  .Banner__description {
    font-weight: bold;
    margin-right: 10px;
  }

  .Banner__actions {
    margin-left: auto;
  }

  .Banner__action {
    width: 140px;
    margin-right: 10px;

    &:last-child {
      margin: 0;
    }
  }

  .Banner__alert-icon {
    margin-right: 10px;
  }

  .Banner__close-icon {
    margin-right: 10px;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Banner = ({ actions = [], children, enableCloseAction, handleClose, ...props }) => (
  <StyledBanner {...props}>
    <i className="Banner__alert-icon fa fa-exclamation-circle" />
    <div className="Banner__description">{children}</div>
    {enableCloseAction ? (
      <i className="Banner__close-icon fa fa-times" onClick={handleClose} />
    ) : (
      <div className="Banner__actions">
        {actions.map(({ action, display }, i) => (
          <Button key={`${i}_${display}`} className="Banner__action" onClick={action}>
            {display}
          </Button>
        ))}
      </div>
    )}
  </StyledBanner>
);

export default Banner;
