import { View } from 'components/layout';
import { ErrorMessage } from './ErrorMessage';

export const ErrorView = () => {
  return (
    <View fab={null}>
      <ErrorMessage />
    </View>
  );
};
