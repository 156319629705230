import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';

import { selectors } from 'modules/timesheet';

class BillingPeriodPromptContainer extends PureComponent {
  render() {
    const { showConfirmation } = this.props;

    return (
      <Prompt when={showConfirmation} message="Are you sure you want to navigate away and lose unsaved changes?" />
    );
  }
}

const mapStateToProps = state => ({
  showConfirmation: selectors.edited(state),
});

export default connect(mapStateToProps, null)(BillingPeriodPromptContainer);
