import React from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';

import { parsedFloat, renderTimeFrame } from 'utils/common';
import { PTO_REASONS, TIME_OFF_STATUS_MESSAGES } from 'utils/reference';

import Checkbox from 'components/Checkbox';
import Loader from 'components/Loader';
import Table from 'components/Table';
import PTOAdminBulkEdit from './PTOAdminBulkEdit';
import StatusBadge from 'components/StatusBadge';

import { aria } from 'constants/aria';

const TimeRow = styled.tr`
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

const renderLoading = (colSpan = 5, numItems = 1) => {
  const arr = [...Array(numItems)];

  return arr.map((_, i) => (
    <tr key={i}>
      <td colSpan={colSpan} style={{ padding: 0 }}>
        <Loader compact />
      </td>
    </tr>
  ));
};

const renderTitle = (teamMember, year) => {
  if (!teamMember) return null;
  const title = `${teamMember.fullName}'s ${year} Requests`;
  return <h3 style={{ marginTop: 0 }}>{title}</h3>;
};

const renderContent = (items, actions) => {
  if (items.length === 0) {
    return (
      <tr>
        <td colSpan="5">No Time Off Requests Found!</td>
      </tr>
    );
  } else {
    return items.map(item => {
      const { id, employeeName, mostRecentDetail: details } = item;
      const { reason, status, startDate, timeframeType: type, endDate, hours } = details;

      return (
        <TimeRow
          key={id}
          onClick={() => {
            actions.onDetails({ ...details, ptoId: id, employeeName });
          }}
        >
          <td style={{ width: '58px' }}>
            <Checkbox
              onClick={e => e.stopPropagation()}
              onChange={actions.onSelectChange(id)}
              checked={actions.isSelected(id)}
            />
          </td>
          <td>{employeeName}</td>
          <td>{PTO_REASONS[reason]}</td>
          <td style={{ width: 250 }}>{renderTimeFrame(type, startDate, endDate)}</td>
          <td>{parsedFloat(hours)} Hours</td>
          <td>
            <StatusBadge
              status={status}
              role="contentinfo"
              aria-label={aria.timeOff.timeOffStatusMessage(employeeName, status)}
              onClick={() => {
                actions.onDetails({ ...details, ptoId: id, employeeName });
              }}
            >
              {TIME_OFF_STATUS_MESSAGES[status] ?? status}
            </StatusBadge>
          </td>
        </TimeRow>
      );
    });
  }
};

const PTOAdminTable = ({
  disabled = false,
  items = [],
  teamMember,
  year,
  loading = false,
  selectedItems = [],
  isSelected = noop,
  onBulkApprove = noop,
  onBulkReject = noop,
  onDetails = noop,
  onSelectChange = noop,
}) => {
  return (
    <>
      <PTOAdminBulkEdit
        count={selectedItems.length}
        onApprove={() => onBulkApprove(selectedItems)}
        onReject={() => onBulkReject(selectedItems)}
      />
      {renderTitle(teamMember, year)}
      <Table>
        <thead>
          <tr>
            <th style={{ width: '58px' }}>
              <Checkbox
                onChange={onSelectChange('all')}
                checked={items.length > 0 && selectedItems.length === items.length}
              />
            </th>
            <th>Employee</th>
            <th>Type</th>
            <th style={{ width: 250 }}>Date(s)</th>
            <th>Duration</th>
            <th style={{ width: 300 }}>&nbsp;</th>
          </tr>
        </thead>
        <tbody>{loading ? renderLoading(7) : renderContent(items, { isSelected, onDetails, onSelectChange })}</tbody>
      </Table>
    </>
  );
};

export default PTOAdminTable;
