import React from 'react';
import styled from 'styled-components';
import { colors } from 'theme/theme';

const Container = styled.div`
  background: ${colors.orangeGambage};
  padding: 1em 2em;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
`;

const Notice = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Notice;
