export const appFetcher = async (...args) => {
  const response = await fetch(...args);
  const json = await response.json();

  if (!response.ok) {
    const message = json?.errors ?? json?.message ?? 'Something went wrong';
    const error = new Error(message);
    error.info = json;
    error.status = response.status;
    throw error;
  }

  return json;
};
