import React from 'react';
import styled from 'styled-components';
import { StyledButton } from 'components/SubmitButton';

import { colors } from 'theme/theme';

const Button = styled(StyledButton)`
  color: ${colors.white};

  &,
  :active {
    background-color: ${colors.redBrick};
  }

  :hover {
    color: ${colors.white};
    background-color: ${colors.redPersian};
  }
`;

const UnapproveButton = props => (
  <Button title="Unapprove Timesheet" {...props}>
    <i className="fa fa-warning" />
    Unapprove Timesheet
  </Button>
);

export default UnapproveButton;
