import React from 'react';
import styled from 'styled-components';

import { colors } from 'theme/theme';

const StyledLabeledInput = styled.div`
  .LabeledInput__label {
    display: block;
    font-size: 14px;
    color: ${colors.black};
    font-weight: 700;
    margin: 0 0 5px;
  }

  .react-datepicker__input-container > input,
  .react-datepicker__input-container > input {
    height: 36px;
    border: 1px solid ${colors.grayNobel};
    border-radius: 3px;
    outline: none;
    box-shadow: none;
    width: 100%;
    font-size: 16px;
    padding: 0 8px;

    &:hover {
      cursor: pointer;
    }
  }

  p {
    margin: 0;
  }

  .react-select__input > input {
    padding: 0;
  }

  .react-select__control {
    height: 34px;
    border-color: ${colors.grayNobel};
    border-radius: 3px;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
`;

const LabeledInput = ({ label, children, style, ...props }) => (
  <StyledLabeledInput style={style}>
    {label && <label className="LabeledInput__label">{label}</label>}
    <div className="LabeledInput__input">{children}</div>
  </StyledLabeledInput>
);

export default LabeledInput;
