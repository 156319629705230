import { createActions, handleActions } from 'redux-actions';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import * as api from 'utils/api';

// default state
const defaultState = {
  relatedManagers: {
    loading: false,
    error: null,
    data: [],
  },
};

// actions
export const actions = createActions('FETCH_RELATED_MANAGERS', 'FETCH_RELATED_MANAGERS_RESPONSE');

// selectors
export const selectors = {
  loading: state => state.approvers.relatedManagers.loading,
  error: state => state.approvers.relatedManagers.error,
  relatedManagers: state => [state.currentUser.data, ...state.approvers.relatedManagers.data],
  approver: id => state => state.approvers.relatedManagers.data.find(manager => manager.id === id),
};

function* fetchRelatedManagersSaga() {
  try {
    const { relatedManagers } = yield call(api.fetchRelatedManagers);
    yield put(actions.fetchRelatedManagersResponse(relatedManagers));
  } catch (error) {
    yield put(actions.fetchRelatedManagersResponse(error));
  }
}

export function* approversSaga() {
  yield all([takeLatest(actions.fetchRelatedManagers, fetchRelatedManagersSaga)]);
}

// reducer
export default handleActions(
  {
    [actions.fetchRelatedManagers](state) {
      return {
        ...state,
        relatedManagers: {
          ...state.relatedManagers,
          loading: true,
          error: null,
        },
      };
    },
    [actions.fetchRelatedManagersResponse]: {
      next(state, { payload: data }) {
        return {
          ...state,
          relatedManagers: {
            ...defaultState.relatedManagers,
            data,
          },
        };
      },
      throw(state, { payload: error }) {
        return {
          ...state,
          relatedManagers: {
            ...state.relatedManagers,
            loading: false,
            error: error.message,
          },
        };
      },
    },
  },
  defaultState
);
