import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from 'components/NotFound';
import { Container, View } from 'components/layout';

import BillingPeriodLayout from '../BillingPeriod/BillingPeriodLayout';

export default ({ match }) => (
  <View>
    <Container>
      <Switch>
        <Route exact path={`${match.path}`} component={BillingPeriodLayout} />
        <Route component={NotFound} />
      </Switch>
    </Container>
  </View>
);
