import isArray from 'lodash/isArray';
import { useEffect, useMemo } from 'react';

const createKeyMap = commands => {
  return Object.entries(commands).reduce((commandsMap, [commandId, command]) => {
    const commandKeys = isArray(command.key) ? command.key : [command.key];
    const commandKeysMap = commandKeys.reduce((obj, key) => ({ ...obj, [key]: commandId }), {});
    return {
      ...commandsMap,
      ...commandKeysMap,
    };
  }, {});
};

const useCommands = (commands, commandActions) => {
  const keyToCommandMap = useMemo(() => createKeyMap(commands), [commands]);

  useEffect(() => {
    const handleKeyPress = e => {
      const { ctrlKey, key, metaKey } = e;
      const commandName = keyToCommandMap[key];
      const commandToRun = commandActions[commandName];

      if (!commandToRun) return;
      if (!metaKey && !ctrlKey) return;

      e.preventDefault();
      commandToRun();
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [commandActions, keyToCommandMap]);
};

export { useCommands };
