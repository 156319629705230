import { PureComponent } from 'react';
import { connect } from 'react-redux';

import PendingTimesheets from 'components/PendingTimesheets';
import { actions, selectors } from 'modules/team';

class PendingTimesheetsContainer extends PureComponent {
  componentDidMount() {
    this.props.fetchTeamPendingTimesheets();
  }

  render() {
    const { loading, pendingTimesheets } = this.props;
    return <PendingTimesheets loading={loading} timesheets={pendingTimesheets} />;
  }
}

const mapState = state => ({
  pendingTimesheets: selectors.pendingTimesheets(state),
  loading: selectors.timesheetsLoading(state),
});

const mapDispatch = { fetchTeamPendingTimesheets: actions.fetchTeamPendingTimesheets };

export default connect(mapState, mapDispatch)(PendingTimesheetsContainer);
