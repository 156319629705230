import { PureComponent } from 'react';
import { connect } from 'react-redux';

import AdminUsers from 'components/AdminUsers';
import { actions, selectors } from 'modules/admin';

class AdminUsersContainer extends PureComponent {
  componentDidMount() {
    const { isActive } = this.state;
    this.props.fetchUsers({ isActive });
  }

  state = {
    isActive: true,
    userQuery: '',
  };

  setIsActiveAndSearch = isActive => {
    this.setState(() => ({ isActive }));
    const { userQuery } = this.state;

    this.props.fetchUsers({ isActive, userQuery });
  };

  setUserQueryAndSearch = userQuery => {
    this.setState(() => ({ userQuery }));
    const { isActive } = this.state;

    this.props.fetchUsers({ isActive, userQuery });
  };

  render() {
    return (
      <AdminUsers
        {...this.state}
        users={this.props.users}
        loading={this.props.loading}
        setIsActiveAndSearch={this.setIsActiveAndSearch}
        setUserQueryAndSearch={this.setUserQueryAndSearch}
      />
    );
  }
}

const mapState = state => ({
  loading: selectors.loading(state),
  users: selectors.users(state),
});

const mapDispatch = {
  fetchUsers: actions.fetchUsers,
};

export default connect(mapState, mapDispatch)(AdminUsersContainer);
