import React from 'react';
import styled from 'styled-components';
import dayjs from 'lib/dayjs';

import InputContainer from 'components/InputContainer';
import Select from 'components/Select';

const Options = styled.div`
  display: flex;
`;

const Option = styled(InputContainer)`
  min-width: 150px;
`;

const buildOptions = options => options.map(str => ({ label: str, value: str }));

const getYears = (startYear = 2017) => {
  const currentYear = dayjs().year();

  if (startYear >= currentYear) {
    return [currentYear];
  }

  return [...Array(currentYear - startYear + 1)].map((_, i) => startYear + i).reverse();
};

const PastPTOOptions = ({ selectedYear, onOptionChange }) => {
  const years = getYears(2017);

  return (
    <Options>
      <Option>
        <h3>Year</h3>
        <Select options={buildOptions(years)} onChange={onOptionChange('year')} clearable={true} value={selectedYear} />
      </Option>
    </Options>
  );
};

export default PastPTOOptions;
