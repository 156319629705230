import { sortBy } from 'lodash';
import { createActions, handleActions } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as api from 'utils/api';

// constants
export const ADDITIONAL_PROJECTS_ID = 'ADDITIONAL_PROJECTS';
export const ADDITIONAL_PROJECTS_NAME = 'General';

// default state
const defaultState = {
  employeeProjects: [],
  additionalProjects: [],
};

// actions
export const actions = createActions(
  'FETCH_PROJECTS',
  'FETCH_PROJECTS_SUCCESS',
  'FETCH_ADDITIONAL_PROJECTS',
  'FETCH_ADDITIONAL_PROJECTS_SUCCESS'
);

// selectors
export const selectors = {
  additionalProjects: state => state.projects.additionalProjects,
  employeeProjects: state => state.projects.employeeProjects,
};

// Flatten Projects to contain client/project/role as a flat object for easier searching
selectors.clients = createSelector(
  [selectors.additionalProjects, selectors.employeeProjects],
  (additionalProjects, projects) => {
    const nextAdditionalProjects = additionalProjects.map(additionalProject => {
      const { id: projectId, name: projectName } = additionalProject;
      return {
        searchString: [ADDITIONAL_PROJECTS_NAME, projectName].join(' '),
        clientId: ADDITIONAL_PROJECTS_ID,
        clientName: ADDITIONAL_PROJECTS_NAME,
        projectId,
        projectName,
        roleId: null,
        roleName: null,
      };
    });

    const nextProjects = projects.reduce((currentProjects, project) => {
      const {
        clientId,
        clientName,
        engagementId: projectId,
        name: projectName,
        projectId: projectKey,
        roles,
        startDate: projectStartDate,
        endDate: projectEndDate,
      } = project;

      const projectRow = roles.map(role => {
        const { id: roleId, name: roleName, startDate: roleStartDate, endDate: roleEndDate } = role;
        return {
          searchString: [clientName, projectName, projectKey, roleName].join(' '),
          clientId,
          clientName,
          projectKey,
          projectId,
          projectName,
          projectStartDate,
          projectEndDate,
          roleId,
          roleName,
          roleStartDate,
          roleEndDate,
        };
      });

      return [...currentProjects, ...projectRow];
    }, []);

    return [...nextAdditionalProjects, ...sortBy(nextProjects, item => item.clientName)];
  }
);

// sagas
function* fetchProjectsSaga({ payload }) {
  try {
    const { projects } = yield call(api.projects, payload);

    if (projects) {
      yield put(actions.fetchProjectsSuccess(projects));
    }
  } catch (e) {
    console.log(e);
  }
}

function* fetchAdditionalProjectsSaga() {
  try {
    const { additionalProjects } = yield call(api.additionalProjects);

    if (additionalProjects) {
      yield put(actions.fetchAdditionalProjectsSuccess(additionalProjects));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* projectsSaga() {
  yield all([
    takeLatest(actions.fetchProjects, fetchProjectsSaga),
    takeLatest(actions.fetchAdditionalProjects, fetchAdditionalProjectsSaga),
  ]);
}

// reducer
export default handleActions(
  {
    [actions.fetchProjectsSuccess](state, { payload }) {
      return { ...state, employeeProjects: [...payload] };
    },
    [actions.fetchAdditionalProjectsSuccess](state, { payload }) {
      return { ...state, additionalProjects: [...payload] };
    },
  },
  defaultState
);
