import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TeamTimeSummary from 'components/Team/TimeSummary';
import { actions, selectors } from 'modules/team';
import useQueryParams from 'hooks/useQueryParams';

const PendingTimesheetsContainer = props => {
  const dispatch = useDispatch();
  const { getParams } = useQueryParams();
  const { startDate, approverId } = getParams();
  const timesheetsLoading = useSelector(selectors.timesheetsLoading);
  const timesheets = useSelector(selectors.timesheets);

  useEffect(() => {
    dispatch(actions.fetchTeamTimesheets({ startDate, approverId }));
  }, [dispatch, startDate, approverId]);

  return <TeamTimeSummary loading={timesheetsLoading} timesheets={timesheets} {...props} />;
};

export default PendingTimesheetsContainer;
