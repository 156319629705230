import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';

export const StyledButton = styled(Button)`
  font-weight: normal;

  & > i {
    font-size: 16px;
    margin-right: 6px;
  }
`;

const RescindButton = ({ disabled, onSubmit }) => {
  return (
    <StyledButton disabled={disabled} onClick={onSubmit}>
      <i className="fa fa-undo" />
      UNDO SUBMISSION
    </StyledButton>
  );
};

export default RescindButton;
