import React from 'react';
import BaseSelect from 'react-select';
import styled from 'styled-components';

import { colors, transitions } from 'theme/theme';

const SelectContainer = styled.div`
  vertical-align: top;
  min-width: 150px;
  font-size: 14px;

  .react-select__control {
    min-height: 36px;
    border-color: ${colors.graySilver};
    border-radius: 5px;
    box-shadow: none;
    transition: ${transitions.inputBorderColor};
  }

  .react-select__control.react-select__control--is-focused {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px ${colors.orangeGambage};
  }

  .react-select__placeholder {
    color: ${colors.black};
  }

  .react-select__group .react-select__option {
    padding-left: 1.5em;
  }

  .react-select__input input:focus {
    box-shadow: none;
  }
`;

const hasValue = value => value !== null && value !== undefined && value !== '';

const Select = ({ value, options, onChange, width, errors, handleSearch, ...rest }) => {
  const handleChange = payload => {
    if (onChange) onChange(payload ? payload.value : '');
  };

  return (
    <SelectContainer style={{ width }}>
      <BaseSelect
        clearable={true}
        {...rest}
        value={hasValue(value) ? options.find(option => option.value === value) : ''}
        options={options}
        onChange={handleChange}
        onInputChange={handleSearch}
        className="react-select"
        classNamePrefix="react-select"
      />
    </SelectContainer>
  );
};

export default Select;
