import React from 'react';
import styled from 'styled-components';

import { Container } from 'components/layout';
import { aria } from 'constants/aria';

const Emoji = styled.div`
  margin: 0 0 10px;
  line-height: 1;
  font-size: 125px;
`;

const Message = styled.div`
  padding: 45px;
  font-size: 48px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

export default () => (
  <Container style={{ textAlign: 'center', fontSize: '24px', padding: '45px' }}>
    <Message>
      <Emoji>
        <span role="img" aria-label={aria.various.emojiCry()}>
          😢
        </span>
      </Emoji>
      Page Not Found
    </Message>
  </Container>
);
