import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import LoaderPage from 'components/LoaderPage';
import { currentUser, isLoading, userLogin } from 'modules/currentUser';

class AuthGate extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    loginComponent: PropTypes.oneOfType([
      PropTypes.instanceOf(Component),
      PropTypes.instanceOf(PureComponent),
      PropTypes.func,
    ]).isRequired,
  };

  render() {
    const { loginComponent: LoginComponent, currentUser, isLoading } = this.props;

    if (!currentUser) {
      const { pathname, search } = this.props.location;

      return (
        <Switch>
          <Route path="/login" render={() => (isLoading ? <LoaderPage /> : <LoginComponent {...this.props} />)} />
          <Redirect
            to={{
              pathname: '/login',
              search: `?redirect=${pathname}${search}`,
            }}
          />
        </Switch>
      );
    }

    const { redirect = '/' } = queryString.parse(this.props.location.search);

    return (
      <Switch>
        <Route path="/login" render={() => <Redirect to={redirect} />} />
        <Route render={() => this.props.children} />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: isLoading(state),
  currentUser: currentUser(state),
});

export default withRouter(connect(mapStateToProps, { userLogin })(AuthGate));
