import { connect } from 'react-redux';

import UnapproveButton from 'components/UnapproveButton';
import { actions as modalActions } from 'modules/modal';
import { actions, selectors } from 'modules/team';

const UnapproveButtonContainer = ({ unapproveTimesheet, showModal, loading }) => (
  <UnapproveButton
    onClick={() => {
      showModal('confirm', {
        title: 'Confirm Unapproval',
        message: 'Are you sure you want to unapprove this timesheet?',
        type: 'alert',
        closeText: 'Yes, Unapprove',
        cancelText: 'No, Cancel',
        onClose: unapproveTimesheet,
      });
    }}
    disabled={loading}
  />
);

const mapState = state => ({
  loading: selectors.memberTimesheetLoading(state),
});

const mapDispatch = { unapproveTimesheet: actions.teamUnapproveTimesheet, showModal: modalActions.showModal };

export default connect(mapState, mapDispatch)(UnapproveButtonContainer);
