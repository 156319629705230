import React, { Component } from 'react';

import Select from 'components/Select';
import { fetchDepartments } from 'utils/api';

const buildOptions = options => options.map(({ name: label, id }) => ({ label, value: String(id) }));

export default class DepartmentSelectContainer extends Component {
  state = { options: [] };

  componentDidMount() {
    this.fetch();

    if (this.props.onChange) {
      this.props.onChange(this.props.value || this.props.defaultValue);
    }
  }

  fetch = async () => {
    const { departments } = await fetchDepartments();
    this.setState({ options: buildOptions(departments) });
  };

  getChildProps() {
    return { ...this.props, ...this.state };
  }

  render() {
    return <Select {...this.getChildProps()} isClearable />;
  }
}
